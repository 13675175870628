<template>
	<div class="event-appointment-container" :class="props.columnsItem.Css" :style="backgroundStyle(props.columnsItem)">
		<!-- 默认预约活动样式 -->
		<div class="event-appointment-default-container" v-for="(item, index) in data.eventPlanList" :key="index">
			<!-- 如果当前时间是在预约时间段内显示预约，如果是在报名时间段内显示报名 -->
			<div v-if="!signupIsStart() && item.Type == 'Appointment'">
				<!-- <div style="font-size: 0.02rem; word-break: break-all; color: #fff">{{ VueCookies.get('token') }}</div> -->
				<button
					class="event-appointment-default-item is-remind"
					v-if="new Date().getTime() < new Date(item.StartTime.replace(/-/g, '/')).getTime()"
				>
					预约报名未开始
				</button>
				<button
					class="event-appointment-default-item"
					:class="{ 'is-remind': data.isRemind }"
					v-else-if="
						new Date().getTime() > new Date(item.StartTime.replace(/-/g, '/')).getTime() &&
						new Date().getTime() < new Date(item.EndTime.replace(/-/g, '/')).getTime()
					"
					@click="data.isRemind ? '' : clickButton(item)"
				>
					{{ data.isRemind ? '已预约' : item.Title }}
				</button>
				<button class="event-appointment-default-item is-remind" v-else>报名未开始</button>
				<div style="font-size: 0.02rem; word-break: break-all; color: #fff">{{ VueCookies.get('token') }}</div>
			</div>
			<!-- 报名已开始 -->
			<div v-if="signupIsStart() && item.Type == 'SignUp'">
				<button
					class="event-appointment-default-item is-remind"
					v-if="new Date().getTime() < new Date(item.StartTime.replace(/-/g, '/')).getTime()"
				>
					报名未开始
				</button>
				<button
					class="event-appointment-default-item"
					v-else-if="
						new Date().getTime() > new Date(item.StartTime.replace(/-/g, '/')).getTime() &&
						new Date().getTime() < new Date(item.EndTime.replace(/-/g, '/')).getTime()
					"
					@click="clickButton(item)"
				>
					{{ item.Title }}
				</button>
				<button class="event-appointment-default-item is-remind" v-else>已结束</button>
			</div>
		</div>
		<!-- 其他活动预约样式 -->
		<div class="event-appointment-other"></div>
		<!-- 弹框 - 登录 -->
		<div v-if="data.notLoggedInVisible">
			<NotLoggedIn @notLoggedInCancel="data.notLoggedInVisible = false"></NotLoggedIn>
		</div>
		<!-- 弹框 - 预约 -->
		<div v-if="data.appointmentVisible">
			<div class="appointment-success-container flex-center-center">
				<div class="appointment-success-content flex-center-center">
					<img class="appointment-success-icon" src="@/assets/images/success-logo.png" alt="" />
					<div class="appointment-success-text">恭喜您，预约成功！</div>
					<!-- <div
            class="flex-center-center appointment-success-remind"
            @click="postEventAppointmentRemind()"
          >
            <img
              v-if="data.isSendMessage"
              class="remind-icon"
              src="@/assets/images/appointment-success-checked.png"
              alt=""
            />
            <img
              v-else
              class="remind-icon"
              src="@/assets/images/appointment-success-no-checked.png"
              alt=""
            />
            请通过手机联系我
          </div> -->
					<button @click="data.appointmentVisible = false" class="appointment-success-button">我知道了</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { reactive, onMounted, getCurrentInstance } from 'vue';
import VueCookies from 'vue-cookies';
import {
	GetEventPartList,
	PostSignup,
	PostLiveUrl,
	GetMyEventDetailsApi,
	PostEventAppointmentApi,
	PostEventAppointmentRemindApi,
	DeleteEventAppointmentApi,
} from '@/http/api/event';
import NotLoggedIn from '@/components/basePopup/NotLoggedIn.vue';
import BasePopup from '@/components/basePopup/BasePopup.vue';
import { ElMessage } from 'element-plus';
import { isOurIosApp, isOurAndroidApp, isOurMiniProgram } from '@/utils/common';
import { getTargetParams } from '@/utils/data';
import { adr_appIsLogin, adr_showCourseDetail, ios_showCourseDetail } from '@/utils/appFn';
import wx from 'weixin-js-sdk';
const { proxy } = getCurrentInstance() as any;
const props = defineProps({
	columnsItem: {
		type: Object,
		default: {},
	},
	ChannelId: {
		type: String,
		default: '',
	},
});
// 判断是否登录
const is_login = proxy.$cookies.get('loginStatus');
const data = reactive({
	eventPlanList: [], //活动环节列表
	notLoggedInVisible: false, //登录弹框
	isRemind: false, //是否已预约
	isSendMessage: true, //是否发送消息
	appointmentVisible: false, //预约成功弹框
	appointmentContent: {}, //预约内容
});

onMounted(() => {
	console.log(props.columnsItem);
	// 获取活动环节
	getEventPartList();
	if (is_login) {
		// 获取用户报名信息集合
		getMyEventDetails();
	}
});
// 点击预约或者报名
const clickButton = (item: any) => {
	const { Id, CourseType } = getTargetParams(props.columnsItem.Target);
	if (isOurIosApp()) {
		ios_showCourseDetail(Id, CourseType, item.Type);
		return;
	}

	if (isOurMiniProgram()) {
		wx.miniProgram.postMessage({
			data: {
				courseId: Id,
				courseType: CourseType,
				pageType: '1',
			},
		});

		setTimeout(() => {
			wx.miniProgram.navigateBack({ delta: 1 });
		}, 2000);
		return;
	}

	if (!is_login) {
		if (isOurAndroidApp()) {
			adr_appIsLogin(is_login);
			return;
		}
		data.notLoggedInVisible = true;
		return;
	}

	if (item.Type == 'Appointment') {
		postEventAppointment(item);
		// 已经预约点击去预约，没有预约点击取消预约
		// data.isRemind ? deleteEventAppointment(item) : postEventAppointment(item);
	} else if (item.Type == 'SignUp') {
		postEventSignup(item);
	} else {
		if (isOurAndroidApp()) {
			adr_showCourseDetail(Id, CourseType);
			return;
		}
		const url = props.columnsItem.Target ? props.columnsItem.Target : '/home';
		window.location.href = url;
	}
};
// 背景样式
const backgroundStyle = (item: any) => {
	if (item.Css.includes('image')) {
		return 'background-image:url(' + item.Css.split('image-')[1] + ')';
	} else if (item.Css.includes('background')) {
		return 'background:' + item.Css.split('-')[1];
	} else {
		return 'background:' + '';
	}
};
// 是否已开始报名
const signupIsStart = () => {
	return (
		data.eventPlanList.find(
			(item) => item.Type == 'SignUp' && new Date().getTime() >= new Date(item.StartTime.replace(/-/g, '/')).getTime(),
		) != null
	);
};
// ------------------ 接口开始 ----------------
// 获取活动环节
const getEventPartList = () => {
	const params = {
		EventId: JSON.parse(props.columnsItem.Data).EventId,
	};
	GetEventPartList(params)
		.then((res: any) => {
			data.eventPlanList = res;
		})
		.catch((err: any) => {
			console.log(err);
		});
};
// 获取我的指定活动报名信息
const getMyEventDetails = () => {
	const params = {
		EventId: JSON.parse(props.columnsItem.Data).EventId,
	};
	GetMyEventDetailsApi(params)
		.then((res: any) => {
			data.isRemind = res ? true : false;
		})
		.catch((err: any) => {
			console.log(err);
		});
};
// 去预约
const postEventAppointment = (item: any) => {
	const params = {
		EventId: item.EventId,
	};
	PostEventAppointmentApi(params)
		.then((res: any) => {
			data.isSendMessage = true;
			data.appointmentVisible = true;
			data.appointmentContent = res;
			getMyEventDetails();
		})
		.catch((err: any) => {
			console.log(err);
		});
};

const toSignUpUrl = () => {
	const url = props.columnsItem.Target;
	if (url && url !== '无') {
		if (props.columnsItem.TargetType == 'Url') {
			const w = window.open('about:blank');
			w.location.href = url;
		}
		if (props.columnsItem.TargetType == 'Local') {
			const w = window.open('about:blank');
			w.location.href = window.location.protocol + url;
		}
	}
};

// 去报名
const postEventSignup = (item: any) => {
	// 已报名
	if (data.isRemind) {
		toSignUpUrl();
		return;
	}
	const params = {
		EventId: item.EventId,
	};
	PostSignup(params)
		.then((res: any) => {
			ElMessage.success('报名成功');
			setTimeout(() => {
				toSignUpUrl();
			}, 1000);
		})
		.catch((err: any) => {
			console.log(err);
			ElMessage.error(err.Message);
		});
};

// 发送消息提醒
const postEventAppointmentRemind = () => {
	data.isSendMessage = !data.isSendMessage;
	const paramsContent = JSON.parse(data.appointmentContent.Data);
	const params = {
		EventId: data.appointmentContent.EventId,
		DependentId: paramsContent[0].DependentId,
		PartType: paramsContent[0].PartType,
		IsNotice: data.isSendMessage,
		CreateTime: paramsContent[0].CreateTime,
	};
	PostEventAppointmentRemindApi(params)
		.then((res: any) => {
			ElMessage({
				message: '设置成功！',
				type: 'success',
			});
		})
		.catch((err: any) => {
			data.isSendMessage = !data.isSendMessage;
			console.log(err);
		});
};
// 取消预约
const deleteEventAppointment = (item: any) => {
	const params = {
		EventId: item.EventId,
	};
	DeleteEventAppointmentApi(params)
		.then((res: any) => {
			ElMessage({
				message: '取消预约！',
				type: 'success',
			});
			getMyEventDetails();
		})
		.catch((err: any) => {
			console.log(err);
		});
};
</script>
<style scoped lang="scss">
.event-appointment-container {
	width: 100%;
	.event-appointment-default-container {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding-right: 0.36rem;
		box-sizing: border-box;
		.event-appointment-default-item {
			width: 100%;
			max-width: 2.5rem;
			min-width: 2.5rem;
			height: 0.7rem;
			border-radius: 0.38rem;
			cursor: pointer;
			font-size: 0.32rem;
			font-family: PingFangSC-Semibold, PingFang SC;
			font-weight: 600;
			color: #ffffff;
			line-height: 0.5rem;
			background: #fd3021;
		}
		.is-remind {
			background: #cdcdcd;
		}
	}
	button {
		outline: none;
		background: none;
		border: none;
	}
	// 预约成功弹框
	.appointment-success-container {
		z-index: 20000 !important;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: constant(safe-area-inset-bottom);
		bottom: env(safe-area-inset-bottom);
		background-color: rgba(0, 0, 0, 0.4);
		.appointment-success-content {
			flex-direction: column;
			width: 90%;
			max-width: 6.6rem;
			padding: 0.6rem 0 0.4rem 0;
			box-sizing: border-box;
			background: #ffffff;
			border-radius: 0.32rem;
			.appointment-success-icon {
				width: 2.08rem;
				height: auto;
				margin-bottom: 0.5rem;
			}
			.appointment-success-text {
				font-size: 0.36rem;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #36404a;
				line-height: 0.5rem;
				margin-bottom: 0.24rem;
			}
			.appointment-success-remind {
				font-size: 0.28rem;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #36404a;
				line-height: 0.4rem;
				margin-bottom: 0.36rem;
				cursor: pointer;
				.remind-icon {
					width: 0.4rem;
					height: auto;
					margin-right: 0.2rem;
				}
			}
			.appointment-success-button {
				width: 4.6rem;
				height: 0.8rem;
				background: #3c6bff;
				border-radius: 0.12rem;
				font-size: 0.32rem;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #ffffff;
				line-height: 0.44rem;
				cursor: pointer;
			}
		}
	}
}
</style>
