import request from '../request';
import qs from 'qs';
const baseURL: any = import.meta.env.VITE_PASSPORT_BASE_URL;
const tokenBaseURL: any = import.meta.env.VITE_PASSPORT_REFRESH_TOKEN_BASE_URL;
// console.log(tokenBaseURL)
// 修改用户信息
export function EditUserInfoApi(data: object) {
	return request({
		url: '/UpdateUser',
		method: 'post',
		baseURL: baseURL,
		data,
	});
}
// 上传头像
export function UploadAvatar(data: object) {
	return request({
		url: '/UploadAvatar',
		method: 'post',
		baseURL: baseURL,
		data: data.formdata,
	});
}
// 获取token
export function RefreshTokenApi(data: object) {
	return request({
		url: '/connect/token',
		method: 'post',
		baseURL: tokenBaseURL,
		data: qs.stringify(data, { arrayFormat: 'repeat' }),
	});
}

// 获取通行证个人档案--获取邮箱
export function GetUserInfoApi(data: object) {
	return request({
		url: '/UserInfo',
		method: 'get',
		baseURL: baseURL,
		data,
	});
}

// 获取通行证个人档案--获取邮箱
export function SetResetCode(data: object) {
	return request({
		url: '/ResetCode?PhoneNumber=' + data.PhoneNumber,
		method: 'post',
		baseURL: baseURL,
		data,
	});
}

// 获取用户是否已经设置密码
export function GetUserPwdStatus() {
	return request({
		url: '/HasPassword',
		method: 'get',
		baseURL,
	});
}

// 修改用户名密码
export function SetUserPwd(data: object) {
	return request({
		url: '/Password',
		method: 'put',
		baseURL,
		data,
	});
}
