<template>
	<div>
		<div v-show="state.visible" class="embed-container">
			<iframe ref="iframeEmbed" :src="props.src" frameborder="0" />
			<span class="icon-close" @click="closeContainer">×</span>
			<BaizeCustomer :is-show="customerShow" @close="customerShow = false"></BaizeCustomer>
		</div>
		<img v-show="!state.visible" class="button" :src="props.buttonSrc" @click="openContainer" />
	</div>
</template>

<script lang="ts">
export default { name: 'EmbedComponent' };
</script>

<script lang="ts" setup>
import { ref, onMounted, reactive, getCurrentInstance, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { onUnmounted } from 'vue';

const store = useStore();
const iframeEmbed = ref();
const { proxy } = getCurrentInstance() as any;
const userInfo = computed(() => proxy.$cookies.get('userInfo'));
const customerShow = ref(false); //客服弹窗是否显示

const props = defineProps({
	buttonSrc: {
		// 按钮图片地址
		type: String,
		default: '',
	},
	src: {
		// 内嵌访问的地址
		type: String,
		default: '',
	},
	greetings: {
		// 问候语
		type: String,
		default: '',
	},
	quickerQuestions: {
		// 猜你想问
		type: Array,
		default: [],
	},
});

const state = reactive({
	visible: false,
	currentCourseId: computed(() => store.state.currentCourseId),
	currentModuleId: computed(() => store.state.currentModuleId),
});

const postData = computed(() => ({
	greetings: props.greetings,
	quickerQuestions: props.quickerQuestions,
	courseId: state.currentCourseId,
	userId: userInfo.value.UserId || userInfo.value.Id || '',
	moduleId: state.currentModuleId,
}));

const messageListener = (event: any) => {
	if (event.data === 'iframe onMounted') {
		console.log('iframe onMounted', postData.value);
		setTimeout(() => {
			iframeEmbed.value.contentWindow.postMessage(JSON.stringify(postData.value), '*');
		}, 1000);
	}

	if (event.data === 'udeskshowmanual') {
		console.log('udeskshowmanual');
		customerShow.value = true;
	}
};

onUnmounted(() => {
	window.removeEventListener('message', messageListener);
});

const openContainer = () => {
	state.visible = true;
	iframeEmbed.value.contentWindow.postMessage(JSON.stringify(postData.value), '*');
	iframeEmbed.value.contentWindow.postMessage('open', '*');
};

const closeContainer = () => {
	state.visible = false;
};

onMounted(() => {
	console.log('embedComponents onMounted', postData.value);
	window.addEventListener('message', messageListener);
});
</script>
<style lang="scss" scoped>
@media screen and (max-width: 770px) {
	.embed-container {
		width: 100% !important;
	}
}

.embed-container {
	position: fixed;
	width: 33%;
	height: 100vh;
	right: 0;
	top: 0;
	z-index: 101;
	box-shadow: rgb(8 23 26 / 20%) 0 4px 12px 0;
	.icon-close {
		position: absolute;
		top: 9px;
		right: 10px;
		font-size: 30px;
		cursor: pointer;
	}

	iframe {
		height: 100%;
		width: 100%;
	}
}
.button {
	position: fixed;
	right: 0;
	// top: calc(35vh + 328px);
	top: calc(35vh + 248px);
	z-index: 10;
	width: 120px;
	height: 140px;
	margin-top: 13px;
	cursor: pointer;
}
</style>
