<template>
	<div class="text-list" :class="[{ 'flex-center-start': columnsItem.SameLine }]">
		<!-- 隐藏标题 -->
		<div v-if="!props.columnsItem.HideHeader" class="title">
			{{ props.columnsItem.Title }}
		</div>
		<!-- 学校列表 -->
		<div class="text-list blue-shadow" :class="columnsItem.Css">
			<div v-for="(item, index) in JSON.parse(props.columnsItem.Data).Items" :key="index" class="text-list-item">
				<pre>{{ item.Content }}</pre>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
const props = defineProps({
	columnsItem: {
		type: Object,
		default: {},
	},
	ChannelId: {
		type: String,
		default: '',
	},
});
// console.log(props.columnsItem);
</script>
<style scoped lang="scss">
.text-list {
	width: 100%;
	margin-bottom: 50px;
	.title {
		min-width: 96px;
		text-align: left;
		font-size: 27px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #ffffff;
		line-height: 38px;
		text-shadow: 0px 2px 3px #2100ce;
		margin-right: 24px;
		margin-bottom: 25px;
		vertical-align: middle;
	}
	.text-list {
		width: 100%;

		&.blue-shadow {
			text-shadow: 0px 2px 4px rgba(33, 0, 206, 0.5);
			font-size: 15px;
			font-family: PingFangSC-Semibold, PingFang SC;
			font-weight: 600;
			color: #ffffff;
		}

		&.default-text {
			color: #1a203c;
			text-shadow: none;
			font-weight: normal;
		}

		.text-list-item {
			width: 100%;
			min-width: 100px;
			padding: 15px 0;
			box-sizing: border-box;

			pre {
				font-family: PingFangSC-Regular, PingFangSC-Regular;
				font-weight: 400;
				font-size: 18px;
				color: #3d3d3d;
				line-height: 28px;
			}
		}
	}
}
</style>
