<template>
	<div class="text-list flex-start-start flex-1 oneDot">
		<template v-for="(item, index) in props.list" :key="index"
			><span class="text-item cursor-pointer" @click.stop="jumpRoute(item)">{{ item.Title }}</span></template
		>
	</div>
</template>
<script setup lang="ts">
import { PropType, ref } from 'vue';
import { useRouter } from 'vue-router';
const router = useRouter();
const props = defineProps({
	list: {
		type: Array as PropType<any[]>,
		default: [],
	},
});

// 跳转路径
const jumpRoute = (item: any) => {
	window.open(item.Target);
};
</script>

<style scoped lang="scss">
.text-list {
	margin-left: 10px;
	.text-item {
		font-size: 13px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #aaaaaa;
		margin-left: 20px;
	}
}
</style>
