<template>
	<div class="mobile-footer">
		<div class="mobile-footer-container flex-center-around">
			<!-- 首页 -->
			<router-link
				v-for="(item, index) in data.footerList"
				:key="index"
				:to="{ path: item.path }"
				class="flex-center-center"
				:class="{ is_active: routes.path == item.path }"
			>
				<img :src="routes.path == item.path ? getImageUrl(item.activeIcon) : getImageUrl(item.icon)" alt="" />
				<div>{{ item.title }}</div>
			</router-link>
		</div>
	</div>
</template>
<script setup lang="ts">
import { reactive } from 'vue';
import { useRoute } from 'vue-router'; //引入路由
const routes = useRoute(); //获取路由
const data = reactive({
	footerList: [
		{ title: '首页', path: '/home', activeIcon: 'home-active.png', icon: 'home.png' },
		{ title: '课程', path: '/screenCourseList', activeIcon: 'note-active.png', icon: 'note.png' },
		{ title: '在学', path: '/myCourse', activeIcon: 'course-active.png', icon: 'course.png' },
		{ title: '我的', path: '/mine', activeIcon: 'mine-active.png', icon: 'mine.png' },
		// { title: "我的", path: "",activeIcon:"mine-active.svg",icon:"mine.svg" },
	],
});
// 获取图片路径
const getImageUrl = (name: string) => {
	return new URL(`../../assets/images/tab-bar/2.0/${name}`, import.meta.url).href;
};
</script>
<style scoped lang="scss">
.mobile-footer {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 1.18rem;
	background: #ffffff;
	box-shadow: -2px 32px 104px 0px rgba(111, 143, 234, 0.19), -2px 6px 40px 0px rgba(111, 143, 234, 0.11);
	padding-bottom: constant(safe-area-inset-bottom);
	padding-bottom: env(safe-area-inset-bottom);

	.mobile-footer-container {
		width: 100%;
		height: 100%;
		a {
			flex-direction: column;
			font-size: 0.26rem;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #999999;
			line-height: 0.28rem;
			img {
				width: 0.38rem;
				height: auto;
				margin-bottom: 0.1rem;
			}
		}
		a.is_active {
			font-size: 0.26rem;
			font-weight: 400;
			color: #000000;
		}
	}
}
</style>
