<template>
	<div class="mobile-picture-link" :class="[{ 'flex-center-start': columnsItem.SameLine }]">
		<!-- 隐藏标题 -->
		<div v-if="!props.columnsItem.HideHeader" class="title">
			{{ props.columnsItem.Title }}
		</div>
		<!-- 学校列表 -->
		<div :class="columnsItem.Css" class="picture-link-item">
			<!-- 外部链接 -->
			<a :href="data.routerDetail.Target" v-if="data.routerDetail.TargetType == 'Url'">
				<img :class="data.routerDetail.Css" :src="data.routerDetail.ImageUrl" alt="" />
			</a>
			<!-- 本地链接 -->
			<router-link
				v-else-if="data.routerDetail.TargetType == 'Local'"
				class="school-content-list-item"
				:to="data.routerDetail.Target"
			>
				<img :class="data.routerDetail.Css" :src="data.routerDetail.ImageUrl" alt="" />
			</router-link>
			<!-- 没有链接 -->
			<a v-else>
				<img :class="data.routerDetail.Css" :src="data.routerDetail.ImageUrl" alt="" />
			</a>
		</div>
	</div>
</template>
<script setup lang="ts">
import { onMounted, reactive } from 'vue';
import { useRouter } from 'vue-router';
const router = useRouter();
const props = defineProps({
	columnsItem: {
		type: Object,
		default: {},
	},
	ChannelId: {
		type: String,
		default: '',
	},
});
// console.log(props.columnsItem);
const data = reactive({
	routerDetail: JSON.parse(props.columnsItem.Data),
});
// console.log(data.routerDetail);
</script>
<style scoped lang="scss">
.mobile-picture-link {
	flex: 1;
	width: 100%;
	padding: 0.32rem;
	box-sizing: border-box;
	background-color: #ffffff;
	margin-top: 0.3rem;
	border-radius: 0.16rem;

	.title {
		min-width: 1rem;
		font-size: 0.32rem;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 600;
		color: #000000;
		line-height: 0.32rem;
		margin-right: 0.24rem;
		vertical-align: middle;
	}
	.picture-link-item {
		width: 100%;
		height: auto;
		a {
			display: block;
			width: 100%;
			height: auto;
			img {
				width: 100%;
				height: auto;
			}
		}
	}
}
</style>
