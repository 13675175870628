<template>
	<div class="media-object-list" :class="[{ 'flex-center-start': columnsItem.SameLine }, columnsItem.Css]">
		<MobileListTitle
			:more="true"
			:columnsItem="props.columnsItem"
			v-if="!props.columnsItem.HideHeader"
			@to-more="seeMore"
		></MobileListTitle>

		<!-- 课程列表 -->
		<div v-if="JSON.parse(props.columnsItem.Data).Items && JSON.parse(props.columnsItem.Data).Items.length * 1 > 0" class="media-list">
			<div
				v-for="(item, index) in JSON.parse(props.columnsItem.Data).Items"
				:key="index"
				class="media-list-item flex-start-start"
				@click="jumpRoute(item)"
			>
				<img v-if="item.ImageUrl" class="media-list-item-cover" :src="item.ImageUrl" :alt="item.Title" />
				<div class="media-list-item-content">
					<h4 class="twoDot">{{ item.Title }}</h4>
					<p v-if="item.Description" class="describe twoDot">
						{{ item.Description }}
					</p>
					<p v-if="item.Description2" class="describe oneDot">
						{{ item.Description2 }}
					</p>
					<p v-if="item.Description3" class="describe oneDot">
						{{ item.Description3 }}
					</p>
				</div>
			</div>
		</div>
		<div v-else class="no-data">暂无数据！</div>
	</div>
</template>
<script setup lang="ts">
import { onMounted, reactive } from 'vue';
import { PostSpecialsApi } from '@/http/api/course';
import { useRouter } from 'vue-router';
const router = useRouter();
const props = defineProps({
	columnsItem: {
		type: Object,
		default: {},
	},
	ChannelId: {
		type: String,
		default: '',
	},
});
const data = reactive({});
// 查看更多
const seeMore = () => {
	const MoreDetails = JSON.parse(props.columnsItem.Data);
	if (MoreDetails.MoreType == 'Url') {
		const path = MoreDetails.More;
		window.location.href = path;
	} else if (MoreDetails.MoreType == 'Local') {
		router.push({
			path: MoreDetails.More,
		});
	}
};
// 跳转路径
const jumpRoute = (item: any) => {
	if (item.TargetType == 'Url') {
		const path = item.Target;
		window.location.href = path;
	} else if (item.TargetType == 'Local') {
		const path = item.Target;
		window.location.href = path;
		// router.push({
		//   path: item.Target,
		// });
	}
};

// 点击更多左侧标题
const clickTitle = () => {
	if (JSON.parse(props.columnsItem.Data).MoreType == 'None') return;
	seeMore();
};
</script>
<style scoped lang="scss">
.media-object-list {
	flex: 1;
	width: 100%;
	margin-top: 0.3rem;
	padding: 0.2rem;
	box-sizing: border-box;
	background-color: #fff;
	border-radius: 0.16rem;

	.media-object-title {
		margin-bottom: 0.42rem;
		.title {
			font-size: 0.32rem;
			font-family: PingFangSC-Semibold, PingFang SC;
			font-weight: 600;
			color: #000000;
			line-height: 0.32rem;
		}
		.more {
			font-size: 0.28rem;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #666666;
			line-height: 0.2rem;
			cursor: pointer;
			img {
				width: 0.32rem;
				height: auto;
				margin-left: 0.1rem;
				cursor: pointer;
			}
		}
	}
	.media-list {
		width: 100%;
		// margin-top: 0.42rem;
		.media-list-item {
			width: 100%;
			margin-bottom: 0.32rem;
			&:last-of-type {
				margin-bottom: 0;
			}
			.media-list-item-cover {
				width: 2.4rem;
				height: 1.4rem;
				display: block;
				margin-right: 0.2rem;
				font-size: 0.24rem;
			}
			.media-list-item-content {
				flex: 1;
				height: 100%;
				h4 {
					width: 100%;
					min-height: 0.8rem;
					font-size: 0.3rem;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 600;
					color: #333333;
					line-height: 0.4rem;
					margin-bottom: 0.2rem;
				}
				.describe {
					font-size: 0.24rem;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #999999;
					line-height: 0.24rem;
				}
			}
		}
	}
	.no-data {
		font-size: 0.3rem;
		padding: 0.2rem 0.32rem;
		box-sizing: border-box;
	}
}
</style>
