<template>
	<div class="course">
		<div class="course-lists" v-if="props.courseLists && props.courseLists.length * 1 > 0">
			<div class="course-lists-item" v-for="(item, index) in props.courseLists" :key="index" @click="clickCourse(item)">
				<img v-if="item.Cover" class="course-lists-item-cover" :src="item.Cover" :alt="item.Name" />
				<div class="course-lists-item-content" :class="{ 'course-lists-item-content-card': props.isCard }">
					<h4 class="oneDot">{{ item.Name }}</h4>
					<div class="organ-name flex-center-start">
						<img src="@/assets/images/mobile-school-icon.png" alt="" class="icon" />
						<div class="oneDot text">{{ item.SourceName }}</div>
					</div>
					<div class="organ-number flex-center-between">
						<div class="text flex-center-start">
							<img src="@/assets/images/user-icon.png" alt="" class="icon" />{{ item.ViewedCount }}
						</div>
						<div class="flex-center-center price-area" v-if="item.Price">
							<div class="price">{{ item.Price }}</div>
							<div class="unit">元</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="no-data">暂无数据！</div>
	</div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import { PostClickCourse } from '@/http/api/course';
import { isOurIosApp, isOurAndroidApp, isOurMiniProgram } from '@/utils/common';
import { adr_showCourseDetail, ios_showCourseDetail } from '@/utils/appFn';
import { toMobileDetails } from '@/utils/event/courseList';
import wx from 'weixin-js-sdk';
const router = useRouter();
const props = defineProps({
	courseLists: {
		type: Array,
		default: [],
	},
	SpecialId: {
		type: String,
		default: '',
	},

	ChannelId: {
		type: String,
		default: '',
	},
	isCard: Boolean,
});
// console.log(props.SpecialId)

// 点击记录点击状态
const clickCourse = (item: any) => {
	if (isOurAndroidApp()) {
		adr_showCourseDetail(item.Id, item.CourseType);
		return;
	}
	if (isOurIosApp()) {
		ios_showCourseDetail(item.Id, item.CourseType);
		return;
	}

	if (isOurMiniProgram()) {
		wx.miniProgram.postMessage({
			data: {
				courseId: item.Id,
				courseType: item.CourseType,
				pageType: '1',
			},
		});

		wx.miniProgram.navigateBack({ delta: 1 });
		return;
	}

	if (!props.SpecialId) {
		toMobileDetails(router, item, props.ChannelId);
		return;
	}
	const params = {
		SpecialId: props.SpecialId,
		CourseId: item.Id,
	};
	PostClickCourse(params)
		.then((res: any) => {
			toMobileDetails(router, item, props.ChannelId);
		})
		.catch((err: any) => {
			console.log(err);
		});
};
</script>

<style lang="scss" scoped>
.course {
	width: 100%;

	.course-lists {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		// margin-top: 0.42rem;
		.course-lists-item {
			margin-bottom: 0.32rem;
			width: calc((100vw - 0.8rem - 0.2rem) / 2);
			min-height: 3.28rem;

			.course-lists-item-cover {
				width: 100%;
				height: 2.04rem;
				display: block;
				font-size: 0.24rem;
				background: #eee;
			}
			.course-lists-item-content {
				display: flex;
				flex-direction: column;
				width: 100%;

				h4 {
					width: 100%;
					font-size: 0.3rem;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 600;
					color: #333333;
					line-height: 0.5rem;
					margin-top: 0.1rem;
					margin-bottom: 0.14rem;
				}
				.organ-name {
					width: 100%;
					.icon {
						height: 0.24rem;
						width: auto;
						margin-right: 0.14rem;
					}
					.text {
						font-size: 0.24rem;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #999999;
						line-height: 0.24rem;
						letter-spacing: 1px;
					}
				}

				.organ-number {
					width: 100%;
					margin-top: 0.1rem;
					height: 0.4rem;

					.text {
						color: #999;
						font-size: 0.24rem;
						font-family: PingFangSC-Medium, PingFang SC;
						flex: 1;

						.icon {
							height: 0.24rem;
							width: auto;
							display: block;
							margin-right: 0.14rem;
						}
					}

					.price-area {
						width: 40%;
						display: flex;
						justify-content: flex-end;
						color: #ff1919;
						height: 0.3rem;
						font-family: PingFangSC-Medium, PingFang SC;

						.price {
							font-size: 0.3rem;
							margin-right: 0.05rem;
						}

						.unit {
							font-size: 0.24rem;
						}
					}
				}
			}

			.course-lists-item-content-card {
				padding: 0.1rem;
				background: #fff;
				box-sizing: border-box;
				border-radius: 0 0 0.14rem 0.14rem;
			}
		}
		.course-lists-item::after {
			content: '';
			flex: auto;
		}
	}
	.no-data {
		font-size: 0.3rem;
		padding: 0.2rem 0.32rem;
		box-sizing: border-box;
	}
}
</style>
