<template>
	<!-- 提示框 -->
	<el-dialog
		draggable
		center
		:show-close="false"
		v-model="data.SetPwdPopupShow"
		modal-class="set-password-dialog"
		width="556px"
		:before-close="close"
	>
		<div class="password-wrapper">
			<div class="flex-column-1">
				<div class="flex-center-between top-title">
					<div>{{ props.title }}</div>
					<div @click="close">
						<img src="@/assets/images/mine/upload-avatar/close.png" alt="" class="close-icon" />
					</div>
				</div>
				<!-- 设置密码 -->
				<VerifyPassword
					v-if="data.showPwd"
					:OldPassword="password"
					:showCancel="props.showCancel"
					:mobile="data.mobile"
				></VerifyPassword>
				<!-- 设置验证码 -->
				<VerifyCode v-if="data.showCode" :OldPassword="password"></VerifyCode>
			</div>
		</div>
	</el-dialog>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref, watch, onUnmounted, computed, getCurrentInstance } from 'vue';
import VerifyPassword from './VerifyPassword.vue';
import VerifyCode from './VerifyCode.vue';
import { emitter } from '@/utils/EventBus';
import { plusXing } from '@/utils/common';

const { proxy } = getCurrentInstance() as any;
const user_info: any = computed(() => proxy.$cookies.get('userInfo'));
const emits = defineEmits(['close']);
const password = ref();
const data: any = reactive({
	SetPwdPopupShow: false, //是否显示设置密码弹窗
	pwdTrimError: false, //校验密码是否有空格
	pwdFormatError: false, //校验密码格式是否正确
	pwdLengthError: false, //校验密码长度是否符合要求
	showPwd: true, //显示输入密码弹窗
	showCode: false, //显示验证码弹窗
	mobile: '',
});

const props = defineProps({
	isShow: {
		type: Boolean,
		default: false,
	},
	avatar: {
		type: String,
		default: '',
	},

	title: {
		type: String,
		default: '',
	},

	// 是否显示跳过
	showCancel: {
		type: Boolean,
		default: true,
	},

	// 是否是登录入口打开的弹窗
	isLoginPopup: {
		type: Boolean,
		default: false,
	},
});

onMounted(() => {
	data.SetPwdPopupShow = props.isShow;
	if (proxy.$cookies.get('userInfo')) {
		data.mobile = plusXing(proxy.$cookies.get('userInfo').PhoneNumber, 3, 4);
	}
	emitter.on('toVerifyCode', toVerifyCode);
	emitter.on('toSetPwd', toSetPwd);
	emitter.on('VerifyPwdSuccess', () => close());
	emitter.on('cancelSetPwd', () => close());
});

watch(
	() => [props.isShow, proxy.$cookies.get('userInfo')],
	() => {
		console.log('isShow变化了');
		console.log(user_info.value);
		console.log(proxy.$cookies.get('userInfo'));
		data.SetPwdPopupShow = props.isShow;
		if (proxy.$cookies.get('userInfo')) {
			data.mobile = plusXing(proxy.$cookies.get('userInfo').PhoneNumber, 3, 4);
		}
	},
);

// 监听设置密码符合条件后点击了下一步操作
const toVerifyCode = (pwd: any) => {
	data.showPwd = false;
	data.showCode = true;
	password.value = pwd;
};

// 监听校验手机号点击了上一步操作
const toSetPwd = (pwd: any) => {
	data.showPwd = true;
	data.showCode = false;
	password.value = pwd;
};

onUnmounted(() => {
	emitter.off('toVerifyCode');
	emitter.off('toSetPwd');
	emitter.off('VerifyPwdSuccess');
	emitter.off('cancelSetPwd');
});

const close = () => {
	data.SetPwdPopupShow = false;
	emits('close');

	// 如果是登录后的跳过入口,关闭后进行刷新
	if (props.isLoginPopup) {
		location.reload();
	}
};
</script>

<style scoped lang="scss">
.password-wrapper {
	background: #ffffff;
	border-radius: 5px;
	display: flex;
	flex-direction: column;

	.top-title {
		margin-left: 20px;
		margin-right: 20px;
		border-bottom: 1px solid #f2f1f1;
		height: 64px;
		box-sizing: border-box;
	}

	.close-icon {
		width: 20px;
		height: 20px;
		vertical-align: bottom;
		cursor: pointer;
	}
}
</style>
<style lang="scss">
.set-password-dialog {
	padding: 0;
	.el-dialog__body {
		padding: 0;
	}

	.el-dialog__header {
		padding: 0;
	}
}
</style>
