<template>
	<MobileScrollButtonList
		v-if="props.isHorizontalScroll"
		@toChannel="toChannel"
		:columnsItem="props.columnsItem"
	></MobileScrollButtonList>

	<div class="button-list-container" :class="[{ 'flex-center-start': columnsItem.SameLine }, columnsItem.Css]" v-else>
		<!-- 隐藏标题 -->
		<div v-if="!props.columnsItem.HideHeader" class="title">
			{{ props.columnsItem.Title }}
		</div>
		<!-- 按钮列表 -->
		<div class="button-list flex-center-around" :class="columnsItem.Css">
			<div
				v-for="(item, index) in JSON.parse(props.columnsItem.Data).Items"
				:key="index"
				@click="toChannel(item)"
				class="button-list-item flex-center-center"
			>
				<img class="button-logo" :src="item.Icon" :alt="item.Title" />
				<span class="button-title">{{ item.Title }}</span>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
const router = useRouter();
const props = defineProps({
	columnsItem: {
		type: Object,
		default: {},
	},
	list: {
		type: Array,
		default: [],
	},
	isHorizontalScroll: Boolean,
});
const data = reactive({});
// 点击去频道页
const toChannel = (item: any) => {
	console.log(item);
	// 外部链接
	if (item.TargetType == 'Url') {
		window.location.href = item.Target;
	} else if (item.TargetType == 'Local') {
		router.push({
			path: item.Target,
		});
	}
};
</script>

<style lang="scss" scoped>
.button-list-container {
	width: 100%;
	margin-top: 0.3rem;
	padding: 0.32rem;
	box-sizing: border-box;
	background-color: #ffffff;
	border-radius: 0.16rem;
	.title {
		font-size: 0.32rem;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #000000;
		line-height: 0.32rem;
		margin-bottom: 0.42rem;
	}
	.button-list {
		flex-wrap: wrap;
		.button-list-item {
			width: 24%;
			flex-direction: column;
			margin-bottom: 0.32rem;
			.button-logo {
				width: 0.8rem;
				height: auto;
				margin-bottom: 0.16rem;
			}
			.button-title {
				font-size: 0.28rem;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #333333;
				line-height: 0.28rem;
			}
		}
	}
	.button-list:after {
		content: '';
		width: 24%;
	}
}
</style>
