import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, AxiosInstance, Axios } from 'axios';
import { ElMessage } from 'element-plus';
import jwt_decode from 'jwt-decode';
import VueCookies from 'vue-cookies';
import { RefreshTokenApi } from '@/http/api/passport';
import { logout } from '@/utils/common';
import { store } from '@/store';

const baseURL: any = import.meta.env.VITE_BASE_URL;
const service: AxiosInstance = axios.create({
	baseURL,
	// timeout: 5000,
});

// 默认刷新状态
let isRefreshing = false;
// 挂起的请求数组
let subscribers: any = [];
const onAccessTokenFetched = () => {
	subscribers.forEach((callback: any) => {
		callback();
	});
	subscribers = [];
};

const addSubscriber = (callback: any) => {
	subscribers.push(callback);
};

const whiteList = ['/connect/token', ''];
const isWhiteList = (url = '') => whiteList.includes(url);

// 请求前的统一处理
service.interceptors.request.use((config: AxiosRequestConfig) => {
	setConfigHeaders(config);
	// token过期
	if (isRefreshTokenExpired() && !isWhiteList(config.url)) {
		// 刷新中
		if (!isRefreshing) {
			isRefreshing = true;
			// 刷新token
			refreshToken();
		}
		// 过滤刷新token请求
		const retryOriginalRequest = new Promise((resolve) => {
			// 挂起请求
			addSubscriber(() => {
				setConfigHeaders(config);
				resolve(config);
			});
		});
		return retryOriginalRequest;
	}
	return config;
});

service.interceptors.response.use(
	(response: AxiosResponse) => {
		const res = response.data;
		// 如果是刷新token
		if (response.config.url.indexOf('/connect/token') >= 0) {
			if (response.status * 1 === 200) {
				return response.data;
			} else {
				showError(response);
				return Promise.reject(response);
			}
		}
		//  其他正常的路由
		if (res.StateCode * 1 === 0 && res.State) {
			return res.Data;
		} else {
			showError(res);
			return Promise.reject(res);
		}
	},
	(error: AxiosError) => {
		console.log(error); // for debug
		const badMessage: any = error.message || error;
		const code = parseInt(badMessage.toString().replace('Request failed with status code', ''));
		// console.log(code)
		showError({ code, message: badMessage });
		return Promise.reject(error);
	},
);
// 错误
function showError(error: any) {
	if (error.code === 418) {
		logout(1);
	}
	if (error.code === 401) {
		// refreshToken();//重新获取token
		// VueCookies.get('refreshToken')
		// if (!isRefreshing) {
		//   isRefreshing = true;
		//   // 获取token
		//   refreshToken()
		// }
	} else if (error.code === 403) {
		ElMessage.error('错了');
		// to re-login
		// store.dispatch('user/loginOut')
	} else {
		// ElMessage({
		//   message: error.msg || error.message || '服务异常',
		//   type: 'error',
		//   duration: 3 * 1000
		// })
	}
}
// 判断token距离过期时间10分钟
const isRefreshTokenExpired = () => {
	const Token = VueCookies.get('token') || '';
	if (!Token) {
		return false;
	}
	const decoded: any = Token ? jwt_decode(Token) : '';
	const exp_time = decoded.exp; //到期时间
	const new_time = new Date().getTime(); //当前时间
	const stamp = exp_time * 1000 - new_time; //距离到期时间
	return stamp <= 10 * 60 * 1000; //距离过期10分钟刷新
};

// 刷新token
const refreshToken = () => {
	const params = {
		client_id: import.meta.env.VITE_CLIENT_ID,
		client_secret: import.meta.env.VITE_CLIENT_SECRETS,
		grant_type: 'refresh_token',
		refresh_token: VueCookies.get('refreshToken') || '',
	};
	RefreshTokenApi(params)
		.then((res: any) => {
			store.commit('saveToken', res.access_token);
			store.commit('saveRefreshToken', res.refresh_token);
		})
		.catch((err: error) => {
			console.log('刷新token请求错误:', err);
			logout();
			// const url = window.location.host + window.location.pathname;
			// let LogoutUrl = `${import.meta.env.VITE_AUTHORITY}Account/Logout?logoutId=${url}`;
			// window.location.href = LogoutUrl;
		})
		.finally(() => {
			isRefreshing = false;
			// 挂起请求重新执行
			onAccessTokenFetched();
		});
};

// 设置header
const setConfigHeaders = (config: AxiosRequestConfig) => {
	if (VueCookies.get('token') && VueCookies.get('refreshToken') && config.url) {
		let Token = VueCookies.get('token') || '';
		if (config.method === 'post') {
			config.headers = {
				Authorization: 'Bearer ' + Token,
				'Content-Type': 'application/json;charset=utf-8',
			};
		}
		if (config.url.indexOf('/connect/token') >= 0) {
			config.headers = {
				Authorization: Token,
				'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
			};
		} else if (config.url.includes('/Upload/UpFile') || config.url.includes('/UploadAvatar')) {
			// 导入文件设置请求头
			config.headers = {
				Authorization: 'Bearer ' + Token,
				'Content-Type': 'multipart/form-data',
			};
		} else {
			config.headers = {
				Authorization: 'Bearer ' + Token,
			};
		}
	}
};

export default service;
