<template>
	<div class="nav-third-list" ref="listRef">
		<template v-for="(item, index) in list" :key="index">
			<div class="nav-third-item" @click.stop="toDetailByClassify(item)">
				<a :title="item.Name">{{ item.Name }}</a>
			</div>
		</template>
	</div>
</template>

<script setup lang="ts">
import { ref, PropType, nextTick, watch } from 'vue';
import { cloneDeep } from 'lodash';
const emit = defineEmits(['toDetailByClassify']);

interface ChildrenElement {
	offsetWidth: number;
}

const props = defineProps({
	data: {
		type: Array as PropType<any[]>,
		required: true,
	},
	parentId: {
		type: String,
		required: true,
	},
});

watch(
	() => props.data,
	() => {
		list.value = props.data;
		nextTick(render);
	},
);

const toDetailByClassify = (item: any) => {
	emit('toDetailByClassify', item);
};

const list = ref<any[]>([]);
const listRef = ref<HTMLDivElement>();
const lastW = 40;

const init = () => {
	list.value = props.data;
	nextTick(render);
};

const computedElChildren = (el: HTMLElement) => {
	const childrenData = [];
	for (let i = 0; i < el.children.length; i++) {
		const item = el.children[i];
		const w = (item as HTMLDivElement).offsetWidth;
		childrenData.push({ offsetWidth: w });
	}
	return childrenData;
};

const computedReduceLength = (data: ChildrenElement[], parentW: number, totalW: number, l: number) => {
	let reduceLength = 0;
	const temData = cloneDeep(data);
	temData.length = l;

	if (totalW <= parentW - lastW) {
		return reduceLength;
	}

	for (let i = l - 1; i > 0; i--) {
		const item = temData[i];
		const w = item.offsetWidth;
		reduceLength++;
		if (totalW - w <= parentW - lastW) {
			return reduceLength;
		}
	}

	return reduceLength;
};

const computedLength = (data: ChildrenElement[], parentW: number): [number, boolean] => {
	let isAppend = false;
	let l = 0;
	let totalW = 0;

	for (const [i, item] of data.entries()) {
		const w = item.offsetWidth;

		const tempW = totalW + w;
		if (tempW <= parentW) {
			totalW += w;
			l++;
		} else {
			isAppend = true;
			if (tempW + lastW > parentW) {
				const reduceLength = computedReduceLength(data, parentW, totalW, l);
				return [l - reduceLength, isAppend];
			}
		}
	}

	return [l, isAppend];
};

const render = () => {
	const el = listRef.value!;
	const elW = el.offsetWidth;
	const childrenData = computedElChildren(el);
	const [dataLength, isAppend] = computedLength(childrenData, elW);
	list.value.length = dataLength;
	if (isAppend) {
		list.value.push({ Name: '其他', Id: props.parentId });
	}
};

init();
</script>

<style lang="scss">
.nav-third-list {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	flex: 1;
	color: #13202b;
	font-size: 14px;
	.nav-third-item {
		font-size: 12px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #999;
		display: flex;
		align-items: center;
		padding: 0 6px;
		&:last-of-type {
			padding-right: 0;
		}
		&:hover {
			color: #3c6bff;
		}
	}
}
</style>
