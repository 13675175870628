<template>
	<div class="picture-link" :class="[{ 'flex-center-start': columnsItem.SameLine }]" :style="{ marginBottom: props.marginBottom }">
		<!-- 隐藏标题 -->
		<div v-if="!props.columnsItem.HideHeader" class="title">
			{{ props.columnsItem.Title }}
		</div>
		<!-- 学校列表 -->
		<div :class="columnsItem.Css" class="picture-link-item">
			<div :class="data.routerDetail.Css" @click="jumpRoute(data.routerDetail)">
				<img :src="data.routerDetail.ImageUrl" alt="" />
			</div>
		</div>
		<!-- 视频 -->
		<div v-if="data.videoShow" class="video-container flex-center-center">
			<div class="video-content">
				<div class="close" @click="data.videoShow = false">
					<img class="close-icon" src="@/assets/images/close-black-icon.png" alt="" />
				</div>
				<div class="base-video-container">
					<BaseVideo :videoPoster="data.videoPoster" :videoUrl="data.videoUrl"></BaseVideo>
				</div>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { reactive } from 'vue';
import BaseVideo from '../baseVideo/BaseVideo.vue';
import { getCurrentInstance } from 'vue';
const emit = defineEmits(['login']);
const { proxy } = getCurrentInstance() as any;
const is_login = proxy.$cookies.get('loginStatus');
const props = defineProps({
	columnsItem: {
		type: Object,
		default: {},
	},
	ChannelId: {
		type: String,
		default: '',
	},
	// 指定活动详情
	eventDetail: {
		type: Object,
		default: {
			EventRequireLogin: false,
			ExternalLinkRequireLogin: false,
		},
	},

	marginBottom: {
		type: String,
		default: '50px;',
	},
});
// console.log(props.columnsItem);
const data = reactive({
	routerDetail: JSON.parse(props.columnsItem.Data),
	videoShow: false, //是否显示视频
	videoUrl: '', //视频路由
	videoPoster: '', //视频封面图
});

// 跳转路径
const jumpRoute = (item: any) => {
	const path = item.Target;
	if (judgmentFile(path)) {
		data.videoUrl = path;
		data.videoPoster = item.ImageUrl;
		data.videoShow = true;
	} else {
		jumpPath(item);
	}
};

// 跳转路径
const jumpPath = (item: any) => {
	if (item.TargetType === 'None') return;

	const path = item.Target;
	if (!path || path === '无') return;
	const { EventRequireLogin, ExternalLinkRequireLogin } = props.eventDetail;
	// 外部链接和内部链接未登录进行强制登录
	if (!is_login) {
		if ((item.TargetType == 'Url' && ExternalLinkRequireLogin) || (item.TargetType == 'Local' && EventRequireLogin)) {
			emit('login');
			return;
		}
	}

	const w = window.open('about:blank');
	w.location.href = path;
};

// 判断是否是视频
const judgmentFile = (picUrl: any) => {
	let filename = picUrl; //文件路径地址
	let index1 = filename.lastIndexOf('.');
	let index2 = filename.length;
	let postf = filename.substring(index1, index2); //获取文bai件后缀名地址
	//判断文件后缀名是否等于视频文件的后缀名
	if (postf === '.avi' || postf === '.mp4' || postf === '.rmvb' || postf === '.ogg') {
		return true;
	} else {
		return false;
	}
};
</script>
<style scoped lang="scss">
.picture-link {
	flex: 1;
	width: 100%;

	.title {
		width: 96px;
		text-align: center;
		font-size: 16px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 600;
		color: #000000;
		line-height: 21px;
		margin-right: 24px;
		vertical-align: middle;
	}
	.picture-link-item {
		width: 100%;
		height: auto;
		img {
			width: 100%;
			height: auto;
			display: block;
			cursor: pointer;
		}
	}
	.video-container {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.4);
		z-index: 1000;
		.video-content {
			width: 80%;
			max-width: 880px;
			padding: 40px 0;
			box-sizing: border-box;
			position: relative;
			.close {
				position: absolute;
				top: 0px;
				right: -25px;
				.close-icon {
					width: 24px;
					height: auto;
					// margin-right: -20px;
					// margin-bottom: 10px;
					cursor: pointer;
				}
			}
			.base-video-container {
				width: 880px;
				height: 495px;
				background: rgba(0, 0, 0);
			}
		}
	}
}
</style>
