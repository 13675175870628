<template>
	<div class="search-wrapper flex-column-center">
		<div class="search-swiper" @mouseleave="setInputBlur">
			<input
				v-focus
				v-if="data.searchInputShow"
				v-model="data.Keyword"
				type="search"
				:placeholder="data.placeholder"
				@blur="setInputBlur"
				@keyup.enter.native="searchEnter"
				class="placeholder"
			/>
			<el-carousel v-else direction="vertical" indicator-position="none" loop class="search-text-el-carousel" @change="swiperChange">
				<el-carousel-item v-for="(item, index) in data.swiperKeywordList" :key="index">
					<h4 class="placeholder cursor-pointer" @click.stop="clickSwiper(item)">{{ item }}</h4>
				</el-carousel-item>
			</el-carousel>
			<div class="search-btn" @click="goSearch()">搜索</div>
		</div>
		<div class="flex-center-between search-keyword">
			<div v-for="item in data.keywordList" @click="clickHotKeyword(item)">{{ item }}</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { reactive, watch, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router'; //引入路由
import { isEmpty, trim } from 'lodash';
import { useStore } from 'vuex';
const store = useStore();
const router = useRouter();
const data = reactive({
	Keyword: '', //搜索关键字
	swiperKeywordList: ['生活', '兴趣', '职场', '技能', '老年', '学历', '图书馆'],
	searchInputShow: false,
	keywordList: ['生活', '兴趣', '职场', '技能', '老年', '学历', '图书馆'],
	swiperCurrent: 0,
	baseData: computed(() => store.state.baseData),
	placeholder: '',
});
const props = defineProps({
	typeId: {
		type: String,
		default: 'course',
	},
});

watch(
	() => data.baseData,
	() => init(),
);

onMounted(() => init());

const init = () => {
	if (!isEmpty(data.baseData)) {
		const RecommendedSearchKeywords = data.baseData.Dictionarys?.RecommendedSearchKeywords.split(',');
		data.keywordList = RecommendedSearchKeywords || data.keywordList;
		data.swiperKeywordList = RecommendedSearchKeywords || data.keywordList;
	}
};

// 搜索
const searchEnter = (e: any) => {
	if (!trim(data.Keyword)) {
		data.Keyword = data.swiperKeywordList[data.swiperCurrent];
	}
	const keyCode = window.event ? e.keyCode : e.which;
	if (keyCode == 13) {
		toSearchList();
	}
};

// 点击搜索按钮
const goSearch = () => {
	if (!trim(data.Keyword)) {
		data.Keyword = data.swiperKeywordList[data.swiperCurrent];
	}
	toSearchList();
};

// 跳转搜索列表
const toSearchList = () => {
	router.push({
		path: '/searchCourseList',
		query: { Keyword: data.Keyword },
	});
};

// 设置隐藏输入框显示轮播文字
const setInputBlur = () => {
	data.searchInputShow = !!trim(data.Keyword);
};

// 点击搜索框下方的热词
const clickHotKeyword = (item: any) => {
	data.Keyword = item;
	toSearchList();
};

// 搜索框轮播改变
const swiperChange = (index: number) => {
	data.swiperCurrent = index;
};

// 点击轮播文字
const clickSwiper = (item: any) => {
	data.placeholder = item;
	data.searchInputShow = true;
};
</script>
<style scoped lang="scss">
.search-wrapper {
	width: 465px;

	.search-swiper {
		width: 100%;
		height: 32px;
		background: #f5f6fa;
		border-radius: 6px;
		display: flex;
		margin-top: 5px;
		position: relative;
		justify-content: space-between;

		input {
			flex: 1;
			height: 100%;
			border: none;
			outline: none;
			background: none;
		}

		.placeholder {
			opacity: 0.75;
			margin: 0;
			font-size: 13px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #999999;
			height: 32px;
			display: flex;
			align-items: center;
			padding: 0 15px;
		}

		.search-input {
			color: $color000;
		}

		.search-btn {
			font-size: 13px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #ffffff;
			width: 59px;
			height: 32px;
			background: #3c6bff;
			border-radius: 0px 5px 5px 0px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			position: absolute;
			right: 0;
		}
	}

	.search-keyword {
		width: 100%;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #aaaaaa;
		display: flex;
		flex-wrap: wrap;
		line-height: 12px;
		box-sizing: border-box;
		cursor: pointer;
		padding-top: 10px;
		padding-left: 13px;
		padding-right: 13px;
	}
}
</style>
<style lang="scss">
.search-text-el-carousel {
	flex: 1;

	.el-carousel__container {
		height: 32px;
	}
}
</style>
