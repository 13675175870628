<template>
	<div class="flex-center-end my-account-area" @mouseenter="data.mineListShow = true" @mouseleave="data.mineListShow = false">
		<div class="flex-center-end flex-1">
			<img v-if="data.userAvatarIcon" class="user-avatar" :src="data.userAvatarIcon" alt="" />
			<img v-else src="@/assets/images/head-pic-default.png" class="user-avatar" alt="" />
			<div class="user-name oneDot">{{ data.userNickname }}</div>
		</div>
		<ul v-show="data.mineListShow" class="mine-account-list flex-center-center">
			<li v-for="(item, index) in data.mineList" :key="index" @click.stop="toMineDetail(item)">
				{{ item.title }}
			</li>
		</ul>
	</div>
</template>
<script setup lang="ts">
import { computed, reactive, getCurrentInstance, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import { emitter } from '@/utils/EventBus';
import { logout } from '@/utils/common';
const { proxy } = getCurrentInstance() as any;
const router = useRouter();
const user_info: any = computed(() => proxy.$cookies.get('userInfo') || {});

const data: any = reactive({
	mineList: [
		{ title: '个人中心', id: 'mine' },
		{ title: '学习记录', id: 'myCourse' },
		{ title: '收藏', id: 'myCollection' },
		{ title: '订单', id: 'myOrder' },
		{ title: '消息', id: 'myNews' },
		{ title: '退出登录', id: 'loginOut' },
	],
	is_login: proxy.$cookies.get('loginStatus'), //登录状态  true登录  false未登录
	mineListShow: false,
});

onMounted(() => {
	const { Avatar, Nickname } = user_info.value;
	data.userAvatarIcon = Avatar;
	data.userNickname = Nickname;

	emitter.on('avatarChange', (e) => {
		data.userAvatarIcon = e;
	});

	emitter.on('nickChange', (e) => {
		data.userNickname = e;
	});
});

onUnmounted(() => {
	emitter.off('avatarChange');
	emitter.off('nickChange');
});

const toMineDetail = (item: any) => {
	let routeData: any;
	switch (item.id) {
		case 'loginOut':
			logout();
			break;
		case 'mine':
		case 'myCourse':
			routeData = router.resolve({
				path: '/mine/myCourse',
			});

			break;
		default:
			routeData = router.resolve({
				path: `/mine/${item.id}`,
			});
			break;
	}
	window.open(routeData.href, '_blank');
};
</script>
<style scoped lang="scss">
.my-account-area {
	position: relative;
	max-width: 160px;
	height: 100%;
	img.user-avatar {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		margin-right: 10px;
	}
	.user-name {
		color: #000000;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #13202b;
		cursor: pointer;
	}

	.mine-account-list {
		position: absolute;
		top: calc(100% - 15px);
		right: -25px;
		padding: 10px 0;
		box-sizing: border-box;
		background: #ffffff;
		flex-direction: column;
		width: 160px;
		box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
		border-radius: 8px;
		li {
			width: 100%;
			padding: 10px;
			box-sizing: border-box;
			text-align: center;
			font-size: 13px;
			font-family: PingFangSC-Light, PingFang SC;
			font-weight: 300;
			color: #000000;
		}
		li:hover {
			font-size: 13px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #000000;
			width: 110px;
			background: #ebf1ff;
			border-radius: 3px;
			cursor: pointer;
		}
	}
}
</style>
