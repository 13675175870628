<template>
	<div class="course">
		<div v-if="props.courseTitle" class="course-list-title flex-center-between">
			<div class="flex-center-start" @click="clickTitle">
				<h2 v-if="props.courseTitle" class="course-list-title-text">
					{{ props.courseTitle }}
				</h2>
				<h4 v-if="props.courseSubTitle" class="course-list-sub-title">{{ props.courseSubTitle }}</h4>
			</div>
			<router-link
				v-if="props.more"
				:to="{
					path: 'specialCourseList',
					query: {
						SpecialId: props.SpecialId,
						SpecialTitle: props.courseTitle,
					},
				}"
				class="course-list-title-more flex-center-end"
			>
				<img src="@/assets/images/more-icon2.png" alt="" />
			</router-link>
		</div>

		<!-- <HorizontalCourse :courseLists="props.courseLists" ></HorizontalCourse> -->
		<VerticalCourse :courseLists="props.courseLists" :isCard="props.isCard"></VerticalCourse>
	</div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import VerticalCourse from './VerticalCourse.vue';

const router = useRouter();
const props = defineProps({
	courseTitle: {
		type: String,
		default: '',
	},
	courseLists: {
		type: Array,
		default: [],
	},
	SpecialId: {
		type: String,
		default: '',
	},
	more: {
		type: Boolean,
		default: true,
	},
	courseSubTitle: {
		type: String,
		default: '',
	},
	ChannelId: {
		type: String,
		default: '',
	},
	isCard: Boolean,
});

// 点击更多左侧标题
const clickTitle = () => {
	if (!props.more) return;
	router.push({
		path: '/specialCourseList',
		query: {
			SpecialId: props.SpecialId,
			SpecialTitle: props.courseTitle,
		},
	});
};
</script>

<style lang="scss" scoped>
.course {
	width: 100%;
	.course-list-title {
		margin-bottom: 0.42rem;
		.course-list-title-text {
			font-size: 0.32rem;
			font-family: PingFangSC-Semibold, PingFang SC;
			font-weight: 600;
			color: #000000;
			line-height: 0.32rem;
		}
		.course-list-sub-title {
			margin-left: 0.2rem;
			font-size: 0.24rem;
			font-family: PingFangSC-Semibold, PingFang SC;
			font-weight: 600;
			color: #000000;
			line-height: 0.24rem;
		}
		.course-list-title-more {
			font-size: 0.28rem;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #666666;
			line-height: 0.2rem;
			cursor: pointer;
			img {
				width: 0.4rem;
				height: auto;
				margin-left: 0.1rem;
				cursor: pointer;
			}
		}
	}
	.go-list {
		display: block;
		.right-icon {
			display: block;
			height: 0.3rem;
			width: auto;
		}
	}
	.course-tab {
		width: 100%;
		position: relative;
		margin-bottom: 0.4rem;
		.course-tab-list {
			width: 100%;
			overflow-y: scroll;
			.course-tab-list-item {
				min-width: 1.6rem;
				height: 0.52rem;
				background: #ecf0ff;
				border-radius: 0.3rem;
				font-size: 0.28rem;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #333333;
				line-height: 0.52;
				margin-right: 0.2rem;
			}
			.course-tab-list-item-active {
				background: #342eff;
				color: #ffffff;
			}
		}
		.course-tab-list::-webkit-scrollbar {
			display: none;
		}
		.go-list {
			display: block;
			.right-icon {
				position: absolute;
				top: -0.06rem;
				right: -10px;
				z-index: 1;
				height: 0.32rem;
				width: auto;
			}
		}
	}
	.course-lists {
		width: 100%;
		// margin-top: 0.42rem;
		.course-lists-item {
			width: 100%;
			margin-bottom: 0.32rem;
			.course-lists-item-cover {
				width: 2.4rem;
				height: 1.4rem;
				display: block;
				margin-right: 0.2rem;
				font-size: 0.24rem;
			}
			.course-lists-item-content {
				flex: 1;
				height: 100%;
				h4 {
					width: 100%;
					min-height: 0.8rem;
					font-size: 0.3rem;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 600;
					color: #333333;
					line-height: 0.4rem;
					margin-bottom: 0.2rem;
				}
				.organ {
					.organ-logo {
						flex: 1;
						font-size: 0.24rem;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #999999;
						line-height: 0.24rem;
						letter-spacing: 1px;
						img {
							height: 0.24rem;
							width: auto;
							display: block;
							margin-right: 0.1rem;
						}
					}
					.organ-number {
						font-size: 0.24rem;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #999999;
						line-height: 0.24rem;
						img {
							display: block;
							width: 0.28rem;
							height: auto;
							margin-right: 0.16rem;
						}
					}
				}
			}
		}
	}
	.no-data {
		font-size: 0.3rem;
		padding: 0.2rem 0.32rem;
		box-sizing: border-box;
	}
}
</style>
