<template>
	<!-- 块类型 - 内容及要求 -->
	<template v-for="(blockItem, blockIndex) in props.columnsItem.Structure.Rows" :key="blockIndex">
		<template
			v-if="
				(blockItem.ClientDisplayMethod2 && blockItem.ClientDisplayMethod2.includes('H5')) ||
				(!blockItem.ClientDisplayMethod2 && blockItem.ClientDisplayMethod != 'PC')
			"
		>
			<!-- 行 -->
			<div v-if="!blockItem.Hide" :class="blockItem.Css">
				<!-- 列 -->
				<div
					v-for="(blockColumnsItem, blockColumnsIndex) in blockItem.Columns"
					:key="blockColumnsIndex"
					:class="blockColumnsItem.Css"
				>
					<!-- 隐藏列 -->
					<template v-if="!columnsItem.Hide">
						<!-- 图片链接列表开始 -->
						<MobilePictureLinkList
							v-if="blockColumnsItem.ColumnType == 'ImageLinkList'"
							:columnsItem="blockColumnsItem"
						></MobilePictureLinkList>
						<!-- 图片链接列表结束 -->

						<!-- 图片链接开始 -->
						<MobilePictureLink
							v-if="blockColumnsItem.ColumnType == 'ImageLink'"
							:columnsItem="blockColumnsItem"
						></MobilePictureLink>
						<!-- 图片链接结束 -->

						<!-- 文本链接列表开始、 -->
						<MobileTextLinkList
							v-if="blockColumnsItem.ColumnType == 'TextLinkList'"
							:columnsItem="blockColumnsItem"
							:event-detail="data.eventDetail"
							@login="data.notLoggedInVisible = true"
						></MobileTextLinkList>
						<!-- 文本链接列表结束 -->

						<!-- 文本列表开始 -->
						<MobileTextList :columnsItem="blockColumnsItem" v-if="blockColumnsItem.ColumnType == 'TextList'"></MobileTextList>
						<!-- 文本列表结束 -->

						<!-- 预约活动 - 开始 -->
						<EventAppointment
							:columnsItem="blockColumnsItem"
							v-if="blockColumnsItem.ColumnType == 'CurrEventAppointment'"
						></EventAppointment>
						<!-- 预约活动 - 结束 -->

						<!-- 媒体视频开始 -->
						<VideoObject :columnsItem="blockColumnsItem" v-if="blockColumnsItem.ColumnType == 'VideoObject'"></VideoObject>
						<!-- 媒体视频结束 -->

						<!-- 保利威视频开始 -->
						<PolyvVideoObject
							:columnsItem="blockColumnsItem"
							v-if="blockColumnsItem.ColumnType == 'PolyvVideoObject'"
						></PolyvVideoObject>
						<!-- 保利威视频结束 -->
					</template>
				</div>
			</div>
		</template>
	</template>
</template>
<script setup lang="ts">
import VideoObject from '@/views/mobile/event/components/VideoObject.vue';
import EventAppointment from '@/views/mobile/event/components/EventAppointment.vue';
import PolyvVideoObject from '@/views/mobile/event/components/PolyvVideoObject.vue';
const props = defineProps({
	columnsItem: {
		type: Object,
		default: {},
	},
});

// 背景样式
const backgroundStyle = (item: any) => {
	if (item.Css.includes('image')) {
		return 'background-image:url(' + item.Css.split('image-')[1] + ')';
	} else if (item.Css.includes('background')) {
		return 'background:' + item.Css.split('-')[1];
	} else {
		return 'background:' + '';
	}
};
</script>
