<template>
	<div class="media-object-list" :class="[{ 'flex-center-start': columnsItem.SameLine }, columnsItem.Css]">
		<div class="flex-center-between media-object-title">
			<!-- 隐藏标题 -->
			<div class="flex-center-center flex-1 pr_2" v-if="!props.columnsItem.HideHeader">
				<div class="flex-center-center flex-1">
					<div class="flex-center-center" @click="clickTitle">
						<h2 v-if="props.columnsItem.Title" class="main-title cursor-pointer">
							{{ props.columnsItem.Title }}
						</h2>
						<h4 v-if="props.columnsItem.SubTitle" class="sub-title cursor-pointer">
							{{ props.columnsItem.SubTitle }}
						</h4>
					</div>
					<div class="flex-1 oneDot">
						<TitleTextLinkList :list="data.TitleTextLink"></TitleTextLinkList>
					</div>
				</div>
			</div>
			<!-- MoreType == None 是暂无更多 -->
			<a
				v-if="JSON.parse(props.columnsItem.Data).MoreType != 'None' && props.more"
				@click="seeMore()"
				class="more flex-center-end"
				target="_blank"
			>
				更多
				<img src="@/assets/images/more-icon.png" alt="" />
			</a>
		</div>

		<!-- 课程列表 -->
		<div class="media-list flex-center-start" :style="{ marginBottom: props.marginBottom + 'px' }">
			<div
				v-for="(item, index) in JSON.parse(props.columnsItem.Data).Items"
				:key="index"
				class="media-list-item"
				@click="jumpRoute(item)"
			>
				<img v-if="item.ImageUrl" class="media-list-item-cover" :src="item.ImageUrl" :alt="item.Title" />
				<div class="media-list-item-content">
					<h4 class="twoDot">{{ item.Title }}</h4>
					<p v-if="item.Description" class="describe oneDot">
						{{ item.Description }}
					</p>
					<p v-if="item.Description2" class="describe oneDot">
						{{ item.Description2 }}
					</p>
					<p v-if="item.Description3" class="describe oneDot">
						{{ item.Description3 }}
					</p>
				</div>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { onMounted, reactive } from 'vue';
import { useRouter } from 'vue-router';
const router = useRouter();
const props = defineProps({
	columnsItem: {
		type: Object,
		default: {},
	},
	ChannelId: {
		type: String,
		default: '',
	},
	more: {
		type: Boolean,
		default: true,
	},
	marginBottom: {
		type: Number,
		default: 25,
	},
});
const data = reactive({
	TitleTextLink: [],
});

const handleTextLink = () => {
	if (props.columnsItem.TitleTextLink) {
		data.TitleTextLink = JSON.parse(props.columnsItem?.TitleTextLink).Items;
	}
};

onMounted(() => {
	handleTextLink();
});
// 查看更多
const seeMore = () => {
	const MoreDetails = JSON.parse(props.columnsItem.Data);
	if (MoreDetails.MoreType == 'Url') {
		const path = MoreDetails.More;
		window.location.href = path;
	} else if (MoreDetails.MoreType == 'Local') {
		let routeData: any;
		routeData = router.resolve({
			path: MoreDetails.More,
		});
		window.open(routeData.href, '_blank');
	}
};
// 跳转路径
const jumpRoute = (item: any) => {
	// if (item.TargetType == 'Url') {
	// 	const path = item.Target;
	// 	window.location.href = path;
	// } else if (item.TargetType == 'Local') {
	// 	const path = item.Target;
	// 	window.location.href = path;
	// 	// router.push({
	// 	//   path: item.Target,
	// 	// });
	// }

	const w = window.open('about:blank');
	w.location.href = item.Target;
};

// 点击更多左侧标题
const clickTitle = () => {
	if (JSON.parse(props.columnsItem.Data).MoreType == 'None') return;
	seeMore();
};
</script>
<style scoped lang="scss">
.media-object-list {
	flex: 1;
	width: 100%;
	.media-object-title {
		margin-bottom: 25px;

		.main-title {
			font-size: 20px;
			font-family: PingFangSC-Semibold, PingFang SC;
			font-weight: 600;
			color: #000000;
		}
		.sub-title {
			margin-left: 20px;
			font-size: 13px;
			font-family: PingFangSC-Semibold, PingFang SC;
			font-weight: 600;
			color: #000000;
		}
		.more {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #666666;
			line-height: 20px;
			cursor: pointer;
			img {
				width: 16px;
				height: auto;
				margin-left: 5px;
				cursor: pointer;
			}
		}
	}
}
.media-list {
	flex-wrap: wrap;
	.media-list-item {
		width: 24%;
		max-width: 277px;
		text-decoration: none;
		background: #ffffff;
		box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.14);
		border-radius: 3px;
		box-sizing: border-box;
		cursor: pointer;
		// margin-right:calc(6% / 3);

		&:not(:nth-of-type(4n)) {
			margin-right: calc(7% / 3);
		}
		&:nth-of-type(4n) {
			margin-right: 0;
		}

		@media screen and (max-width: 1350px) {
			&:not(:nth-of-type(4n)) {
				margin-right: calc(4% / 3);
			}
		}

		.media-list-item-cover {
			width: 100%;
			// height: auto;
			height: 165px;
			display: block;
			border-radius: 4px 4px 0 0;
			font-size: 12px;
			color: #000000;
		}
		.media-list-item-content {
			padding: 20px 15px 0 15px;
			box-sizing: border-box;
			h4 {
				min-height: 47px;
				font-size: 16px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 600;
				color: #000000;
				line-height: 24px;
				// margin-bottom: 12px;
			}
			.describe {
				font-size: 13px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #999999;
				line-height: 13px;
				margin-bottom: 18px;
			}
			.organ {
				border-top: 1px solid #eeeeee;
				padding: 12px 0;
				box-sizing: border-box;
				margin-top: 25px;
				.organ-logo {
					font-size: 12px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #2655fb;
					line-height: 20px;
				}
				.organ-number {
					font-size: 12px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #757575;
					line-height: 13px;
					img {
						width: 16px;
						height: auto;
						margin-right: 8px;
					}
				}
			}
		}
	}
}
</style>
