<template>
	<router-view />
	<!-- 调查问卷 -->
	<div class="questionnaire-container">
		<a class="questionnaire-content" href="https://www.wjx.cn/vm/YjEEXAG.aspx" target="_blank">
			<img class="questionnaire-icon" src="@/assets/images/question-icon.png" alt="" />用户体验问卷调查<img
				class="right-icon"
				src="@/assets/images/arrow-right-white-icon.png"
				alt=""
			/>
		</a>
	</div>
	<!-- 咨询客服 -->
	<!-- pc端 -->
	<Affix v-if="data.showAffix"></Affix>
	<!-- 移动端 -->
	<div class="udeskMobile">
		<a
			href="https://ouconline.s4.udesk.cn/im_client/?web_plugin_id=35122&group_id=14434"
			class="udeskContainer udeskContainerMobile flex-center-center"
		>
			<div class="udeskContainerIcon flex-center-center">
				<img src="@/assets/images/udesk-icon2.png" alt="" />
			</div>
		</a>
	</div>
	<!-- 完善个人资料弹框 -->
	<div v-if="data.studentIsRemind == 'true'">
		<BasePopup
			:basePopupContent="(data.basePopupContent = '请完善您的个人资料！')"
			@basePopupCancel="basePopupCancel"
			@basePopupConfirm="basePopupConfirm"
		>
		</BasePopup>
	</div>
	<ChatGptQuestionAnswer :is-show="data.chatShow" @hide="hideChat"></ChatGptQuestionAnswer>
</template>
<script setup lang="ts">
import { getCurrentInstance, reactive, watch } from 'vue';
import BasePopup from '@/components/basePopup/BasePopup.vue';
import { PutStudentInfoApi } from '@/http/api/student';
import { useRouter } from 'vue-router';
import Affix from '@/components/affix/index.vue';
import ChatGptQuestionAnswer from './components/chatGptQuestionAnswer/ChatGptQuestionAnswer.vue';
import { useStore } from 'vuex';
const { proxy } = getCurrentInstance() as any;
const router = useRouter();
const store = useStore();
const data = reactive({
	studentIsRemind: proxy.$cookies.get('studentIsRemind') ? proxy.$cookies.get('studentIsRemind') : false,
	chatShow: false,
	showAffix: !(location.href.indexOf('digitalHuman') >= 0 || location.href.indexOf('unique') >= 0),
});

watch(
	() => store.state.logoClickNum,
	() => {
		data.chatShow = store.state.logoClickNum == 4;
	},
);

// 弹框取消按钮
const basePopupCancel = () => {
	data.studentIsRemind = false;
	// 存储用户提醒信息
	proxy.$cookies.remove('studentIsRemind');
	proxy.$cookies.set('studentIsRemind', false);
	// 设置个人完善资料
	putStudentInfoApi();
};
// 弹框确认按钮
const basePopupConfirm = () => {
	data.studentIsRemind = false;
	proxy.$cookies.remove('studentIsRemind');
	proxy.$cookies.set('studentIsRemind', false);
	// 设置个人完善资料
	putStudentInfoApi();
	router.push('/mine/editUserInfo');
};
// 设置学生提醒信息
const putStudentInfoApi = () => {
	PutStudentInfoApi({})
		.then((res: any) => {
			// 存储用户提醒信息
			proxy.$cookies.remove('studentIsRemind');
			proxy.$cookies.set('studentIsRemind', res.IsRemind);
			// store.commit("studentIsRemind", res.IsRemind);
		})
		.catch((err: any) => {
			console.log(err);
		});
};
// 问答弹窗隐藏
const hideChat = () => {
	data.chatShow = false;
	store.commit('setLogoClickNum', 0);
};
</script>
<style>
/* 调查问卷 */
.questionnaire-container {
	display: none;
	position: fixed;
	bottom: 20%;
	right: 0px;
	z-index: 10000;
	cursor: pointer;
}
.questionnaire-content {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #c81722;
	padding: 12px 15px 12px 20px;
	box-sizing: border-box;
	border-radius: 50px 0 0 50px;
	font-size: 16px;
	color: #ffffff;
}
.questionnaire-icon {
	width: 24px;
	height: auto;
	margin-right: 10px;
}
.right-icon {
	width: 14px;
	height: auto;
	margin-left: 5px;
}
/* 客服 */
.udeskContainer {
	position: fixed;
	bottom: 10%;
	right: 30px;
	height: 40px;
	background: rgb(48, 122, 232);
	z-index: 10000;
	cursor: pointer;
}
.udeskContainer .udeskContainerIcon {
	width: 40px;
	height: 40px;
	border-right: 1px solid rgba(0, 0, 0, 0.08);
}
.udeskContainer .udeskContainerIcon img {
	width: 24px;
	height: 24px;
}

.udeskMobile {
	display: none;
}
.udeskContainerMobile {
	right: 20px;
	width: 40px;
	border-radius: 50%;
}
</style>
