<template>
	<div class="special-courses-list" :class="[{ 'flex-center-start': columnsItem.SameLine }, columnsItem.Css]">
		<!-- 隐藏标题 -->
		<div v-if="!props.columnsItem.HideHeader" class="title">
			{{ props.columnsItem.Title }}
		</div>
		<!-- 课程列表 -->
		<!-- 课程系列 -->
		<div class="course-series-item" v-for="(item, index) in data.specialCoursesList" :key="index">
			<CourseList
				:courseTitle="item.Name"
				:courseSubTitle="item.SubTitle"
				:SpecialId="item.Id"
				:courseLists="item.Courses"
				:textLinkList="item.TitleTextLink"
				:ChannelId="props.ChannelId"
			></CourseList>
		</div>
	</div>
</template>
<script setup lang="ts">
import { onMounted, reactive } from 'vue';
import CourseList from '@/components/courseList/CourseList.vue';
import { PostSpecialsApi } from '@/http/api/course';
import { useRouter } from 'vue-router';
const router = useRouter();
const props = defineProps({
	columnsItem: {
		type: Object,
		default: {},
	},
	ChannelId: {
		type: String,
		default: '',
	},
});
// console.log(props.columnsItem);
const data = reactive<any>({
	specialList: [], //专题数组
	specialIdList: [], //专题id数组
	specialCoursesList: [], //专题课程列表
});
// 专题列表
onMounted(() => {
	data.specialList = JSON.parse(props.columnsItem.Data)['Items']; //专题数组

	data.specialList.forEach((listItem: any) => {
		if (listItem.Title == '猜你喜欢') {
			listItem.SpecialId = 'HotSale';
		}
		let obj: any = {
			Key: listItem.SpecialId + '',
			Value: listItem.Count + '',
		};
		data.specialIdList.push(obj);
	});
	data.specialIdList;
	//   获取专题列表
	getSpecialsList();
});

// 获取专题课程列表
const getSpecialsList = () => {
	PostSpecialsApi(data.specialIdList)
		.then((res: any) => {
			const specialCoursesList = res;

			data.specialList.forEach((item: any) => {
				const courseItem = specialCoursesList.find((v: any) => item.SpecialId == v.Id);
				courseItem['SubTitle'] = item.SubTitle || '';
				courseItem['TitleTextLink'] = item.TitleTextLink ? JSON.parse(item.TitleTextLink)?.Items || [] : [];
			});

			data.specialCoursesList = specialCoursesList;
		})
		.catch((err: any) => {
			console.log(err);
		});
};
</script>
<style scoped lang="scss"></style>
