<template>
	<div class="container">
		<img v-if="data.userInfo && data.userInfo.Avatar" class="head" :src="data.userInfo.Avatar" alt="" />
		<img v-else src="@/assets/images/head-pic-default.png" class="head" alt="" />
		<div class="content">
			<div class="flex-center-end user-name">{{ data.userInfo.Name }}</div>
			<div class="text" v-if="props.type === 1">
				{{ props.content }}
			</div>
			<img class="img" :src="props.content" v-else-if="props.type === 2" />
		</div>
	</div>
</template>

<script setup lang="ts">
import { getCurrentInstance, reactive } from 'vue';
const { proxy } = getCurrentInstance() as any;
const props = defineProps({
	content: String,
	type: Number,
});
const data = reactive({ userInfo: proxy.$cookies.get('userInfo') });
</script>

<style scoped lang="scss">
.container {
	display: flex;
	padding: 10px 15px;
	margin-left: 60px;
	flex-direction: row-reverse;

	.head {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		border: 1px solid #eee;
	}

	.content {
		margin-right: 10px;
		margin-top: 10px;

		.user-name {
			margin-bottom: 11px;
			font-size: 13px;
			font-family: ArialMT;
			color: #58617e;
			line-height: 22px;
		}

		.text {
			background: #316efa;
			border-radius: 13px;
			word-break: break-all;
			font-size: 16px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #ffffff;
			line-height: 22px;
			padding: 18px 20px;
		}

		.img {
			width: 100px;
			border-bottom-right-radius: 10px;
			border-top-right-radius: 10px;
			border-bottom-left-radius: 10px;
		}
	}
}
</style>
