<template>
	<div
		class="picture-link-list-container"
		:class="[{ 'flex-center-start': columnsItem.SameLine }]"
		:style="{ marginBottom: props.marginBottom }"
	>
		<!-- 隐藏标题 -->
		<div v-if="!props.columnsItem.HideHeader" class="title">
			{{ props.columnsItem.Title }}
		</div>
		<!-- 学校列表 -->
		<div class="direction_row picture-link-list">
			<div
				v-for="(item, index) in JSON.parse(props.columnsItem.Data).Items"
				:key="index"
				class="picture-link-list-item flex-center-center"
			>
				<!-- 外部链接 -->
				<a :href="item.Target" v-if="item.TargetType == 'Url'" target="_blank">
					<img :class="item.Css" :src="item.ImageUrl" alt="" />
				</a>
				<!-- 本地链接 -->
				<router-link v-else-if="item.TargetType == 'Local'" class="school-content-list-item" :to="item.Target" target="_blank">
					<img :class="item.Css" :src="item.ImageUrl" alt="" />
				</router-link>
				<!-- 无 链接 -->
				<a v-else>
					<img :class="item.Css" :src="item.ImageUrl" alt="" />
				</a>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { onMounted, reactive } from 'vue';
import { useRouter } from 'vue-router';
const router = useRouter();
const props = defineProps({
	columnsItem: {
		type: Object,
		default: {},
	},
	ChannelId: {
		type: String,
		default: '',
	},
	marginBottom: {
		type: String,
		default: '50px',
	},
});
// console.log(props.columnsItem);
const data = reactive({});
</script>
<style scoped lang="scss">
.picture-link-list-container {
	flex: 1;
	width: 100%;

	.title {
		font-size: 20px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #000000;
		line-height: 28px;
		margin-bottom: 20px;
	}
	.picture-link-list {
		width: 100%;
		flex-wrap: wrap;
		.picture-link-list-item {
			cursor: pointer;
			background: #ffffff;
			box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
			border-radius: 5px;
			margin-right: 13px;
			margin-bottom: 25px;
			width: 160px;
			height: 60px;
			a {
				display: block;
				display: flex;
				align-items: center;
				justify-content: center;
				img {
					display: block;
					width: 150px;
					height: 40px;
				}
			}
		}
		.picture-link-list-item:nth-of-type(7n) {
			margin-right: 0;
		}
	}
}
</style>
