<template>
	<!-- 提示框 -->
	<el-dialog
		draggable
		center
		:show-close="false"
		:close-on-click-modal="false"
		v-model="data.basePopupAllVisible"
		modal-class="base-dialog"
	>
		<div class="base-popup-title flex-center-start">提醒</div>
		<div class="base-popup-content flex-center-center">
			<img class="tips-icon" src="@/assets/images/tips-icon.png" alt="" />
			{{ props.basePopupContent }}
		</div>
		<template #footer>
			<span class="dialog-footer">
				<el-button type="primary" @click="basePopupConfirm()">确认</el-button>
				<el-button v-if="props.showCancel" @click="basePopupCancel()">取消</el-button>
			</span>
		</template>
	</el-dialog>
</template>
<script setup lang="ts">
import { reactive } from 'vue';
const emit = defineEmits(['basePopupConfirm', 'basePopupCancel']);
const props = defineProps({
	basePopupContent: {
		type: String,
		default: '',
	},
	showCancel: {
		type: Boolean,
		default: true,
	},
});
const data = reactive({
	basePopupAllVisible: true,
});
// 确认
const basePopupConfirm = () => {
	emit('basePopupConfirm');
};
// 取消
const basePopupCancel = () => {
	emit('basePopupCancel');
};
</script>
<style lang="scss">
// 提示框
.base-dialog {
	position: fixed;
	top: 0;
	bottom: constant(safe-area-inset-bottom);
	bottom: env(safe-area-inset-bottom);
	left: 0;
	right: 0;
	z-index: 2002;
	.el-overlay-dialog {
		position: fixed;
		top: 0;
		bottom: constant(safe-area-inset-bottom);
		bottom: env(safe-area-inset-bottom);
		left: 0;
		right: 0;
		.el-dialog {
			width: 30%;
			border-radius: 6px;
			padding: 0 20px;
			box-sizing: border-box;
			.el-dialog__header {
				padding: 0;
			}
			.el-dialog__body {
				padding: 0px !important;
				box-sizing: border-box;
				.base-popup-title {
					padding: 16px 0 10px 0;
					box-sizing: border-box;
					font-size: 16px;
					font-family: PingFangSC-Semibold, PingFang SC;
					font-weight: 600;
					color: #333333;
					line-height: 16px;
					letter-spacing: 1px;
					border-bottom: 1px solid #d0daf3;
				}
				.base-popup-content {
					padding: 46px 0px !important;
					box-sizing: border-box;
					font-size: 16px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #333333;
					line-height: 20px;
					.tips-icon {
						width: 20px;
						height: auto;
						margin-right: 10px;
					}
				}
			}

			.el-dialog__footer {
				padding: 10px 0px 36px 0px !important;
				box-sizing: border-box;
				.dialog-footer {
					.el-button {
						width: 148px;
						height: 42px;
						border-radius: 6px;
						font-size: 14px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						border: 1px solid #bfc8dd;
						color: #707795;
						text-align: center;
					}
					.el-button--primary {
						color: #ffffff;
						background: #3464f6;
						border: 1px solid #3464f6;
					}
				}
			}
		}
	}
}
@media screen and (min-width: 320px) and (max-width: 750px) {
	.base-dialog {
		z-index: 20000 !important;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: constant(safe-area-inset-bottom);
		bottom: env(safe-area-inset-bottom);
		.el-overlay-dialog {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: constant(safe-area-inset-bottom);
			bottom: env(safe-area-inset-bottom);
			.el-dialog {
				width: 90% !important;
				--el-dialog-width: 90% !important;
				border-radius: 6px;
				padding: 20px;
				box-sizing: border-box;
				.el-dialog__footer {
					padding: 10px 0px 10px 0px !important;
					box-sizing: border-box;
					.dialog-footer {
						.el-button {
							width: 2rem !important;
							height: 0.7rem !important;
						}
					}
				}
			}
		}
	}
}
</style>
