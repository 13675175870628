<template>
	<div class="picture-link-list-container" :class="[{ 'flex-center-start': columnsItem.SameLine }]">
		<!-- 隐藏标题 -->
		<div v-if="!props.columnsItem.HideHeader" class="title flex-center-center" :class="columnsItem.Css">
			<!-- {{ props.columnsItem.Title }} -->
			<img
				v-if="props.columnsItem.Title == '主办单位' || columnsItem.Css == 'sponsor-title'"
				src="@/assets/images/event-sponsor-title.png"
				alt=""
			/>
			<img
				v-else-if="props.columnsItem.Title == '协办单位' || columnsItem.Css == 'co-organizer-title'"
				src="@/assets/images/event-co-organizer-title.png"
				alt=""
			/>
			<div v-else>{{ props.columnsItem.Title }}</div>
		</div>
		<!-- 图片列表 -->
		<div class="picture-link-list">
			<div
				v-for="(item, index) in JSON.parse(props.columnsItem.Data).Items"
				:key="index"
				class="picture-link-list-item"
				@click="jumpRoute(item)"
			>
				<img :class="item.Css" :src="item.ImageUrl" alt="" class="picture-link-img" />
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { getCurrentInstance } from 'vue';
import { GetLibraryExternalUrl } from '@/http/api/event';

const { proxy } = getCurrentInstance() as any;
const is_login = proxy.$cookies.get('loginStatus');
const emit = defineEmits(['login']);

const props = defineProps({
	columnsItem: {
		type: Object,
		default: {},
	},
	ChannelId: {
		type: String,
		default: '',
	},
	// 指定活动详情
	eventDetail: {
		type: Object,
		default: {},
	},
});
// 跳转路径
const jumpRoute = (item: any) => {
	if (item.TargetType === 'None') return;

	const path = item.Target;
	if (!path || path === '无') return;
	const { EventRequireLogin, ExternalLinkRequireLogin, LibraryId } = props.eventDetail;
	// 外部链接和内部链接未登录进行强制登录
	if (!is_login) {
		if ((item.TargetType == 'Url' && ExternalLinkRequireLogin) || (item.TargetType == 'Local' && EventRequireLogin)) {
			emit('login');
			return;
		}
	}

	// 如果没有配置资源库而且需要跳转
	if (!LibraryId && (item.TargetType == 'Url' || item.TargetType == 'Local')) {
		toUrl(path);
		return;
	}

	GetLibraryExternalUrl({ Url: encodeURIComponent(path), LibraryId }).then((res) => {
		toUrl(res);
	});
};

const toUrl = (url: any) => {
	const w = window.open('about:blank');
	w.location.href = url;
};
</script>
<style scoped lang="scss">
.picture-link-list-container {
	// width: 90%;
	// max-width: 1200px;
	// margin: 0 auto;
	// padding-bottom: 80px;
	// box-sizing: border-box;
	.title {
		font-size: 16px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 600;
		color: #000000;
		line-height: 21px;
		margin-right: 24px;
		vertical-align: middle;
		border-right: 1px solid #eeeeee;
		margin-bottom: 58px;
		img {
			height: 34px;
			width: auto;
		}
	}
	.picture-link-list {
		.picture-link-list-item {
			height: 100%;
			// width: 300px;
			// height: 60px;
			// background: #ffffff;
			// border-radius: 4px;
			// margin: 0 25px;
			.picture-link-img {
				display: block;
				width: 100%;
				height: 100%;
			}
		}
	}
}
</style>
