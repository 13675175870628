<template>
	<div class="mobile-picture-link-list" :class="[{ 'flex-center-start': columnsItem.SameLine }]">
		<MobileListTitle :more="true" :columnsItem="props.columnsItem" v-if="!props.columnsItem.HideHeader"></MobileListTitle>
		<!-- 学校列表 -->
		<div class="list flex-center-start" :class="columnsItem.Css">
			<div v-for="(item, index) in JSON.parse(props.columnsItem.Data).Items" :key="index" class="picture-link-list-item">
				<!-- 外部链接 -->
				<router-link :to="item.Target" v-if="item.TargetType == 'Url'">
					<img :class="item.Css" :src="item.ImageUrl" alt="" />
				</router-link>
				<!-- 本地链接 -->
				<router-link v-else-if="item.TargetType == 'Local'" class="school-content-list-item" :to="item.Target">
					<img :class="item.Css" :src="item.ImageUrl" alt="" />
				</router-link>
				<!-- 无 链接 -->
				<img :class="item.Css" :src="item.ImageUrl" alt="" v-else />
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { onMounted, reactive } from 'vue';
import { useRouter } from 'vue-router';
const router = useRouter();
const props = defineProps({
	columnsItem: {
		type: Object,
		default: {},
	},
	ChannelId: {
		type: String,
		default: '',
	},
});
// console.log(props.columnsItem);
const data = reactive({});
</script>
<style scoped lang="scss">
.mobile-picture-link-list {
	flex: 1;
	width: 100%;
	padding: 0.18rem 0.32rem;
	box-sizing: border-box;
	background-color: #ffffff;
	position: relative;
	margin-top: 0.3rem;
	border-radius: 0.16rem;

	.list {
		width: 100%;
		overflow-x: auto;
		/* 隐藏滚动条 */
		scrollbar-width: none;
		/* firefox */
		-ms-overflow-style: none;
		/* IE 10+ */
		display: flex;
		flex-direction: row;
		.picture-link-list-item {
			margin-right: 0.2rem;
			&:last-of-type {
				margin-right: 0;
			}
			img {
				display: block;
				width: auto;
				height: 1.68rem;
			}
		}
	}
	.list::-webkit-scrollbar {
		display: none;
		/* Chrome Safari */
	}
	.list-end-shadow {
		position: absolute;
		right: 0;
		top: 0;
		width: 1.2rem;
		max-height: 0.96rem;
		z-index: 10;
		background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 70%);
		border-radius: 0.16rem;
	}
}
</style>
