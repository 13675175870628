<template>
	<Advertise :columnsItem="data.advertise" v-if="data.advertise" @hide="hideModal()"></Advertise>
	<div class="home" id="backTop" :class="data.PageStyle">
		<!-- 头部 -->
		<BaseHeader :is-show-course-module="false" @logo-click="data.chatShow = true" />
		<div class="home-content-container" v-if="data.CacheBody">
			<div v-for="(item, index) in data.CacheBody" :key="index">
				<!-- PC端显示还是移动端显示 -->
				<div
					v-if="
						(item.ClientDisplayMethod2 && item.ClientDisplayMethod2.includes('PC')) ||
						(!item.ClientDisplayMethod2 && item.ClientDisplayMethod != 'Mobile')
					"
				>
					<!-- 行 -->
					<div v-if="!item.Hide" class="col_1" :class="item.Css">
						<!-- 列 -->
						<div v-for="(columnsItem, carouselIndex) in item.Columns" :key="carouselIndex">
							<!-- 隐藏列 -->
							<div v-if="!columnsItem.Hide" :class="columnsItem.Css">
								<!-- 类型  标题和内容是否在同行  css样式 -->
								<!-- ActiveModalWindow -->
								<div v-if="columnsItem.ColumnType == 'ActiveModalWindow'"></div>
								<!-- 轮播图开始 -->
								<div v-if="columnsItem.ColumnType == 'Carousel'">
									<!-- <BaseSwiper :columnsItem="columnsItem" :ChannelId="data.ChannelId" /> -->
									<BannerModule :columnsItem="columnsItem" />
								</div>
								<!-- 轮播图结束 -->
								<!-- 图片链接列表开始 -->
								<div v-if="columnsItem.ColumnType == 'ImageLinkList'" class="background_fa width_100">
									<div class="default_width col_1">
										<div class="flex-start-between picture-box">
											<div class="flex-1">
												<PictureLinkList :columnsItem="columnsItem" margin-bottom="0px"></PictureLinkList>
											</div>
											<div class="tips-box">
												<img src="@/assets/images/common/partners-icon.png" alt="" class="partner-icon" />
											</div>
										</div>
									</div>
								</div>

								<!-- 图片链接列表结束 -->
								<!-- 排行榜模块开始 -->
								<CourseRank v-if="columnsItem.ColumnType == 'RankingList'" :params="columnsItem.Data"></CourseRank>
								<!-- 排行榜模块结束 -->
								<!-- 文本链接列表开始、 -->
								<div v-if="columnsItem.ColumnType == 'TextLinkList'">
									<TextLinkList :columnsItem="columnsItem"></TextLinkList>
								</div>
								<!-- 文本链接列表结束 -->
								<!-- 最新专题课程列表开始 -->
								<div v-if="columnsItem.ColumnType == 'NewestSpecialCourseList'">
									<SpecialCourseList :columnsItem="columnsItem"></SpecialCourseList>
								</div>
								<!-- 最新专题课程列表结束 -->
								<!-- 最新专题图书列表开始 -->
								<div v-if="columnsItem.ColumnType == 'NewestSpecialBookList'">
									<SpecialBookList :columnsItem="columnsItem"></SpecialBookList>
								</div>
								<!-- 文本列表 -->
								<div v-if="columnsItem.ColumnType == 'TextList'">
									<TextList :columnsItem="columnsItem"></TextList>
								</div>
								<!-- 图片链接 -->
								<div v-if="columnsItem.ColumnType == 'ImageLink'">
									<PictureLink :columnsItem="columnsItem"></PictureLink>
								</div>
								<!-- 媒体对象 -->
								<div v-if="columnsItem.ColumnType == 'MediaObject'">
									<MediaObjectList :columnsItem="columnsItem"></MediaObjectList>
								</div>
								<BlockStructure :columns-item="columnsItem"></BlockStructure>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 底部 -->
		<BaseFooter></BaseFooter>
	</div>
</template>
<script setup lang="ts">
import { reactive, getCurrentInstance } from 'vue';
import BaseHeader from '@/components/baseHeader/BaseHeader.vue';
import PictureLinkList from '@/components/customColumnType/PictureLinkList.vue';
import TextLinkList from '@/components/customColumnType/TextLinkList.vue';
import TextList from '@/components/customColumnType/TextList.vue';
import SpecialCourseList from '@/components/customColumnType/SpecialCoursesList.vue';
import PictureLink from '@/components/customColumnType/PictureLink.vue';
import MediaObjectList from '@/components/customColumnType/MediaObjectList.vue';
import BaseFooter from '@/components/baseFooter/BaseFooter.vue';
import { GetViewApi } from '@/http/api/basedata';
import { useStore } from 'vuex';
import { onMounted } from 'vue';
const store = useStore();
const { proxy } = getCurrentInstance() as any;
const data = reactive<any>({
	CacheBody: [], //渲染页面
	ChannelId: 'home', //频道id
	PageStyle: '', //页面灰度
	advertise: null,
});

onMounted(() => {
	if (!proxy.$cookies.get('adStatus')) {
		proxy.$cookies.set('adStatus', 0);
	}
});

// ------------------请求接口开始-------------------
// 获取页面
const getView = () => {
	const params = {
		DependentType: 'homepage',
		DependentId: data.ChannelId,
	};
	GetViewApi(params)
		.then((res: any) => {
			// 渲染首页页面
			data.CacheBody = JSON.parse(res.CacheBody).Rows;
			data.PageStyle = JSON.parse(res.CacheBody).Css;
			// console.log(data.CacheBody);
			data.CacheBody.forEach((item: any, index: number) => {
				if (item.ClientDisplayMethod != 'Mobile' && !item.Hide) {
					// 如果不是轮播图&&图片列表，后台管理系统忘了加宽度，给其他都加默认宽度
					if (
						item.Columns.find((v: any) => v.ColumnType === 'Carousel') ||
						item.Columns.find((v: any) => v.ColumnType === 'ImageLinkList')
					) {
						data.CacheBody[index].Css = 'width_100';
					} else {
						item.Css += ' default_width';
					}

					if (item.Columns.find((v: any) => v.ColumnType === 'ActiveModalWindow')) {
						if (proxy.$cookies.get('adStatus') == 0) {
							data.advertise = item.Columns.find((v: any) => v.ColumnType === 'ActiveModalWindow');
							// 首页模态框展示10秒，6秒后渐隐
							setTimeout(() => hideModal(), 10000);
						}
					}

					if (item.Columns.find((v: any) => v.ColumnType === 'RankingList')) {
						item.Columns[0].Data = item.Columns.map((v: any) => v.Data);
						item.Columns = [item.Columns[0]];
					}
				}
			});
		})
		.catch((err: any) => {
			console.log(err);
		});
};
getView();

const hideModal = () => {
	data.advertise = null;
	proxy.$cookies.set('adStatus', 1);
};
</script>

<style scoped lang="scss">
@import './index.scss';
</style>
