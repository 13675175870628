<template>
	<div class="password-wrapper">
		<div class="mobile-text">你的手机号：{{ props.mobile }}</div>
		<div class="main-wrapper">
			<el-input
				v-model="data.password"
				type="password"
				placeholder="请输入密码"
				show-password
				class="set-password-input"
				@blur="handleVerify"
				@input="handleVerify"
			></el-input>
			<div class="tips-area">
				<div class="check-tips error-tips" v-if="data.pwdFormatError">
					<img src="@/assets/images/mine/password/error.png" alt="" class="check-icon" />包含一个字母一个数字八个字符
				</div>
				<div class="check-tips" v-else>
					<img src="@/assets/images/mine/password/check.png" alt="" class="check-icon" />包含一个字母一个数字八个字符
				</div>

				<div class="check-tips error-tips" v-if="data.pwdTrimError">
					<img src="@/assets/images/mine/password/error.png" alt="" class="check-icon" />密码不能包含空格
				</div>
				<div class="check-tips" v-else>
					<img src="@/assets/images/mine/password/check.png" alt="" class="check-icon" />密码不能包含空格
				</div>

				<!-- <div class="check-tips error-tips" v-if="data.pwdLengthError">
					<img src="@/assets/images/mine/password/error.png" alt="" class="check-icon" />密码长度为8-20位
				</div>
				<div class="check-tips" v-else>
					<img src="@/assets/images/mine/password/check.png" alt="" class="check-icon" />密码长度为8-20位
				</div> -->
			</div>
			<div class="confirm-btn" @click="toNext">
				<div class="btn" :class="{ 'btn-disabled': confirmDisabled }">下一步</div>
				<div class="return-text" @click="cancelSetPwd" v-if="props.showCancel">跳过</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { getCurrentInstance, reactive, computed } from 'vue';
import { emitter } from '@/utils/EventBus';
import { plusXing } from '@/utils/common';

const { proxy } = getCurrentInstance() as any;
const confirmDisabled = computed(() => data.pwdTrimError || data.pwdFormatError || !data.password);
const data: any = reactive({
	pwdTrimError: false, //校验密码是否有空格
	pwdFormatError: false, //校验密码格式是否正确
	pwdLengthError: false, //校验密码长度是否符合要求
	password: '',
});

const props = defineProps({
	//如果之前已填密码,传过来的密码
	OldPassword: {
		type: String,
		default: '',
	},

	// 是否显示跳过
	showCancel: {
		type: Boolean,
		default: true,
	},

	mobile: {
		type: String,
		default: '',
	},
});

data.password = props.OldPassword;

const handleVerify = () => {
	// data.pwdLengthError = (data.password && data.password.length < 8) || data.password.length > 20;
	data.pwdTrimError = data.password && !/^[^\s]*$/.test(data.password);
	data.pwdFormatError = data.password && !/^(?=.*[a-zA-Z])(?=.*\d)[^\s]{8,}$/.test(data.password);
};

// 点击下一步切换输入验证码弹窗
const toNext = () => {
	if (confirmDisabled.value) return;
	emitter.emit('toVerifyCode', data.password);
};

// 取消输入
const cancelSetPwd = () => {
	emitter.emit('cancelSetPwd');
};
</script>

<style scoped lang="scss">
.password-wrapper {
	background: #ffffff;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	margin: 30px auto;

	.mobile-text {
		margin-bottom: 20px;
	}

	.mobile-tips {
		font-size: 14px;
		font-family: PingFang SC, PingFang SC;
		font-weight: 400;
		color: #999999;
		margin-bottom: 40px;
	}

	.main-wrapper {
		.check-tips:first-of-type {
			margin-top: 12px;
		}

		.tips-area {
			margin-top: 20px;

			.check-tips {
				font-size: 14px;
				font-family: PingFang SC, PingFang SC;
				font-weight: 400;
				color: #999999;
				margin-bottom: 12px;

				.check-icon {
					width: 18px;
					height: 18px;
					vertical-align: bottom;
					margin-right: 8px;
				}
			}

			.error-tips {
				color: #f1291b;
			}
		}

		.error-tips {
			font-size: 14px;
			font-family: PingFang SC, PingFang SC;
			font-weight: 400;
			color: #f1291b;
		}
	}

	.confirm-btn {
		margin-top: 38px;
		margin-bottom: 40px;

		.btn {
			height: 42px;
			background: #3464f6;
			border-radius: 21px;
			color: #ffffff;
			justify-content: center;
			align-items: center;
			display: flex;
			cursor: pointer;
		}

		.btn-disabled {
			background-color: #e3e4eb;
		}

		.return-text {
			font-weight: 400;
			font-size: 14px;
			color: #3c6bff;
			text-align: center;
			cursor: pointer;
			margin-top: 24px;
		}
	}
}
</style>
<style lang="scss">
.password-wrapper {
	padding: 0;

	.set-password-input {
		.el-input__inner {
			width: 360px !important;
			height: 46px;
			background: #f5f6fa;
			border-radius: 23px;
			font-size: 14px;
			font-family: PingFang SC, PingFang SC;
			font-weight: 400;
			padding-left: 16px;
		}
	}
}
</style>
