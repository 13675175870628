<template>
	<div class="hot-course-wrapper-item" :style="{ backgroundImage: 'url(' + props.Bg + ')' }">
		<div class="hot-title flex-center-between" @click="toMore">
			<div class="rank-title-a" :style="{ color: props.TitleColor }">{{ props.Name }} <span>TOP20</span></div>
			<img src="@/assets/images/rank/mobile/more-icon.png" alt="" class="more-icon" />
		</div>
		<div
			class="flex-center-start hot-course-wrapper-line"
			v-for="(pageItem, pageIndex) in data.rankList"
			:key="pageIndex"
			@click="clickCourse(pageItem)"
		>
			<div class="course-cover">
				<img :src="pageItem.Cover" alt="" class="cover-icon" />
				<img src="@/assets/images/rank/rank-icon1.png" alt="" class="rank-icon" v-if="pageIndex === 0" />
				<img src="@/assets/images/rank/rank-icon2.png" alt="" class="rank-icon" v-if="pageIndex === 1" />
				<img src="@/assets/images/rank/rank-icon3.png" alt="" class="rank-icon" v-if="pageIndex === 2" />
			</div>
			<div class="flex-column-1 height_1 right-info">
				<div class="course-title twoDot">{{ pageItem.Name }}</div>
				<div class="source-name">{{ pageItem.SourceName }}</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { toDetails } from '@/utils/event/courseList';
import { onMounted, reactive } from 'vue';
import { useRouter } from 'vue-router';
const router = useRouter();

const data: any = reactive({
	rankList: [],
});

const props = defineProps({
	Key: {
		type: String,
		default: '',
	},
	list: {
		type: Array,
		default: [],
	},
	Name: {
		type: String,
		default: '',
	},
	Bg: {
		type: String,
		default: '',
	},
	TitleColor: {
		type: String,
		default: '',
	},
});

// 点击去详情页
const clickCourse = (item: any) => {
	toDetails(router, item, '');
};

onMounted(() => getData());

const getData = () => {
	const rankList = props.list.map((v: any) => ({
		...v,
		Name: v.CourseName,
		Id: v.CourseId,
	}));
	data.rankList = rankList.slice(0, 3);
};

const toMore = () => {
	const routeData = router.resolve({
		path: '/rankCourseList',
	});
	window.open(routeData.href, '_blank');
};
</script>

<style scoped lang="scss">
.hot-course-wrapper-item {
	background-position: top center;
	background-repeat: no-repeat;
	background-size: 100%;
	width: 6.76rem;
	height: 6.56rem;
	background: linear-gradient(180deg, #cbe8ff 3%, #ffffff 100%);
	border-radius: 0.16rem;
	padding: 0.32rem 0.32rem 0.4rem 0.4rem;
	box-sizing: border-box;
	opacity: 1;
	margin-right: 0.2rem;
	.hot-title {
		margin-bottom: 24px;

		.rank-title-a {
			font-size: 0.36rem;
			font-family: PangMenZhengDao-Regular, PangMenZhengDao;
			font-weight: bold;
			color: #144db5;
			line-height: 0.36rem;
			letter-spacing: 1px;
		}

		.more-icon {
			width: 0.48rem;
			height: 0.48rem;
		}
	}

	.hot-course-wrapper-line {
		padding-bottom: 0.48rem;
		height: 1.32rem;

		&:last-of-type {
			padding-bottom: 0;
		}

		.course-cover {
			width: 2.4rem;
			height: 1.32rem;
			position: relative;
			margin-right: 0.32rem;
			.cover-icon {
				width: 100%;
				height: 100%;
				background-color: #eee;
			}

			.rank-icon {
				width: 24px;
				height: 24px;
				position: absolute;
				top: 0;
				left: 0;
			}
		}

		.right-info {
			padding: 0.08rem 0 0.08rem 0;
			box-sizing: border-box;
			justify-content: space-between;
			.course-title {
				font-size: 0.28rem;
				font-family: PingFang SC-Medium, PingFang SC;
				font-weight: 500;
				color: #333333;
			}
			.source-name {
				font-size: 0.24rem;
				font-family: PingFang SC-Regular, PingFang SC;
				font-weight: 400;
				color: #888888;
			}
		}
	}
}
</style>
