<template>
	<div
		class="special-courses-list-container"
		:class="[{ 'flex-center-start': columnsItem.SameLine }, columnsItem.Css, { 'item-card': !props.isCard }]"
	>
		<!-- 隐藏标题 -->
		<div v-if="!props.columnsItem.HideHeader" class="title">
			{{ props.columnsItem.Title }}
		</div>
		<!-- 课程列表 -->
		<!-- 课程系列 -->
		<div class="special-courses-list">
			<div
				class="course-series-item"
				v-for="(item, index) in data.specialCoursesList"
				:key="index"
				:class="{ 'item-card': props.isCard }"
			>
				<CourseList
					:courseTitle="item.Name"
					:courseSubTitle="item.SubTitle"
					:SpecialId="item.Id"
					:courseLists="item.Courses"
					:ChannelId="props.ChannelId"
				></CourseList>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { onMounted, reactive } from 'vue';
import CourseList from '@/components/courseList/MobileCourseList.vue';
import { PostSpecialsApi } from '@/http/api/course';
import { useRouter } from 'vue-router';
const router = useRouter();
const props = defineProps({
	columnsItem: {
		type: Object,
		default: {},
	},
	ChannelId: {
		type: String,
		default: '',
	},
	isCard: Boolean,
});
// console.log(props.columnsItem);
const data = reactive({
	specialList: [], //专题数组
	specialIdList: [], //专题id数组
	specialCoursesList: [], //专题课程列表
});
// 专题列表
onMounted(() => {
	data.specialList = JSON.parse(props.columnsItem.Data)['Items']; //专题数组
	data.specialList.forEach((listItem: any) => {
		if (listItem.Title == '猜你喜欢') {
			listItem.SpecialId = 'HotSale';
		}
		let obj = {
			Key: listItem.SpecialId + '',
			Value: listItem.Count + '',
		};
		data.specialIdList.push(obj);
	});
	data.specialIdList;
	//   获取专题列表
	getSpecialsList();
});

// 获取专题课程列表
const getSpecialsList = () => {
	PostSpecialsApi(data.specialIdList)
		.then((res: any) => {
			const specialCoursesList = res;
			// console.log(res);
			data.specialList.forEach((item) => {
				specialCoursesList.forEach((courseItem: any) => {
					if (item.SpecialId == courseItem.Id && item.SubTitle) {
						courseItem['SubTitle'] = item.SubTitle;
					}
				});
			});

			data.specialCoursesList = specialCoursesList;
		})
		.catch((err: any) => {
			console.log(err);
		});
};
</script>
<style scoped lang="scss">
.special-courses-list-container {
	margin-top: 0.3rem;

	.title {
		font-size: 0.32rem;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #000000;
		line-height: 0.32rem;
		margin-bottom: 0.42rem;
	}
	.special-courses-list {
		.course-series-item {
			padding: 0.32rem 0.32rem 0 0.32rem;
			margin-bottom: 0.3rem;
			background: #fff;
			border-radius: 0.16rem;
		}

		.item-card {
			padding: 0.2rem 0.2rem 0 0.2rem;
		}
	}
}
</style>
