<template>
	<div class="scroll-library-list">
		<div v-for="(item, index) in data.rankList" :key="index">
			<MobileCourseRankList
				:list="item.Value"
				:Key="item.Key"
				:Name="item.Name"
				:Bg="item.Bg"
				:TitleColor="item.TitleColor"
			></MobileCourseRankList>
		</div>
	</div>
</template>
<script setup lang="ts">
import { GetRankListMultiData } from '@/http/api/course';
import { onMounted, reactive } from 'vue';
const props = defineProps({
	params: {
		type: Array,
		default: [],
	},
});
const data: any = reactive({
	rankList: [],
});
onMounted(() => getData());

// 获取图片路径
const getImageUrl = (name: string) => {
	return new URL(`../../assets/images/rank/${name}`, import.meta.url).href;
};

const getData = () => {
	const TopTypes = props.params.map((i: any) => JSON.parse(i) && JSON.parse(i).RankingListId);
	GetRankListMultiData({ TopTypes, TopNum: 20 }).then((res: any) => {
		if (!res.TopCourses.length) return;

		res.TopCourses = res.TopCourses.map((v: any) => {
			const setList = [
				{
					Key: 'CourseList',
					TitleColor: '#144DB5',
					Bg: getImageUrl('rank4.png'),
				},
				{
					Key: 'NewCourseList',
					TitleColor: '#1C70AD',
					Bg: getImageUrl('rank5.png'),
				},
				{
					Key: 'CourseMonthlyList',
					TitleColor: '#144DB5',
					Bg: getImageUrl('rank6.png'),
				},
			];
			const index: any = setList.findIndex((i) => i.Key === v.Key);
			return { ...v, Bg: setList[index].Bg, TitleColor: setList[index].TitleColor };
		});
		data.rankList = res.TopCourses;
	});
};
</script>

<style lang="scss" scoped>
.scroll-library-list {
	width: 100%;
	white-space: nowrap;
	display: flex;
	overflow-x: auto;
	overflow-y: hidden;
	&::-webkit-scrollbar {
		display: none;
		height: 0;
	}
}
</style>
