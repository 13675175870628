import { MockMethod } from 'vite-plugin-mock'

import SortListData from './json/sortList.json'//分类
import SeriesDetailsData from './json/seriesDetails.json'//分类详情
import CourseListData from './json/courseList.json'//课程
import TitleData from './json/title.json'
export default [
  // 课程分类列表
  {
    url: `/mock/course/sort`,
    method: 'post',
    response: ({ body }) => {
      const { type } = body // 1生活 2工作
      const SortList = []
      SortListData.forEach((item) => {
        if (type == item.type) {
          item.List.map((itemList)=>{
            SortList.push(itemList)
          })
        }
      })

      return {
        StateCode: 0,
        Data: {
          SortList: SortList,
        },
        "msg": "成功"
      }
    }
  },
  // 课程系列列表
  {
    url: `/mock/course/series`,
    method: 'post',
    response: ({ body }) => {
      const CourseSeriesList = []
      const { TypeId } = body
      // TypeId 0 生活 1 工作
      const List = TitleData.map(item => {
        if (item.TypeId == TypeId) {
          item.SeriesList.forEach((item, index) => {
            const CourseSeriesObj = {
              Id: index,
              Title: '',
              List: []
            }
            CourseSeriesObj.Id = index,
              CourseSeriesObj.Title = Object.keys(item)[0],
              item[Object.keys(item)].forEach((titleItem) => {
                const SeriesListObj = {
                  SeriesName: '',
                  SeriesId: '',
                  Cover: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.1ppt.com%2Fuploads%2Fallimg%2F2104%2F1_210414101157_1.JPG&refer=http%3A%2F%2Fimg.1ppt.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653183620&t=4e8235582df356c0ab9185c4c034683f',
                  ViewersNumber: '1000',
                  SeriesIntroduction: '',
                  SeriesCover: '',
                  CourseID: '',
                }
                CourseListData.forEach((courseItem, courseIndex) => {
                  if (titleItem == courseItem.CourseSeries || titleItem == courseItem.CourseName) {
                    SeriesListObj.SeriesId = courseItem.SeriesID
                    SeriesListObj.SeriesName = titleItem
                    SeriesListObj.SeriesIntroduction = courseItem.CourseIntroduction

                    if (Object.keys(item) == '名人讲堂') {
                      SeriesListObj.CourseID = courseItem.CourseID
                      SeriesListObj.SeriesCover = 'series-cover-course' + courseItem.SeriesID + '.png'
                    } else {
                      SeriesListObj.SeriesCover = 'series-cover' + courseItem.SeriesID + '.png'
                    }

                  }
                })
                CourseSeriesObj.List.push(SeriesListObj)
              });
            CourseSeriesList.push(CourseSeriesObj)
          })
        }
      })
      return {
        StateCode: 0,
        Data: {
          CourseSeriesList: CourseSeriesList
        },
        Msg: ''
      }
    }
  },


  // 系列 - 课程列表··
  {
    url: `/mock/course`,
    method: 'post',
    response: ({ body }) => {
      const CourseList = []
      interface CourseDetailsInterface {
        SeriesID: String
        SeriesName: string;
        SeriesIntroduction: string;
        SeriesCover: string;
        Teacher: string;
        TeacherIntroduction: string;
        SeriesLabel: string;
        ViewersNumber: string;
        SeriesCredit: string;
      }
      let CourseDetails: CourseDetailsInterface = {
        SeriesID: '',
        SeriesName: '',
        SeriesIntroduction: '',
        SeriesCover: "",
        Teacher: '',
        TeacherIntroduction: '',
        SeriesLabel: '',
        ViewersNumber: '',
        SeriesCredit: ''
      }
      CourseListData.map(item => {
        if (body.SeriesID === item.SeriesID) {
          CourseList.push(item)
          return CourseList
        }
      })
      SeriesDetailsData.map(item => {
        if (body.SeriesID === item.SeriesID) {
          CourseDetails = item
          return CourseDetails
        }
      })
      return {
        StateCode: 0,
        Data: {
          SeriesID: CourseDetails.SeriesID,//系列id
          SeriesName: CourseDetails.SeriesName,//系列名称
          SeriesCover: CourseDetails.SeriesCover + CourseDetails.SeriesID + '.png',//系列图片
          SeriesLabel: CourseDetails.SeriesLabel.split("、"),//系列标签
          Teacher: CourseDetails.Teacher,//主讲人
          TeacherIntroduction: CourseDetails.TeacherIntroduction,//讲师简介
          SeriesIntroduction: CourseDetails.SeriesIntroduction,//系列介绍
          SeriesCredit: CourseDetails.SeriesCredit,//学分
          SeriesClassHours: CourseList.length,//课时数量
          CourseList: CourseList,//课程数组
          ViewersNumber: CourseDetails.ViewersNumber//观看人数
        },
        Msg: "成功"
      }
    }
  },
]