<template>
	<!-- 标题 -->
	<div class="list-title flex-center-between cursor-pointer">
		<div class="flex-center-center flex-1 pr_2">
			<div class="flex-center-center flex-1">
				<div class="flex-center-center" @click="clickTitle">
					<h2 v-if="props.title" class="list-title-text cursor-pointer">
						{{ props.title }}
					</h2>
					<h4 v-if="props.subTitle" class="course-list-sub-title cursor-pointer">
						{{ props.subTitle }}
					</h4>
				</div>

				<div class="flex-1 oneDot">
					<TitleTextLinkList :list="props.textLinkList"></TitleTextLinkList>
				</div>
			</div>
		</div>

		<router-link
			v-if="props.more"
			:to="{
				path: `/special${props.type}List`,
				query: {
					SpecialId: props.SpecialId,
					SpecialTitle: props.title,
				},
			}"
			class="list-title-more flex-center-end"
			target="_blank"
		>
			更多
			<img src="@/assets/images/more-icon.png" alt="" />
		</router-link>
	</div>
</template>

<script setup lang="ts">
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
const router = useRouter();
const props = defineProps({
	title: {
		type: String,
		default: '',
	},
	SpecialId: {
		type: String,
		default: '',
	},
	more: {
		type: Boolean,
		default: true,
	},
	subTitle: {
		type: String,
		default: '',
	},
	type: {
		type: String,
		default: 'Course',
	},
	textLinkList: {
		type: Array,
		value: [],
	},
});

// 点击更多左侧标题
const clickTitle = () => {
	if (!props.more) return;
	let routeData: any;
	routeData = router.resolve({
		path: `/special${props.type}List`,
		query: {
			SpecialId: props.SpecialId,
			SpecialTitle: props.title,
		},
	});
	window.open(routeData.href, '_blank');
};
</script>

<style lang="scss" scoped>
.list-title {
	margin-bottom: 25px;
	.list-title-text {
		font-size: 20px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #000000;
		line-height: 22px;
	}
	.course-list-sub-title {
		margin-left: 20px;
		font-size: 13px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #000000;
		line-height: 14px;
	}
	.list-title-more {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;
		line-height: 20px;
		cursor: pointer;
		img {
			width: 16px;
			height: auto;
			margin-left: 5px;
			cursor: pointer;
		}
	}
}
</style>
