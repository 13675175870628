<template>
	<div class="square-wrapper-btn" @mouseenter="data.squareListShow = true" @mouseleave="data.squareListShow = false">
		<div class="main-btn" :class="{ 'main-btn-radius': !data.squareListShow }">
			<img src="@/assets/images/header/square-icon.png" alt="" class="square-icon" />全国
		</div>
		<div class="square-area" v-show="data.squareListShow">
			<div class="square-area-list">
				<div class="square-item" v-for="(item, index) in data.list" :key="index" @click="toUrl(item.url)">{{ item.name }}</div>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { isEmpty } from 'lodash';
import { reactive, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
const store = useStore();
const data = reactive({
	squareListShow: false,

	list: [
		{ name: '北京京学', url: 'https://www.bjlearning.cn/' },
		{ name: '湖北荆楚学习广场', url: 'http://openstudy.hbou.edu.cn/' },
		{ name: '江西省终身学习网', url: 'http://jxzsxx.jxrtvu.com/portal' },
	],

	baseData: computed(() => store.state.baseData),
});

watch(
	() => data.baseData,
	() => init(),
);

const init = () => {
	if (!isEmpty(data.baseData)) {
		data.list = [];
		for (let key in data.baseData.Dictionarys.RecommendedLinks) {
			if (isEmpty(data.list.find((v) => v.name === key))) {
				data.list.push({ name: key, url: data.baseData.Dictionarys.RecommendedLinks[key] });
			}
		}
	}
};

onMounted(() => init());

const toUrl = (url: string) => {
	window.open(url);
};
</script>
<style lang="scss" scoped>
.square-wrapper-btn {
	position: relative;
	cursor: pointer;
}
.main-btn {
	width: 64px;
	height: 35px;
	border-radius: 5px 5px 0 0;
	border: 1px solid #dddddd;
	font-size: 13px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #13202b;
	display: flex;
	justify-content: center;
	align-items: center;

	.square-icon {
		width: 11px;
		height: 11px;
		margin-right: 3px;
	}
}

.main-btn-radius {
	border-radius: 5px;
}

.square-area {
	position: absolute;
	width: 310px;
	background: #ffffff;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.13);
	border: 1px solid #dddddd;
	border-radius: 0 5px 5px;
	&::before {
		content: '';
		position: absolute;
		top: -4px;
		width: 64px;
		height: 4px;
		background-color: #fff;
	}
	.square-area-list {
		display: flex;
		flex-wrap: wrap;
		padding: 15px 15px;
		justify-content: space-between;

		.square-item {
			font-size: 13px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #999999;
			cursor: pointer;
			padding: 8px 0;
			width: 50%;
			box-sizing: border-box;
			&:hover {
				font-size: 13px;
				font-weight: 400;
				color: #3c6bff;
			}
		}
	}
}
</style>
