<template>
	<div class="library-list-item" v-for="(item, index) in props.list" :key="index" @click="getBookUrl(item)">
		<div class="library-cover">
			<img class="library-cover-img" :src="item.Cover" alt="" />
		</div>
		<div class="library-list-item-describe">
			<h2 class="library-title oneDot">{{ item.Title }}</h2>
			<div class="author flex-center-between">
				<div class="oneDot">{{ item.Author }}</div>
				<div class="viewed-count flex-center-end oneDot">{{ item.ViewedCount }}人看过</div>
			</div>
			<div class="organ">
				<div>{{ item.SourceName }}</div>
				<!-- <div class="arrow-right flex-center-center">
                <img src="@/assets/images/arrow-right-white.png" alt="">
              </div> -->
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
const emits = defineEmits(['getBookUrl']);

const props = defineProps({
	list: Array,
	quoteType: Number,
});

const getBookUrl = (item: any) => {
	emits('getBookUrl', item);
};
</script>

<style scoped lang="scss">
.library-list-item {
	width: 31%;
	margin-bottom: 0.6rem;
	.library-cover {
		width: 100%;
		max-width: 2.28rem;
		height: 3rem;
		margin-bottom: 0.28rem;
		.library-cover-img {
			display: block;
			width: 100%;
			height: auto;
		}
	}
	.library-list-item-describe {
		max-width: 2.28rem;
		h2.library-title {
			font-size: 0.28rem;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 600;
			color: #333333;
			line-height: 0.28rem;
			margin-bottom: 0.2rem;
		}
		.author {
			font-size: 0.24rem;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #666666;
			line-height: 0.24rem;
			margin-bottom: 0.2rem;
		}
		.organ {
			font-size: 0.24rem;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #255efe;
			line-height: 0.24rem;
		}
	}
}
</style>
