<template>
	<div class="header">
		<div class="header-wrapper flex-center-between">
			<div class="flex-center-start header-left">
				<img class="header-logo" :src="props.logo" v-if="props.logo" />
				<a :title="logoTitle" @click="changeAccount" v-else
					><img class="header-logo" src="@/assets/images/header-logo.svg" alt=""
				/></a>
				<div class="module-btn">
					<SquareModule></SquareModule>
				</div>
				<div class="module-btn classify-module" v-if="props.isShowCourseModule">
					<ClassifyModule></ClassifyModule>
				</div>

				<div class="search-module"><SearchModule :type-id="props.typeId"></SearchModule></div>
				<!-- <img src="@/assets/images/header/active.png" alt="" class="active-icon" /> -->
			</div>
			<div class="flex-center-end header-right">
				<!-- 关怀模式 -->
				<CareModule></CareModule>
				<!-- 已登录 -->
				<div v-if="data.is_login" class="account-module"><MineAccount></MineAccount></div>
				<div class="login not-login flex-center-center" @click="data.notLoggedInAllVisible = !data.notLoggedInAllVisible" v-else>
					<span class="login-btn">登录</span><span class="register-btn">注册</span>
				</div>
			</div>
		</div>
		<LoginPopup :is-show="data.notLoggedInAllVisible" @close="data.notLoggedInAllVisible = false"></LoginPopup>
	</div>
</template>
<script setup lang="ts">
import { reactive, getCurrentInstance, onMounted, computed, ref } from 'vue';
import { mgr } from '@/assets/ts/oidcConfig';
import router from '@/router';
import { useStore } from 'vuex';
const store = useStore();
const { proxy } = getCurrentInstance() as any;
const props = defineProps({
	// 搜索类型默认为course
	typeId: {
		type: String,
		default: 'course',
	},

	// 是否显示课程分类模块
	isShowCourseModule: {
		type: Boolean,
		default: true,
	},
	logo: {
		type: String,
		default: '',
	},
});
const logoTitle = ref();
logoTitle.value = router.currentRoute.value.fullPath.indexOf('/home') >= 0 ? '' : '回到首页';
const data = reactive<any>({
	is_login: proxy.$cookies.get('loginStatus'), //登录状态  true登录  false未登录
	popoverShow: false,

	num: computed(() => store.state.logoClickNum), //点击次数
	timer0: '', //第一次点击的时间
	timer4: '', //第四次点击的时间
	chatShow: false,
	notLoggedInAllVisible: false,
});

onMounted(() => {
	if (!data.is_login) {
		data.popoverShow = true;
	}
});

// 登录
const login = () => {
	mgr.signinRedirect({ state: window.location.href });
};

//  连续4次点击显示问答模态框
const changeAccount = () => {
	const num = data.num + 1;
	store.commit('setLogoClickNum', num);

	if (data.num == 1) {
		data.timer0 = new Date().getTime() / 1000;
	}
	if (data.num == 5) {
		data.timer4 = new Date().getTime() / 1000;

		if (data.timer4 - data.timer0 < 5) {
			store.commit('setLogoClickNum', 0);
			return;
		} else {
			router.push({ path: '/' });
			store.commit('setLogoClickNum', 0);
		}
	}

	router.push({ path: '/' });
};
</script>
<style scoped lang="scss">
.header {
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
	background: #ffffff;
	box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.07);
	width: 100%;
	height: 75px;
	.header-top-bg {
		width: 100%;
		height: auto;
		display: block;
	}
	.header-wrapper {
		width: 100%;
		max-width: 1200px;
		height: 100%;
		margin: 0 auto;

		.header-left {
			flex: 1;
			height: 100%;
			margin-right: 10px;
			.header-logo {
				width: auto;
				height: 39px;
				cursor: pointer;
			}
			.module-btn {
				display: flex;
				align-items: center;
				height: 100%;
				margin-left: 24px;
			}

			.search-module {
				margin-left: 30px;
			}

			.active-icon {
				width: 243px;
				height: 45px;
				margin-left: 20px;
			}
		}

		.header-right {
			height: 100%;

			.login {
				font-size: 14px;
				cursor: pointer;
				position: relative;
				width: 101px;
				height: 35px;
				border-radius: 5px;
				border: 1px solid #dddddd;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #13202b;
				line-height: 18px;
				.register-btn {
					padding-left: 10px;
					&:hover {
						color: #342eff;
					}
				}
				.login-btn {
					position: relative;
					padding-right: 10px;
					&:hover {
						color: #342eff;
					}

					&::after {
						content: '|';
						color: #dddddd;
						position: absolute;
						right: 0;
					}
				}
			}
		}

		.account-module {
			display: flex;
			align-items: center;
			height: 100%;
		}
	}
}
</style>
