<template>
	<div class="nav-second-area">
		<div class="nav-second-list">
			<div class="nav-second-item" v-for="(item, index) in props.list" :key="index" @click="toDetailByClassify(item)">
				<div class="nav-second-left">
					<a :title="item.Name" :class="{ 'letter-space-8': item.Name.length <= 2 }">{{ item.Name }}</a>
				</div>
				<List
					v-if="item.childSortList && item.childSortList.length"
					:data="item.childSortList"
					:parent-id="item.Id"
					@to-detail-by-classify="toDetailByClassify"
				/>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import List from '../courseClassify/List.vue';

const router = useRouter();
const props: any = defineProps({
	currentChannelId: {
		type: String,
		default: '',
	},

	list: {
		type: Array,
		default: [],
	},
});

// 跳转分类筛选页
const toDetailByClassify = (item: any) => {
	if (!props.currentChannelId) return;
	let ParentId, ChildId, routeData: any;

	if (item.Level === 1) {
		ParentId = item.Index.split(',')[1];
		routeData = router.resolve({ path: '/screenCourseList', query: { ChannelId: props.currentChannelId, ParentId } });
	}

	if (item.Level === 2) {
		ChildId = item.Index.split(',')[2];
		ParentId = item.Index.split(',')[1];
		routeData = router.resolve({ path: '/screenCourseList', query: { ChannelId: props.currentChannelId, ParentId, ChildId } });
	}

	window.open(routeData.href, '_blank');
};
</script>

<style scoped lang="scss">
.letter-space-8 {
	letter-spacing: 8px;
}
.nav-second-area {
	display: flex;
	box-sizing: border-box;
	padding: 14.5px 20px 14.5px 0;

	.nav-second-list {
		display: flex;
		flex-direction: column;
	}
	.nav-second-item {
		display: flex;
		align-items: center;
		overflow: hidden;
		font-size: 13px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #000000;
		line-height: 13px;
		cursor: pointer;
		height: 34px;
		.nav-second-left {
			min-width: 85px;
			padding-left: 15px;
			box-sizing: border-box;
			height: 100%;
			display: flex;
			align-items: center;
			font-weight: 700;
		}
	}
}
</style>
