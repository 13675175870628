<template>
	<div class="text-link-list" :class="[{ 'flex-center-start': props.columnsItem.SameLine }, props.columnsItem.Css]">
		<!-- 隐藏标题 -->
		<div v-if="!props.columnsItem.HideHeader" class="title">
			{{ props.columnsItem.Title }}
		</div>
		<!-- 学校列表 -->
		<div
			v-for="(item, index) in JSON.parse(props.columnsItem.Data).Items"
			:key="index"
			class="text-link-list-item"
			:class="item.Css"
			@click="jumpRoute(item)"
		>
			<div class="text-link-list-item-content oneDot">
				<div class="text-link-list-item-title oneDot">{{ item.Title }}</div>
				<div v-if="item.Description2" class="text-link-list-item-describe2 oneDot">{{ item.Description2 }}</div>
			</div>
			<div v-if="item.Description" class="text-link-list-item-describe">
				{{ item.Description }}
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { getCurrentInstance } from 'vue';
import { GetLibraryExternalUrl } from '@/http/api/event';

const emit = defineEmits(['login']);
const { proxy } = getCurrentInstance() as any;
const is_login = proxy.$cookies.get('loginStatus');
const props = defineProps({
	columnsItem: {
		type: Object,
		default: {},
	},
	ChannelId: {
		type: String,
		default: '',
	},
	// 指定活动详情
	eventDetail: {
		type: Object,
		default: {},
	},
	quoteType: Number, // 0默认 1活动
});

// 跳转路径
const jumpRoute = (item: any) => {
	if (item.TargetType === 'None') return;

	const path = item.Target;
	if (!path || path === '无') return;
	const { EventRequireLogin, ExternalLinkRequireLogin, LibraryId } = props.eventDetail;
	// 外部链接和内部链接未登录进行强制登录
	if (!is_login) {
		if ((item.TargetType == 'Url' && ExternalLinkRequireLogin) || (item.TargetType == 'Local' && EventRequireLogin)) {
			emit('login');
			return;
		}
	}

	if (props.quoteType === 0 || (!LibraryId && (item.TargetType == 'Url' || item.TargetType == 'Local'))) {
		toUrl(path);
		return;
	}

	GetLibraryExternalUrl({ Url: encodeURIComponent(path), LibraryId }).then((res) => {
		toUrl(res);
	});
};

const toUrl = (url: any) => {
	const w = window.open('about:blank');
	w.location.href = url;
};
</script>
<style scoped lang="scss">
.text-link-list {
	width: 100%;
	margin-bottom: 50px;
	.title {
		font-size: 24px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 600;
		color: #000000;
		line-height: 26px;
		vertical-align: middle;
		margin-bottom: 30px;
	}
	.text-link-list-item {
		width: 100%;
		padding: 20px 0px;
		box-sizing: border-box;
		font-size: 16px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 400;
		color: #000000;
		line-height: 26px;
		cursor: pointer;
		.text-link-list-item-content {
			flex: 1;
			margin-right: 20px;
			.text-link-list-item-title {
				width: 100%;
				font-weight: 600;
			}
			.text-link-list-item-describe2 {
				width: 100%;
				font-size: 14px;
			}
		}
		.text-link-list-item-describe {
			font-size: 14px;
			min-width: 160px;
			color: #aaaaaa;
		}
	}
}
</style>
