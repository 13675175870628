<template>
	<template v-if="props.isVerticalScroll">
		<ul
			v-infinite-scroll="load"
			class="library-list flex-center-between"
			:infinite-scroll-immediate="props.infiniteScrollImmediate"
			:infinite-scroll-disabled="props.infiniteScrollDisabled"
		>
			<MobileBookItem :list="props.list" :quoteType="props.quoteType" @getBookUrl="getBookUrl"></MobileBookItem>
		</ul>
	</template>
	<template v-if="props.isHorizontalScroll">
		<div class="scroll-library-list">
			<MobileBookItemV2 :list="props.list" @getBookUrl="getBookUrl" :quoteType="props.quoteType"></MobileBookItemV2>
		</div>
	</template>
	<template v-else>
		<div class="library-list flex-start-between" v-if="props.showType === 0">
			<MobileBookItem :list="props.list" :quoteType="props.quoteType" @getBookUrl="getBookUrl"></MobileBookItem>
		</div>
	</template>
</template>

<script setup lang="ts">
import MobileBookItemV2 from './MobileBookItemV2.vue';
import MobileBookItem from './MobileBookItem.vue';
import { getBookUrlApi } from '@/http/api/book';
import { getCurrentInstance, ref } from 'vue';
import { isOurIosApp, isOurAndroidApp, isOurMiniProgram } from '@/utils/common';
import { adr_appIsLogin, ios_showCourseDetail, miniProgram_login } from '@/utils/appFn';
import wx from 'weixin-js-sdk';
import { store } from '@/store';
const { proxy } = getCurrentInstance() as any;
const is_login = proxy.$cookies.get('loginStatus');

const props = defineProps({
	list: Array,
	quoteType: Number,
	showType: {
		type: Number,
		default: 0,
	},
	isVerticalScroll: Boolean, //是否是垂直滚动加载的样式
	isHorizontalScroll: Boolean, //是否是水平滚动加载的样式
	infiniteScrollDisabled: Boolean,
	infiniteScrollImmediate: Boolean,
});
const emits = defineEmits(['login', 'load']);

// 获取书外部跳转链接
const getBookUrl = (item: any) => {
	// if (isOurIosApp()) {
	// 	ios_showCourseDetail(item.Id, item.CourseType);
	// 	return;
	// }
	// 未登录去登录
	if (!is_login) {
		if (isOurAndroidApp()) {
			adr_appIsLogin(is_login);
			return;
		}

		if (isOurMiniProgram() && !store.state.JtiStatus) {
			miniProgram_login();
			return;
		}
		emits('login');
		return;
	}

	const params = {
		bookId: item.Id,
	};
	getBookUrlApi(params)
		.then((res: any) => {
			console.log(res);
			// window.open(res);
			window.location.href = res + '?v=' + new Date().getTime();
		})
		.catch((err: any) => {
			console.log(err);
		});
};

const load = () => {
	emits('load');
};
</script>

<style scoped lang="scss">
.library-list {
	width: 100%;
	flex-wrap: wrap;
	&::after {
		content: '';
		width: 31%;
	}
}

.scroll-library-list {
	width: 100%;
	white-space: nowrap;
	display: flex;
	overflow-x: auto;
	&::-webkit-scrollbar {
		display: none;
		height: 0;
	}
}
</style>
