import { mgr } from '@/assets/ts/oidcConfig';
import { ElMessageBox } from 'element-plus';
import { showConfirmDialog } from 'vant';
import VueCookies from 'vue-cookies';
import type { Action } from 'element-plus';
import wx from 'weixin-js-sdk';
import jwtDecode from 'jwt-decode';
import { store } from '@/store';

// 判断移动端还是pc端
let flagRouter = JSON.parse(sessionStorage.getItem('flag') || '0');

export const ua: any = navigator.userAgent;

// 判断是否为app内嵌webview ios端
export const isOurIosApp = () => {
	return ua.indexOf('app_iOS/ouc_lifelong') !== -1;
};

// 判断是否为app内嵌webview android端
export const isOurAndroidApp = () => {
	return ua.indexOf('app_Android/ouc_lifelong') !== -1;
};

// 判断是否为小程序打开
export const isOurMiniProgram = () => {
	return ua.indexOf('miniProgram') > -1;
};

// 判断是否为Android环境
export const isAndroid = () => {
	return /(Android)/i.test(ua);
};

// 判断是否为ios环境
export const isIOS = () => {
	return !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
};

// 判断是否为微信浏览器
export const isWeixin = () => {
	return /micromessenger/.test(ua.toLowerCase());
};

export const urlencode = (str: string) => {
	str = (str + '').toString();
	return encodeURIComponent(str)
		.replace(/!/g, '%21')
		.replace(/'/g, '%27')
		.replace(/\(/g, '%28')
		.replace(/\)/g, '%29')
		.replace(/\*/g, '%2A')
		.replace(/%20/g, '+');
};

// 获取路由参数
export const getSearchParams = () => {
	const params: any = {};
	window.location.search &&
		window.location.search.match(/\??&?([^\?&]+)=([^\?&]+)/g)?.forEach((str: any) => {
			str = str.slice(1);
			var arrs = str.split('=');
			params[arrs[0]] = arrs[1];
		});
	return params;
};

// 判断是否在webview
export const openInWebView = () => {
	// 微信浏览器判断
	if (ua.match(/MicroMessenger/i) == 'micromessenger') {
		wx.miniProgram.getEnv((res: any) => {
			console.log('当前环境', res.miniProgram);
			return res.miniProgram;
		});

		return false;
	} else if (ua.match(/QQ/i) == 'qq') {
		// QQ浏览器判断
		return false;
	} else if (ua.match(/WeiBo/i) == 'weibo') {
		return false;
	} else {
		if (ua.match(/Android/i) != null) {
			return ua.match(/browser/i) == null;
		} else if (ua.match(/iPhone/i) != null) {
			return ua.match(/safari/i) == null;
		} else {
			return ua.match(/macintosh/i) == null && ua.match(/windows/i) == null;
		}
	}
};

export const sortList = (list: any, column: string = '', order = 'ASC') => {
	//正序
	if (order == 'ASC') {
		list = list.sort((a: any, b: any) => {
			return Number(a[column]) - Number(b[column]);
		});
	}
	//倒叙
	if (order == 'DESC') {
		list = list.sort((a: any, b: any) => {
			return Number(b[column]) - Number(a[column]);
		});
	}
};

export const getTime = (time: any) => {
	let seconds: any = Math.floor(time % 60);
	seconds = seconds < 10 ? '0' + seconds : seconds;
	let minutes: any = Math.floor(time / 60);
	minutes = minutes < 60 ? minutes : minutes - 60;
	minutes = minutes < 10 ? '0' + minutes : minutes;
	let hours: any = Math.floor(time / 3600);
	hours = hours < 10 ? '0' + hours : hours;
	return hours + ':' + minutes + ':' + seconds;
};

export const timeEvent = (e: any) => {
	var time = e;
	var len = time.split(':');
	if (len.length == 3) {
		var hour = time.split(':')[0];
		var min = time.split(':')[1];
		var sec = time.split(':')[2];
		return Number(hour * 3600) + Number(min * 60) + Number(sec);
	}
	if (len.length == 2) {
		var min = time.split(':')[0];
		var sec = time.split(':')[1];
		return Number(min * 60) + Number(sec);
	}
	if (len.length == 1) {
		var sec = time.split(':')[0];
		return Number(sec);
	}
};

export const sumCharacterCount = (str: string = '', a: string = '') => {
	let b = str.indexOf(a);
	var num = 0;
	while (b !== -1) {
		num++;
		b = str.indexOf(a, b + 1);
	}
	return num;
};

// 解析字符串中参数
export const handleStrParams = (str: any) => {
	const params: any = {};
	str.match(/\??&?([^\?&]+)=([^\?&]+)/g)?.forEach((str: any) => {
		str = str.slice(1);
		var arrs = str.split('=');
		params[arrs[0]] = arrs[1];
	});
	return params;
};

// 时间格式转为整型
export const convert1000 = (time: any) => {
	const [h, m, s] = time.split(':');
	let seconds = h * 3600 + m * 60 + parseInt(s);
	return seconds;
};

const emailRegExp = /^[\w\.-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)*\.[a-zA-Z]{2,}$/;
export const isEmail = (value: string) => emailRegExp.test(value);

export const validateEmail = (rule: any, value: any, callback: any) => {
	if (!emailRegExp.test(value) && value != '') {
		callback(new Error('邮箱格式错误'));
	} else {
		callback();
	}
};

export const validateEmailCode = (rule: any, value: any, callback: any) => {
	if (!/^[0-9]{6}$/.test(value) && value != '') {
		callback(new Error('验证码必须是6位数字'));
	} else {
		callback();
	}
};

export const plusXing = (str: any, frontLen: any, endLen: any) => {
	var len = str && str.length - frontLen - endLen;

	var xing = '';

	for (var i = 0; i < len; i++) {
		xing += '*';
	}

	return str && str.substring(0, frontLen) + xing + str.substring(str.length - endLen);
};

export const twoFixed = (value: any) => {
	value = Math.round(parseFloat(value) * 100) / 100;
	let s = value.toString().split('.');
	// console.log('s :>> ', s)
	// 只有整数
	if (s.length === 1) {
		value = value.toString() + '.00';
		return value;
	}
	// 有小数
	if (s.length > 1) {
		// 只有一位小数
		if (s[1].length < 2) {
			value = value.toString() + '0';
		}
		return value;
	}
};

// 验证税号
export const checkTax = (obj: any) => {
	return /^[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}$/.test(obj);
};

// 判断url是否包含参数
export const hasQueryParams = (url: any) => {
	const regex = /\?/; // 查询字符 "?" 的正则表达式
	return regex.test(url);
};

// 退出登录
export const logout = (mutual = 0) => {
	window.localStorage.clear();
	window.sessionStorage.clear();
	VueCookies.remove('loginStatus');
	VueCookies.remove('token');
	VueCookies.remove('refreshToken');
	VueCookies.remove('userInfo');

	let url = window.location.href;
	if (mutual) {
		url = hasQueryParams(url) ? `${url}&mutual=true` : `${url}?mutual=true`;
		url = encodeURIComponent(url);
	}
	const LogoutUrl = `${import.meta.env.VITE_AUTHORITY}Account/Logout?logoutId=${url}`;
	window.location.href = LogoutUrl;
};

// 处理登陆互斥下线提示
export const handleMutalLoginMsg = () => {
	const url = window.location.origin + window.location.pathname;
	if (flagRouter) {
		showConfirmDialog({
			title: '提示',
			message: '当前登录状态已失效，请重新登录。',
			confirmButtonText: '重新登录',
			cancelButtonText: '知道了',
		})
			.then(() => {
				mgr.signinRedirect({ state: url });
			})
			.catch(() => {});
		return;
	}
	ElMessageBox.confirm('当前登录状态已失效，请重新登录。', {
		closeOnClickModal: true,
		confirmButtonText: '重新登录',
		cancelButtonText: '知道了',
		type: 'warning',
	})
		.then(() => {
			mgr.signinRedirect({ state: url });
		})
		.catch((action: Action) => {
			console.log(action);
		});
};

export const handleTokenInfo = (access_token: any) => {
	const profile: any = jwtDecode(access_token);
	// 存储access_token;
	store.commit('saveToken', access_token);
	VueCookies.remove('token');
	VueCookies.set('token', access_token);
	// 存储refresh_token
	store.commit('saveRefreshToken', access_token);
	VueCookies.remove('refreshToken');
	VueCookies.set('refreshToken', access_token);
	// 存储登录状态
	store.commit('saveLoginStatus', true);
	VueCookies.remove('loginStatus');
	VueCookies.set('loginStatus', true);
	// 设置cookies的日期为1个月
	// VueCookies.config("1m");
	// 存储用户信息
	VueCookies.remove('userInfo');
	VueCookies.set('userInfo', profile);
	store.commit('saveUserInfo', profile);
};

export const fomatFloat = (num, n) => {
	var f = parseFloat(num);
	if (isNaN(f)) {
		return false;
	}
	f = Math.round(num * 10 ** n) / 10 ** n; // n 幂
	var s = f.toString();
	var rs = s.indexOf('.');
	//判定如果是整数，增加小数点再补0
	if (rs < 0) {
		rs = s.length;
		s += '.';
	}
	while (s.length <= rs + n) {
		s += '0';
	}
	return s;
};

export const arrayToTree = (array = [], Id = 'Id', ParentId = 'ParentId') => {
	// 找到第一级
	const rootItems = array.filter((item) => !item[ParentId]);

	// 遍历找子级
	const buildChildItems = (parentItem: any, array: any) => {
		const childItems = array.filter((item: any) => item[ParentId] === parentItem[Id]);
		if (childItems.length) {
			parentItem.List = childItems.map((childItem: any) => buildChildItems(childItem, array));
		}
		return parentItem;
	};

	return rootItems.map((item) => buildChildItems(item, array));
};

export const getWebsiteUrl = () => {
	const protocol = window.location.protocol;
	const host = window.location.host;
	return `${protocol}//${host}`;
};
