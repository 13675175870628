<template>
	<div class="notice-list-wrapper" :class="[{ 'flex-center-start': props.columnsItem.SameLine }, props.columnsItem.Css]">
		<MobileListTitle
			v-if="!props.columnsItem.HideHeader"
			@toMore="toMore"
			:more="true"
			:columnsItem="props.columnsItem"
		></MobileListTitle>
		<div class="notice-list">
			<div class="item" v-for="(item, index) in data.noticeList" :key="index" @click="toDetail(item)">
				<div class="info">
					<div class="title oneDot">{{ item.Title }}</div>
					<div class="date">{{ item.Date }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { getOrzNoticeList } from '@/http/api/organization';
import { split } from 'lodash';
import { reactive, onMounted } from 'vue';
import { useRouter } from 'vue-router';
const router = useRouter();
const props = defineProps({
	columnsItem: {
		type: Object,
		default: {},
	},
	ChannelId: {
		type: String,
		default: '',
	},
});

const data: any = reactive({
	noticeList: [],
});

onMounted(() => {
	getList();
});

const getList = () => {
	// console.log(props.columnsItem);
	const { TenantId, Count } = JSON.parse(props.columnsItem.Data);
	getOrzNoticeList({ TenantId, PageNum: 1, PageSize: Count }).then((res: any) => {
		res.PageListInfos.forEach((v: any) => {
			const time = split(v.CreateTime, ' ');
			v.Date = time[0];
		});
		data.noticeList = res.PageListInfos;
	});
};

const toDetail = ({ Id, IsUseLink, Link }: any) => {
	if (IsUseLink) {
		location.href = Link;
		return;
	}
	router.push({
		path: '/noticeDetail',
		query: { Id },
	});
};

const toMore = () => {
	const { TenantId } = JSON.parse(props.columnsItem.Data);
	router.push({
		path: '/noticeInfo',
		query: { TenantId },
	});
};
</script>
<style lang="scss" scoped>
.notice-list-wrapper {
	background-color: #fff;
	margin-top: 0.3rem;
	padding: 0.32rem;
	border-radius: 0.16rem;
	.item {
		border-bottom: 0.02rem solid #eee;
		&:first-of-type {
			padding-top: 0;
		}
		.info {
			padding-top: 0.32rem;
			padding-bottom: 0.16rem;
		}

		.title {
			font-size: 0.32rem;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #333333;
			line-height: 0.48rem;
		}

		.date {
			font-size: 0.28rem;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #999999;
			line-height: 0.44rem;
		}
	}
}
</style>
