<template>
	<!-- 提示框 -->
	<el-dialog
		draggable
		center
		v-model="data.notLoggedInAllVisible"
		modal-class="login-modal"
		:before-close="cancel"
		:width="iframeWidth + 'px'"
		:height="iframeHeight + 'px'"
	>
		<div class="background_white" :style="{ height: iframeHeight + 'px', 'border-radius': '9px' }">
			<iframe
				ref="iframe"
				:key="data.key"
				:width="iframeWidth + 'px'"
				:height="iframeHeight + 'px'"
				:src="data.currentSrc"
				referrerPolicy="no-referrer"
				frameborder="0"
				scrolling="no"
				style="border-radius: 9px"
			></iframe>
		</div>
	</el-dialog>
	<SetPassword :is-show="data.showSetPassword" @close="toggleSetPassword" title="设置密码" :isLoginPopup="true"></SetPassword>
</template>
<script lang="ts" setup>
import { mgr, oidcSettings } from '@/assets/ts/oidcConfig';
import { reactive, onMounted, watch, ref, getCurrentInstance } from 'vue';
import { GetStudentRemindInfoApi } from '@/http/api/student';
import { useStore } from 'vuex';
import jwtDecode from 'jwt-decode';
import { ElMessageBox } from 'element-plus';
import { logout } from '@/utils/common';
import { GetUserPwdStatus } from '@/http/api/passport';

const store = useStore();
const { proxy } = getCurrentInstance() as any;
const iframe = ref();
let newWindow: any = null;
const emits = defineEmits(['close']);
const iframeWidth = ref(780);
const iframeHeight = ref(480);

const props = defineProps({
	isShow: {
		type: Boolean,
		default: false,
	},
});
const data: any = reactive({
	notLoggedInAllVisible: false,
	currentSrc: `${import.meta.env.VITE_AUTHORITY}Account/NewLogin?ClientId=${oidcSettings.client_id}&ClientSecret=${
		oidcSettings.client_secret
	}`,
	key: '',
	showSetPassword: false,
});

watch(
	() => props.isShow,
	() => {
		data.notLoggedInAllVisible = props.isShow;
		if (props.isShow) {
			data.key = new Date().getTime() + Math.floor(Math.random() * 1000000); //获取当前时间戳
		}
	},
);

onMounted(() => {
	data.notLoggedInAllVisible = props.isShow;
	window.addEventListener('message', messageListener);
});

const messageListener = (event: any) => {
	if (event.data.type === 'Login') {
		//内部第三方登录重定向
		const url = window.location.origin + window.location.pathname;
		const scheme = event.data.content;
		if (scheme) {
			var extraQueryParams = { scheme };
			mgr.signinRedirect({ state: url, extraQueryParams });
		}
	}
	if (event.data.type === 'Open') {
		const iWidth = 750;
		const iHeight = 550;
		//获得窗口的垂直位置
		const iTop = (window.screen.availHeight - iHeight) / 2;
		//获得窗口的水平位置
		const iLeft = (window.screen.availWidth - iWidth) / 2;
		// QQ打开新窗体 newWindow 最好是一个全局变量收到Access消息后要关闭
		newWindow = window.open(
			event.data.content,
			'oauth2Login',
			'height=' +
				iHeight +
				',innerHeight=' +
				iHeight +
				',width=' +
				iWidth +
				',innerWidth=' +
				iWidth +
				',top=' +
				iTop +
				',left=' +
				iLeft +
				',status=no,toolbar=no,menubar=no,location=no,resizable=no,scrollbars=0,titlebar=no',
		);
	}
	if (event.data.type === 'Access') {
		// 返回的access token
		data.notLoggedInAllVisible = false;
		const { content } = event.data;
		handleLoginInfo(content);

		if (newWindow) {
			newWindow.close();
		}
	}
	if (event.data.type === 'resize') {
		iframeWidth.value = Number(event.data.width);
		iframeHeight.value = Number(event.data.height);
	}
};

// 处理登录返回的信息
const handleLoginInfo = (content: any) => {
	const { access_token, refresh_token } = content;
	const profile: any = jwtDecode(access_token);
	if (profile.UserType != 2) {
		console.log('后台管理');
		ElMessageBox.alert('只有学生可以登录终身教育平台，将为您退出登录并跳转至首页！', '提示', {
			confirmButtonText: '确定',
			center: true,
			callback: () => {
				logout();
			},
		});
		return;
	}
	// 存储access_token
	store.commit('saveToken', access_token);
	// 存储refresh_token
	store.commit('saveRefreshToken', refresh_token);
	// 存储登录状态
	store.commit('saveLoginStatus', true);
	// 存储用户信息
	store.commit('saveUserInfo', profile);

	// 获取学生提醒信息
	studentRemindInfo();

	getUserPwdStatus();
};

// 获取学生提醒信息
const studentRemindInfo = () => {
	GetStudentRemindInfoApi({})
		.then((res: any) => {
			// 存储用户提醒信息
			proxy.$cookies.remove('studentIsRemind');
			proxy.$cookies.set('studentIsRemind', res.IsRemind);
			// store.commit("studentIsRemind", res.IsRemind);
		})
		.catch((err: any) => {
			console.log(err);
		});
};

const cancel = () => {
	emits('close');
};

const toggleSetPassword = () => {
	data.showSetPassword = !data.showSetPassword;
};

const getUserPwdStatus = () => {
	GetUserPwdStatus().then((res) => {
		if (!res) {
			data.showSetPassword = true;
		} else {
			location.reload();
		}
	});
};
</script>

<style lang="scss">
// 提示框
.login-modal {
	position: fixed;
	top: 0;
	bottom: constant(safe-area-inset-bottom);
	bottom: env(safe-area-inset-bottom);
	left: 0;
	right: 0;
	z-index: 2002;
	.el-overlay-dialog {
		position: fixed;
		top: 0;
		bottom: constant(safe-area-inset-bottom);
		bottom: env(safe-area-inset-bottom);
		left: 0;
		right: 0;
		.el-dialog {
			padding: 0 !important;
			border-radius: 6px;
			padding: 0 20px;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			margin: 0 !important;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			.el-dialog__header {
				padding: 0;
			}
			.el-dialog__body {
				padding: 0px !important;
				box-sizing: border-box;
			}
		}
	}
}
</style>
