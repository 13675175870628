<template>
	<div class="password-wrapper">
		<div class="mobile-text">你的手机号：{{ mobile }}</div>
		<div class="mobile-tips">为了安全，我们会给您发送短信验证码</div>
		<div class="main-wrapper">
			<div class="input-wrapper">
				<InputCode @get-code-value="getCodeValue" :code="data.captchaCode"></InputCode>
				<div class="flex-center-end tips-area">
					<div v-if="data.showCodeError" class="flex-1 error-tips">验证码错误</div>
					<div class="send-code-button flex-center-end" @click="sendCode">
						{{ sms.btntext }}
					</div>
				</div>

				<div class="confirm-btn">
					<div class="btn" @click="confirmUserPwd" :class="{ 'btn-disabled': confirmDisabled }">下一步</div>
					<div class="return-text" @click="toSetPwd">上一步</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { SetResetCode, SetUserPwd } from '@/http/api/passport';
import { emitter } from '@/utils/EventBus';
import { plusXing } from '@/utils/common';
import { ElMessage } from 'element-plus';
import { throttle } from 'lodash';
import { computed, getCurrentInstance, reactive, nextTick } from 'vue';

const { proxy } = getCurrentInstance() as any;
const user_info: any = computed(() => proxy.$cookies.get('userInfo'));
let durationTimer: any = null;
const confirmDisabled = computed(() => data.captchaCode.length !== 6);
const codeTime = 60;
const mobile = plusXing(user_info.value.PhoneNumber, 3, 4);
const data = reactive({
	captchaCode: '',
	PhoneNumber: user_info.value.PhoneNumber,
	NewPassword: '', //上一步设置的密码
	showCodeError: false,
});

const props = defineProps({
	OldPassword: {
		type: String,
		default: '',
	},
});

data.NewPassword = props.OldPassword;

// 验证码计时器
const sms = reactive({
	disabled: false,
	total: codeTime,
	count: 0,
	btntext: '发送验证码',
	sendLoading: false,
});

// 计时器
const timerHandler = () => {
	sms.count = sms.total;
	handleCodeTimer();

	durationTimer = setInterval(() => {
		if (sms.count > 0 && sms.count <= sms.total) {
			handleCodeTimer();
		} else {
			destoryInterval();
		}
	}, 1000);
};

// 验证码相关销毁
const destoryInterval = () => {
	clearInterval(durationTimer);
	sms.disabled = false;
	sms.total = codeTime;
	sms.count = 0;
	sms.btntext = '发送验证码';
};

// 验证码计时执行操作
const handleCodeTimer = () => {
	sms.btntext = sms.count + 's后重新发送';
	sms.count--;
};

// 发送邮箱验证码;
const sendCode = throttle(
	() => {
		if (sms.disabled) return;
		SetResetCode({ PhoneNumber: data.PhoneNumber }).then(() => {
			sms.btntext = '正在发送';
			ElMessage.success('验证码发送成功');
			// 发送之前清空上次的验证码
			data.captchaCode = '';
			// 按钮禁用样式
			sms.disabled = true;
			// 倒计时
			timerHandler();
		});
	},
	10000,
	{ leading: true, trailing: false },
);

// 监听验证码值
const getCodeValue = (code: any) => {
	if (code.length === 6) {
		data.captchaCode = code;
	} else {
		data.showCodeError = false;
	}
};

// 返回设置密码
const toSetPwd = () => {
	emitter.emit('toSetPwd', data.NewPassword);
};

// 点击确认设置密码
const confirmUserPwd = () => {
	if (confirmDisabled.value) return;
	const params = {
		NewPassword: data.NewPassword,
		Code: data.captchaCode,
		PhoneNumber: data.PhoneNumber,
	};

	SetUserPwd(params)
		.then((res: any) => {
			if (res) {
				data.captchaCode = '';
				ElMessage({
					message: '密码设置成功',
					type: 'success',
				});
				emitter.emit('VerifyPwdSuccess');
			}
		})
		.catch((err) => {
			console.log(err);

			if (err.StateCode === 501) {
				data.showCodeError = true;
			}

			ElMessage({
				message: err.Message,
				type: 'error',
			});
		});
};
</script>

<style scoped lang="scss">
.password-wrapper {
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	margin: 30px auto;
	.mobile-tips {
		font-size: 14px;
		font-family: PingFang SC, PingFang SC;
		font-weight: 400;
		color: #999999;
		margin-top: 20px;
		margin-bottom: 40px;
	}

	.tips-area {
		margin-top: 20px;
		font-size: 14px;
		font-family: PingFang SC, PingFang SC;
		font-weight: 400;

		.error-tips {
			color: #f1291b;
		}
	}

	.input-wrapper {
		width: calc(64px * 4 + 60px);

		.confirm-btn {
			margin-bottom: 40px;
			margin-top: 62px;

			.btn {
				background: #3464f6;
				border-radius: 21px;
				height: 42px;
				color: #ffffff;
				justify-content: center;
				align-items: center;
				display: flex;
				cursor: pointer;
			}

			.btn-disabled {
				background-color: #e3e4eb;
			}

			.return-text {
				font-weight: 400;
				font-size: 14px;
				color: #3c6bff;
				text-align: center;
				cursor: pointer;
				margin-top: 24px;
			}
		}

		.send-code-button {
			font-size: 14px;
			font-family: PingFang SC, PingFang SC;
			font-weight: 400;
			color: #3c6bff;
			cursor: pointer;
		}
	}
}
</style>
