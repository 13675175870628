import { store } from '@/store';
// 我的课程点击
export const toStudyMyCourse = (router: any = {}, item: any = {}, isOrganization = false, terminal = 'pc') => {
	const CourseId = isOrganization ? item.CourseSummary.Id : item.CourseId;
	const BatchId = item.CourseSummary?.BatchId || '';
	const LastModuleCode = item.LastModuleCode || 'one';
	let params: any = { CourseId };
	let routeData;
	// // 微课
	if (item.CourseSummary.CourseType == 'Micro') {
		const commonParams = { CourseId, LastModuleCode };
		// 付费课程和非付费课程
		params = item.CourseSummary.Price * 1 > 0 ? { ...commonParams, BatchId } : commonParams;
		routeData = router.resolve({ name: 'CourseDetails', params });
	} else if (item.CourseSummary.CourseType == 'Moodle') {
		params = { CourseId, BatchId };
		// moodle
		// 跳转到学生空间
		const { CourseType, PaymentOrganization } = item.CourseSummary;
		if (PaymentOrganization === 'Open' && CourseType === 'Moodle') {
			if (terminal === 'mobile') {
				location.href = store.state.baseData?.Dictionarys?.AllInOne?.AllInOneStudentSpaceUrl;
				return;
			}
			window.open(store.state.baseData?.Dictionarys?.AllInOne?.AllInOneStudentSpaceUrl, '_blank');
		} else {
			routeData = router.resolve({ name: 'EducationCourseDetails', params });
			window.open(routeData.href, '_blank');
		}
		return;
	} else if (item.CourseSummary.CourseType == 'External') {
		// 外部
		routeData = router.resolve({ name: 'ExternalCourseDetails', params });
	} else if (item.CourseType == 'Live') {
		// 直播
		routeData = router.resolve({ name: 'LiveCourseDetails', params });
	}
	window.open(routeData.href, '_blank');
};

// 我的收藏，我的点赞
export const toStudyClickByDependentId = (router: any = {}, item: any = {}) => {
	let params: any = { CourseId: item.Dependent.DependentId };
	let routeData;
	// 微课
	if (item.CourseType == 'Micro') {
		if (item.LastModuleCode) {
			params = { ...params, LastModuleCode: item.LastModuleCode };
		}
		routeData = router.resolve({
			name: 'CourseDetails',
			params,
		});
	} else if (item.CourseType == 'Moodle') {
		// moodle
		routeData = router.resolve({
			name: 'EducationCourseDetails',
			params,
		});
	} else if (item.CourseType == 'External') {
		// 外部
		routeData = router.resolve({
			name: 'ExternalCourseDetails',
			params,
		});
	} else if (item.CourseType == 'Live') {
		// 外部
		routeData = router.resolve({
			name: 'LiveCourseDetails',
			params,
		});
	}

	console.log(routeData);
	window.open(routeData.href, '_blank');
};

// 我的好友的课程点击
export const toStudyOtherCourse = (router: any = {}, item: any) => {
	let params: any = { CourseId: item.CourseId };
	let routeData;
	// 微课
	if (item.CourseSummary.CourseType == 'Micro') {
		routeData = router.resolve({
			name: 'CourseDetails',
			params: {
				...params,
				LastModuleCode: item.LastModuleCode ? item.LastModuleCode : '',
			},
		});
	} else if (item.CourseSummary.CourseType == 'Moodle') {
		// moodle
		routeData = router.resolve({
			name: 'EducationCourseDetails',
			params,
		});
	} else if (item.CourseSummary.CourseType == 'External') {
		// 外部
		routeData = router.resolve({
			name: 'ExternalCourseDetails',
			params,
		});
	} else if (item.CourseType == 'Live') {
		// 外部
		routeData = router.resolve({
			name: 'LiveCourseDetails',
			params,
		});
	}

	window.open(routeData.href, '_blank');
};
