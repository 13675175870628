export const toDetails = (router: any = {}, item: any, ChannelId: any = '') => {
	let routeData: any;
	let params = { CourseId: item.Id };
	// 微课
	if (item.CourseType == 'Micro') {
		routeData = router.resolve({ name: 'CourseDetails', params: { ...params, ChannelId } });
	} else if (item.CourseType == 'Moodle') {
		// moodle
		routeData = router.resolve({ name: 'EducationCourseDetails', params });
	} else if (item.CourseType == 'External') {
		// 外部
		routeData = router.resolve({ name: 'ExternalCourseDetails', params });
	} else if (item.CourseType == 'Live') {
		// 直播
		routeData = router.resolve({ name: 'LiveCourseDetails', params });
	}
	window.open(routeData.href, '_blank');
};

export const toMobileDetails = (router: any = {}, item: any, ChannelId: any = '') => {
	let params = { CourseId: item.Id };
	// 微课
	if (item.CourseType == 'Micro') {
		router.push({ name: 'CourseDetails', params: { ...params, ChannelId } });
	} else if (item.CourseType == 'Moodle') {
		// moodle
		router.push({ name: 'EducationCourseDetails', params });
	} else if (item.CourseType == 'External') {
		// 外部
		router.push({ name: 'ExternalCourseDetails', params });
	} else if (item.CourseType == 'Live') {
		// 直播
		router.push({ name: 'LiveCourseDetails', params });
	}
};
