<template>
	<div class="input-box">
		<div class="code-item" :class="codeValue.length == 0 && inputFocus ? 'code-item-active' : ''">{{ codeValue[0] }}</div>
		<div class="code-item" :class="codeValue.length == 1 && inputFocus ? 'code-item-active' : ''">{{ codeValue[1] }}</div>
		<div class="code-item" :class="codeValue.length == 2 && inputFocus ? 'code-item-active' : ''">{{ codeValue[2] }}</div>
		<div class="code-item" :class="codeValue.length == 3 && inputFocus ? 'code-item-active' : ''">{{ codeValue[3] }}</div>
		<div class="code-item" :class="codeValue.length == 4 && inputFocus ? 'code-item-active' : ''">{{ codeValue[4] }}</div>
		<div class="code-item" :class="codeValue.length >= 5 && inputFocus ? 'code-item-active' : ''">{{ codeValue[5] }}</div>
		<input
			class="input-code"
			v-model="codeValue"
			:maxlength="6"
			type="tel"
			@blur="codeInputBlur"
			@focus="codeInputFocus"
			@input="codeInputChange"
		/>
	</div>
</template>

<script lang="ts" setup>
import { ref, Ref } from 'vue';
let codeValue: Ref<string> = ref('');
let inputFocus: Ref<boolean> = ref(false);
const emits = defineEmits(['getCodeValue']);

const props = defineProps({
	code: {
		type: String,
		default: '',
	},
});

codeValue.value = props.code;

// 验证码输入框input
const codeInputChange = () => {
	if (codeValue.value && codeValue.value.length >= 6) {
		// console.log(codeValue.value);
		emits('getCodeValue', codeValue.value);
	}
};
// 验证码输入框失去焦点
const codeInputBlur = () => {
	inputFocus.value = false;
};
// 验证码输入框获取到焦点
const codeInputFocus = () => {
	inputFocus.value = true;
};
</script>

<style lang="scss" scoped>
.input-box {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
	position: relative;
	box-sizing: border-box;

	.input-code {
		width: 100%;
		height: 44px;
		position: absolute;
		left: 0px;
		top: 0px;
		box-sizing: border-box;
		color: transparent;
		background-color: transparent;
		opacity: 0;
	}

	.code-item {
		outline: none;
		line-height: 44px;
		text-align: center;
		margin-right: 10px;
		width: 44px;
		height: 44px;
		background: #f3f4f9;
		border-radius: 8px;
		font-size: 32px;
		font-family: PingFang SC, PingFang SC;
		font-weight: 500;
		color: #333333;
		border: none;
		box-sizing: border-box;
		&:last-of-type {
			margin-right: 0;
		}
	}

	.code-item-active {
		border: 1px solid #3464f6;
	}
}
</style>
