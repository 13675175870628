<template>
	<div>
		<div class="library-list-container common-content">
			<div class="library-list flex-start-between">
				<div class="library-list-item flex-start-start" v-for="(item, index) in props.list" :key="index" @click="getBookUrl(item)">
					<div class="library-cover">
						<img class="library-cover-img" :src="item.Cover" alt="" />
					</div>
					<div class="library-list-item-describe flex-start-between">
						<div class="top-info width_100">
							<h2 class="library-title twoDot">{{ item.Title }}</h2>
							<div class="author flex-center-between">
								<div class="oneDot author-text">{{ item.Author }}</div>
								<div class="viewed-count flex-center-end">
									<img src="@/assets/images/mobile-view-icon.png" alt="" />{{ item.ViewedCount }}人
								</div>
							</div>
							<p class="describe threeDot">{{ item.Description }}</p>
						</div>
						<div class="organ flex-center-between">
							<div>{{ item.SourceName }}</div>
							<div class="arrow-right flex-center-center">
								<img src="@/assets/images/arrow-right-white.png" alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { getCurrentInstance } from 'vue';
import { getBookUrlApi } from '@/http/api/book';
const { proxy } = getCurrentInstance() as any;
const props = defineProps({
	list: Array,
});

// 判断是否登录
const is_login = proxy.$cookies.get('loginStatus');
const emits = defineEmits(['login']);

// 获取书外部跳转链接
const getBookUrl = (item: any) => {
	const { Id } = item;

	// 未登录去登录
	if (!is_login) {
		emits('login');
		return;
	}

	const params = {
		bookId: Id,
	};
	getBookUrlApi(params)
		.then((res: any) => {
			toUrl(res);
		})

		.catch((err: any) => {
			console.log(err);
		});
};

const toUrl = (url: any) => {
	const w = window.open('about:blank');
	w.location.href = url;
};
</script>

<style lang="scss" scoped>
@import './bookList.scss';
</style>
