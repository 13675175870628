<template>
	<div class="notice-list-area" :class="[{ 'flex-center-start': props.columnsItem.SameLine }, props.columnsItem.Css]">
		<ListTitle v-if="!props.columnsItem.HideHeader" :columns-item="props.columnsItem" :more="true" @to-more="toMore"></ListTitle>

		<div class="notice-list">
			<div class="flex-center-start item" v-for="(item, index) in data.noticeList" :key="index" @click="toDetail(item)">
				<div class="circle"></div>
				<!-- :class="{ 'is-active': item.IsRelease }" -->
				<div class="title oneDot">{{ item.Title }}</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { getOrzNoticeList } from '@/http/api/organization';
import { split } from 'lodash';
import { reactive, onMounted } from 'vue';
import { useRouter } from 'vue-router';
const router = useRouter();

const props = defineProps({
	columnsItem: {
		type: Object,
		default: {},
	},
	more: Boolean,
});

const data: any = reactive({
	noticeList: [],
});

onMounted(() => getList());

const getList = () => {
	const { TenantId, Count } = JSON.parse(props.columnsItem.Data);
	getOrzNoticeList({ TenantId, PageNum: 1, PageSize: Count }).then((res: any) => {
		res.PageListInfos.forEach((v: any) => {
			const time = split(v.CreateTime, ' ');
			const timeArray = split(time[0], '-');
			v.Year = timeArray[0];
			v.Month = timeArray[1];
			v.Date = timeArray[2];
		});
		data.noticeList = res.PageListInfos;
	});
};

const toMore = () => {
	const { TenantId } = JSON.parse(props.columnsItem.Data);
	const routeData = router.resolve({
		path: '/noticeInfo',
		query: { TenantId },
	});
	window.open(routeData.href, '_blank');
};

const toDetail = ({ Id, IsUseLink, Link }: any) => {
	const { TenantId } = JSON.parse(props.columnsItem.Data);
	if (IsUseLink) {
		window.open(Link, '_blank');
		return;
	}
	const routeData = router.resolve({
		path: '/noticeDetail',
		query: { Id, TenantId },
	});

	window.open(routeData.href, '_blank');
};
</script>
<style lang="scss" scoped>
.notice-list-area {
	.notice-list {
		background: #ffffff;
		box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
		padding: 20px 16px;
		min-width: 330px;
		box-sizing: border-box;
		.item {
			margin-bottom: 14px;
			&:last-of-type {
				margin-bottom: 0;
			}

			.circle {
				width: 4px;
				height: 4px;
				background: #3c6bff;
				margin-right: 12px;
			}

			.title {
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #333333;
				line-height: 22px;
				cursor: pointer;
				flex: 1;
			}

			.is-active {
				color: #3c6bff;
			}
		}
	}
}
</style>
