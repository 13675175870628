import request from '../request';
import qs from 'qs';
// 获取学生信息
export function GetStudentInfoApi(data: object) {
	return request({
		url: '/Student',
		method: 'get',
		data,
	});
}
// 保存学生信息
export function PutStudentInfoApi(data: object) {
	return request({
		url: '/Student',
		method: 'put',
		data,
	});
}
// 获取学生提醒信息
export function GetStudentRemindInfoApi(data: object) {
	return request({
		url: '/Student/RemindInfo',
		method: 'get',
		data,
	});
}
// 保存学生提醒信息
export function PutStudentRemindInfoApi(data: object) {
	return request({
		url: '/Student/RemindInfo',
		method: 'put',
		data,
	});
}

// 保存学生提醒信息
export function SetStudentRealInfo(data: object) {
	return request({
		url: '/Student/EditStudent',
		method: 'put',
		data,
	});
}

// 发送邮件绑定信息
export function SetSendBindEmailCode(data: object) {
	return request({
		url: '/Student/Setting/SendBindEmailCode',
		method: 'post',
		data,
	});
}

// 设置绑定邮箱
export function SetBindEmail(data: object) {
	return request({
		url: '/Student/Setting/BindEmail',
		method: 'post',
		data,
	});
}

// 获取随机昵称
export function GetRandomNick() {
	return request({
		url: '/Student/Random/Nickname',
		method: 'get',
	});
}

// 获取用户是否存在学分银行
export function GetStudentState() {
	return request({
		url: '/Student/PersonalInfo/State',
		method: 'get',
	});
}

// 获取学分账户
export function GetStudentAccount() {
	return request({
		url: '/Student/PersonalInfo',
		method: 'get',
	});
}

// 获取个人学习成果
export function GetStudentResults(data: any) {
	return request({
		url: '/Student/PersonalResults?' + qs.stringify(data, { arrayFormat: 'repeat' }),
		method: 'get',
	});
}

// 创建个人学习成果
export function SetStudentResults(data: any) {
	return request({
		url: '/Student/PersonalResults?CourseId=' + data.CourseId,
		method: 'post',
	});
}

// 创建个人账户
export function SetStudentAccount(data: any) {
	return request({
		url: '/Student/PersonalInfo',
		method: 'post',
		data,
	});
}
