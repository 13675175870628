import { list } from './chatLimitList';

const state = {
	token: '',
	refreshToken: '',
	userInfo: {},
	loginStatus: false,
	code: '',
	appId: '',
	courseDetail: {
		showRecentViewNumCondition: 100, //共同观看人数显示‘最近’的条件
		tabIndex: 0,
	},

	//触发问答弹窗logo点击次数
	logoClickNum: 0,
	baseData: {},
	baseDataStatus: false,
	myCourse: [],
	myCourseStatus: false,
	chatLimitCourseIdList: list,
	chatShow: false,
	currentCourseId: '', //当前点击微课详情页的课程id
	currentModuleId: '', //当前点击微课详情页的课程ModuleId
	passportUserInfo: {},
	passportStatus: false,
	JtiStatus: false, //小程序传过来的登录状态
	baizeUrl: 'https://le.ouchn.cn/Event/cd507def-90bd-5da6-4483-5eff4eb46bc0',
	ziyuanClassifySelectPath: [],
	ziyuanClassifySelectId: '',
	traceRecommendCourseParams: {}, //追踪推荐课程的参数
};

export { state };
