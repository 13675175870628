import request from '../request';
import qs from 'qs';
// ----------------------------------- mock数据开始 ----------------------

// 获取指定活动
export function GetEvent(data: object) {
	return request({
		url: '/Event/' + data.Id,
		method: 'get',
		data,
	});
}
// 获取指定活动详情信息
export function GetEventDetails(data: object) {
	return request({
		url: '/Event/' + data.Id + '/Details',
		method: 'get',
		data,
	});
}
// 获取活动环节列表
export function GetEventPartList(data: object) {
	return request({
		url: '/Event/' + data.EventId + '/Part',
		method: 'get',
		data,
	});
}
// 报名
export function PostSignup(data: object) {
	return request({
		url: '/Event/' + data.EventId + '/SignUp',
		method: 'post',
		data,
	});
}
// 获取用户报名信息集合
export function GetSignupMessage(data: object) {
	return request({
		url: '/Event/UserEvent',
		method: 'get',
		data,
	});
}
// 直播
export function PostLiveUrl(data: object) {
	return request({
		url: '/Event/' + data.ChannelId + '/DirectUrl',
		method: 'post',
		data,
	});
}
// 保存投稿活动
export function SaveEventContributeApi(data: object) {
	return request({
		url: '/Event/SaveEventContribute',
		method: 'post',
		data,
	});
}
// 指定活动报名信息
export function GetMyEventDetailsApi(data: object) {
	return request({
		url: '/Event/MyEvent?' + qs.stringify(data, { arrayFormat: 'repeat' }),
		method: 'get',
		data,
	});
}
// 获取指定环节详情
export function GetEventPartDetailsApi(data: object) {
	return request({
		url: '/Event/Part/' + data.PartId,
		method: 'get',
		data,
	});
}
// 活动预约
export function PostEventAppointmentApi(data: object) {
	return request({
		url: '/Event/' + data.EventId + '/Appointment',
		method: 'post',
		data,
	});
}
// 活动预约 - 发送消息提醒
export function PostEventAppointmentRemindApi(data: object) {
	return request({
		url: '/Event/SaveEventAppointment?EventId=' + data.EventId,
		method: 'post',
		data,
	});
}
// 活动预约 - 取消预约
export function DeleteEventAppointmentApi(data: object) {
	return request({
		url: '/Event/CancelEvent/' + data.EventId,
		method: 'delete',
		data,
	});
}

// 我的活动信息分页
export function GetMyEvent(data: object) {
	return request({
		url: '/Event/MyEvents',
		method: 'get',
		data,
	});
}

// 生成资源库外部跳转链接
export function GetLibraryExternalUrl(data: object) {
	return request({
		url: '/Event/Library/' + data.LibraryId + '/ExternalUrl?' + qs.stringify({ Url: data.Url }, { arrayFormat: 'repeat' }),
		method: 'get',
		data,
	});
}

// 保存投稿活动
export function SaveEventCustomContributionApi(data: object) {
	return request({
		url: '/Event/SaveEventCustomContribution',
		method: 'post',
		data,
	});
}
