<template>
	<div class="text-link-list" :class="[{ 'flex-center-start': columnsItem.SameLine }, columnsItem.Css]">
		<!-- 隐藏标题 -->
		<div v-if="!props.columnsItem.HideHeader" class="title">
			{{ props.columnsItem.Title }}
		</div>
		<!-- 学校列表 -->
		<div
			v-for="(item, index) in JSON.parse(props.columnsItem.Data).Items"
			:key="index"
			class="text-link-list-item"
			:class="item.Css"
			@click="jumpRoute(item)"
		>
			<div class="text-link-list-item-content oneDot">
				<div class="text-link-list-item-title oneDot">{{ item.Title }}</div>
				<div v-if="item.Description2" class="text-link-list-item-describe2 oneDot">{{ item.Description2 }}</div>
			</div>
			<div v-if="item.Description" class="text-link-list-item-describe">
				{{ item.Description }}
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { getCurrentInstance } from 'vue';
import { GetLibraryExternalUrl } from '@/http/api/event';

const emit = defineEmits(['login']);
const { proxy } = getCurrentInstance() as any;
const is_login = proxy.$cookies.get('loginStatus');
const props = defineProps({
	columnsItem: {
		type: Object,
		default: {},
	},
	ChannelId: {
		type: String,
		default: '',
	},
	// 指定活动详情
	eventDetail: {
		type: Object,
		default: {},
	},
	quoteType: Number, // 0默认 1活动
});
// 跳转路径
const jumpRoute = (item: any) => {
	if (item.TargetType === 'None') return;

	const path = item.Target;
	if (!path || path === '无') return;
	const { EventRequireLogin, ExternalLinkRequireLogin, LibraryId } = props.eventDetail;
	// 外部链接和内部链接未登录进行强制登录
	if (!is_login) {
		if ((item.TargetType == 'Url' && ExternalLinkRequireLogin) || (item.TargetType == 'Local' && EventRequireLogin)) {
			emit('login');
			return;
		}
	}

	if (props.quoteType === 0 || (!LibraryId && (item.TargetType == 'Url' || item.TargetType == 'Local'))) {
		window.location.href = path;
		return;
	}

	GetLibraryExternalUrl({ Url: encodeURIComponent(path), LibraryId }).then((res: any) => {
		window.location.href = res;
	});
};
</script>
<style scoped lang="scss">
.text-link-list {
	width: 100%;
	padding: 0.32rem;
	box-sizing: border-box;
	background-color: #ffffff;
	margin: 0.2rem 0;
	.title {
		font-size: 0.32rem;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 600;
		color: #000000;
		line-height: 0.32rem;
		vertical-align: middle;
		margin-bottom: 0.4rem;
	}
	.text-link-list-item {
		flex: 1;
		padding: 0.2rem 0;
		box-sizing: border-box;
		font-size: 0.3rem;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 400;
		color: #000000;
		line-height: 0.4rem;
		.text-link-list-item-content {
			flex: 1;
			margin-right: 0.1rem;
			.text-link-list-item-title {
				width: 100%;
				font-weight: 600;
			}
			.text-link-list-item-describe2 {
				width: 100%;
				font-size: 0.28rem;
			}
		}
		.text-link-list-item-describe {
			font-size: 0.28rem;
			min-width: 2.6rem;
			color: #aaaaaa;
		}
	}
}
</style>
