const shortUrl = (targetString: string) => {
	const splitArr = targetString.split('/');
	const courseDetailConfig = [
		{ name: 'courseDetails', type: 'Micro' },
		{ name: 'educationCourseDetails', type: 'Moodle' },
		{ name: 'externalCourseDetails', type: 'External' },
	];
	for (let key in courseDetailConfig) {
		const current = courseDetailConfig[key];
		if (splitArr.includes(current.name)) {
			const CourseType = current.type;
			return {
				Id: splitArr[2],
				CourseType,
			};
		}
	}
	return { Id: '', CourseType: '' };
};

export const getTargetParams = (targetString = '') => {
	const data = ['le.ouchn.cn', 'web.le.ouc-online.com.cn'];
	if (data.some((v) => targetString.includes(v))) {
		const splitUrlArr = targetString.split('//');
		const index = splitUrlArr[1].indexOf('/');
		targetString = splitUrlArr[1].substring(index);
	}
	return shortUrl(targetString);
};
