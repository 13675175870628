export const list = [
	'CAAA010000018082',
	'CAAA010000061843',
	'CAAA010000021056',
	'CAAA010000042154',
	'CAAA010000042244',
	'CAAA010000031152',
	'CAAA010000021166',
	'CAAA010000020325',
	'CAAA010000020320',
	'CAAA010000041464',
	'CAAA010000062166',
	'CAAA010000042606',
	'CAAA010000020624',
	'CAAA010000053069',
	'CAAA010000051449',
	'CAAA010000023839',
	'CAAA010000037731',
	'CAAA010000015919',
	'CAOA010000000301',
	'CAAA010000056230',
	'CAAA010000014418',
	'CAAA010000057199',
	'CAOA010000001507',
	'CAAA010000030783',
	'CAOA010000000897',
	'CAAA010000031063',
	'CAOA010000000909',
	'CAAA010000030838',
	'CAAA010000013907',
	'CAAA010000031053',
	'CAAA010000061904',
	'CAAA010000041364',
	'CAAA010000020131',
	'CAOA010000003799',
	'CAAA010000057180',
	'11751b7a-34bd-0187-2bf2-8a2470443991',
	'CAAA010000000085',
	'CAAA010000002447',
	'CAAA010000019818',
	'CAAA010000020193',
	'CAAA010000020218',
	'CAAA010000020244',
	'CAAA010000020293',
	'CAAA010000020596',
	'CAAA010000020606',
	'CAAA010000020644',
	'CAAA010000020647',
	'CAAA010000020659',
	'CAAA010000020663',
	'CAAA010000020683',
	'CAAA010000023848',
	'CAAA010000023856',
	'CAAA010000023906',
	'CAAA010000023908',
	'CAAA010000023911',
	'CAAA010000023913',
	'CAAA010000023917',
	'CAAA010000023930',
	'CAAA010000023933',
	'CAAA010000023941',
	'CAAA010000023981',
	'CAAA010000023982',
	'CAAA010000023987',
	'CAAA010000024001',
	'CAAA010000024054',
	'CAAA010000024064',
	'CAAA010000024111',
	'CAAA010000025839',
	'CAAA010000025869',
	'CAAA010000026136',
	'CAAA010000026201',
	'CAAA010000030891',
	'CAAA010000037290',
	'CAAA010000044645',
	'CAAA010000044653',
	'CAAA010000044710',
	'CAAA010000044722',
	'CAAA010000046077',
	'CAAA010000047357',
	'CAAA010000050800',
	'CAAA010000053097',
	'CAAA010000053549',
	'CAAA010000054708',
	'CAAA010000054751',
	'CAAA010000056308',
	'CAAA010000056391',
	'CAAA010000056876',
	'CAAA010000059635',
	'CAAA010000059871',
	'CAAA010000062260',
	'CAAA010000062618',
	'CAAA010000020788',
	'CAAA010000023988',
	'CAAA010000023853',
	'CAAA010000021006',
	'CAOA010000003736',
	'CAAA010000036950',
	'CAAA010000036973',
	'CAAA010000045140',
	'CAAA010000024089',
];
