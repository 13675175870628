<template>
	<!-- 块类型 - 内容及要求 -->
	<div v-if="props.columnsItem.ColumnType == 'BlockStructure'">
		<ListTitle :columns-item="props.columnsItem"></ListTitle>
		<!-- {{columnsItem}} -->
		<template v-for="(blockItem, blockIndex) in props.columnsItem.Structure.Rows" :key="blockIndex">
			<template v-if="blockItem.ClientDisplayMethod != 'Mobile'">
				<!-- 行 -->
				<div v-if="!blockItem.Hide" :class="blockItem.Css">
					<!-- {{blockItem}} -->
					<!-- 列 -->
					<div
						v-for="(blockColumnsItem, blockColumnsIndex) in blockItem.Columns"
						:key="blockColumnsIndex"
						:class="blockColumnsItem.Css"
						:style="backgroundStyle(blockColumnsItem)"
					>
						<!-- 隐藏列 -->
						<template v-if="!blockColumnsItem.Hide">
							<!-- 块结构 -->
							<BlockStructure :columns-item="blockColumnsItem"></BlockStructure>
							<!-- 图片链接列表开始 -->
							<div v-if="blockColumnsItem.ColumnType == 'ImageLinkList'">
								<PictureLinkList :columnsItem="blockColumnsItem" @login="data.notLoggedInVisible = true"></PictureLinkList>
							</div>
							<!-- 图片链接列表结束 -->
							<!-- 图片链接 -->
							<div v-if="blockColumnsItem.ColumnType == 'ImageLink'">
								<PictureLink :columnsItem="blockColumnsItem" margin-bottom="0"></PictureLink>
							</div>
							<!-- 文本链接列表开始、 -->
							<template v-if="blockColumnsItem.ColumnType == 'TextLinkList'">
								<TextLinkList :columnsItem="blockColumnsItem" @login="data.notLoggedInVisible = true"></TextLinkList>
							</template>
							<!-- 文本链接列表结束 -->
							<!-- 媒体对象 -->
							<div v-if="blockColumnsItem.ColumnType == 'MediaObject'">
								<MediaObjectList :columnsItem="blockColumnsItem" :more="false" :marginBottom="0"></MediaObjectList>
							</div>
							<!-- 文本列表开始 -->
							<!-- {{blockColumnsItem}} -->
							<template v-if="blockColumnsItem.ColumnType == 'TextList'">
								<TextList :columnsItem="blockColumnsItem"></TextList>
							</template>
							<!-- 文本列表结束 -->
							<!-- 预约活动 - 开始 -->
							<template v-if="blockColumnsItem.ColumnType == 'CurrEventAppointment'">
								<EventAppointment :columnsItem="blockColumnsItem"></EventAppointment>
							</template>
							<!-- 预约活动 - 结束 -->
							<!-- 媒体视频开始 -->
							<!-- {{blockColumnsItem}} -->
							<template v-if="blockColumnsItem.ColumnType == 'VideoObject'">
								<VideoObject :columnsItem="blockColumnsItem"></VideoObject>
							</template>
							<!-- 媒体视频结束 -->
							<!-- 保利威视频开始 -->
							<template v-if="blockColumnsItem.ColumnType == 'PolyvVideoObject'">
								<PolyvVideoObject :columnsItem="blockColumnsItem"></PolyvVideoObject>
							</template>
							<!-- 保利威视频结束 -->
							<!-- 公告列表 -->
							<template v-if="blockColumnsItem.ColumnType == 'OrganizationNotice'">
								<BlockNoticeList :columnsItem="blockColumnsItem" :more="true"></BlockNoticeList>
							</template>
						</template>
					</div>
				</div>
			</template>
		</template>
	</div>
</template>

<script setup lang="ts">
import VideoObject from '@/views/pc/event/components/VideoObject.vue';
import PictureLinkList from '@/views/pc/event/components/pictureLinkList.vue';
import EventAppointment from '@/views/pc/event/components/EventAppointment.vue';
import PolyvVideoObject from '@/views/pc/event/components/PolyvVideoObject.vue';
const props = defineProps({
	columnsItem: {
		type: Object,
		default: {},
	},
});

// 背景样式
const backgroundStyle = (item: any) => {
	if (item.Css.includes('image')) {
		return 'background-image:url(' + item.Css.split('image-')[1] + ')';
	} else if (item.Css.includes('background')) {
		return 'background:' + item.Css.split('-')[1];
	} else {
		return 'background:' + '';
	}
};
</script>
