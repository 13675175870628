<template>
  <div class="base-video">
    <video
    ref="videoContainer"
      class="video-item"
      :src="props.videoUrl"
      controls="controls"
      alt=""
      autoplay
    >
      您的浏览器不支持 HTML5 video 标签。
    </video>
    <img @click="playVideo" v-if="data.isShowPause" class="video-play" src="@/assets/images/video-stop-icon.png" alt="">
  </div>
</template>
<script setup lang="ts">
import { onMounted, reactive,ref } from "vue"
const videoContainer = ref(null)
const data = reactive({
  isShowPause:false,
})
const props =  defineProps({
  videoPoster:{
    type:String,
    default:''
  },
  videoUrl:{
    type:String,
    default:''
  }
})
onMounted(()=>{
  // 监听视频播放
    videoContainer.value.addEventListener("play", () => {
      console.log("video is playing");
      data.isShowPause = false;
      // this.openTimer();
    });
    // 监听视频暂停
    videoContainer.value.addEventListener("pause", () => {
      console.log("video is stop");
      data.isShowPause = true;
      // this.closeTimer();
    });
})
// 获取图片路径
const getImageUrl = (name: string) => {
  return new URL(`../../assets/images/${name}`, import.meta.url).href;
};

const playVideo = () =>{
  videoContainer.value.play()
}
</script>
<style scoped lang="scss">
.base-video {
  width: 100%;
  height: 100%;
  position: relative;
  .video-item {
    width: 100%;
    height: 100%;
  }
  .video-play{
    width:35px;
    height:auto;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%);
    cursor: pointer;
  }
}
</style>