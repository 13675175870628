import request from '../request';

// 获取导航服务
export function GetNavigationInfo(data: object) {
	return request({
		url: '/Navigation/' + data.Client + '/Find',
		method: 'get',
		data,
	});
}
