import request from '../request';
import qs from 'qs';
// ----------------------------------- mock数据开始 ----------------------
// 课程分类列表
export function sortListApi(data: object) {
	return request({
		url: '/course/sort',
		method: 'post',
		baseURL: '/mock',
		data,
	});
}
// 课程系列列表
export function courseSeriesListApi(data: object) {
	return request({
		url: '/course/series',
		method: 'post',
		baseURL: '/mock',
		data,
	});
}
// 每个系列的课程列表
export function courseListApi(data: object) {
	return request({
		url: '/course',
		method: 'post',
		baseURL: '/mock',
		data: data,
	});
}
// 课程详情
export function GetCourseDetailsApi(data: object) {
	return request({
		url: '/mock/course/courseDetails.json',
		// url: '../../../public/mock/course/courseDetails.json',
		method: 'get',
		baseURL: '',
		data: data,
	});
}
// 直播列表
export function GetEventListApi(data: object) {
	return request({
		url: '/mock/course/eventList.json',
		method: 'get',
		baseURL: '',
		data: data,
	});
}
// ---------------------------- mock数据结束 -------------------------
// 视频加密播放
export function videoParamApi(data: object) {
	return request({
		url: '/VOD/' + data.vid + '/Token?Weixin=false',
		method: 'get',
		// baseURL:'http://49.4.122.4:2103/api',
		// baseURL:'/api',
		data,
	});
}
// 获取全部课程
export function GetCourseApi(data: object) {
	return request({
		url: '/Course?' + data.Ids,
		method: 'get',
		data,
	});
}
// 获取指定课程
export function GetOneCourseApi(data: object) {
	return request({
		url: '/Course/' + data.CourseId,
		method: 'get',
		data,
	});
}
// 获取moodle课程详情
export function GetMoodleCourseDetailsApi(data: object) {
	return request({
		url: '/Course/' + data.CourseId + '/MoodleCourse/Details',
		method: 'get',
		data,
	});
}

// 获取多个专题下的课程
export function PostSpecialsApi(data: object) {
	return request({
		url: '/Course/BySpecials',
		method: 'post',
		data,
	});
}

// 获取某个专题下的课程
export function GetOneSpecialsApi(data: object) {
	return request({
		url: '/Course/' + data.SpecialId + '/Courses?Count=' + data.Count,
		method: 'get',
		data,
	});
}

// 获取分页显示课程信息
export function GetCoursePagingApi(data: object) {
	return request({
		url: '/Course/Paging?' + qs.stringify(data, { arrayFormat: 'repeat' }),
		method: 'get',
		data,
	});
}
// 微课详情页
export function GetMicroCourseDetailsApi(data: object) {
	return request({
		url: '/Course/' + data.CourseId + '/MicroCourse/Details',
		method: 'get',
		data,
	});
}
// 微课详情页
export function GetExternalUrlApi(data: object) {
	return request({
		url: '/Course/' + data.CourseId + '/ExternalUrl',
		method: 'get',
		data,
	});
}

// 记录课程专题点击情况
export function PostClickCourse(data: object) {
	return request({
		url: '/Course/' + data.SpecialId + '/' + data.CourseId + '/Click',
		method: 'post',
		data,
	});
}
// 获取课程在学用户列表
export function GetLearningUsersList(data: object) {
	return request({
		url: '/Course/' + data.CourseId + '/RecentlyLearningUsers',
		method: 'get',
		data,
	});
}
// 推荐课程
export function PostCourseRecommend(data: object) {
	return request({
		url: '/Course/' + data.CourseId + '/Recommend/' + data.TargetUserId,
		method: 'post',
		data,
	});
}
// 创建课程笔记
export function PostCourseNote(data: object) {
	return request({
		url: '/Course/' + data.CourseId + '/CourseNotes/Module/' + data.ModuleType + '/' + data.ModuleId,
		method: 'post',
		data: data,
	});
}
// 课程笔记列表
export function GetCourseNoteList(data: object) {
	return request({
		url: '/Course/' + data.CourseId + '/CourseNotes/Paging?' + qs.stringify(data, { arrayFormat: 'repeat' }),
		method: 'get',
		data,
	});
}
// 笔记列表
export function GetNoteList(data: object) {
	return request({
		url: '/Course/CourseNotes/Paging?' + qs.stringify(data, { arrayFormat: 'repeat' }),
		method: 'get',
		data,
	});
}
// 修改课程笔记
export function EditCourseNote(data: object) {
	return request({
		url: '/Course/CourseNotes',
		method: 'put',
		data,
	});
}
// 删除课程笔记
export function DeleteCourseNote(data: object) {
	return request({
		url: '/Course/CourseNotes/' + data.NoteId,
		method: 'delete',
		data,
	});
}
// 创建关注标签
export function CreateFollowApi(data: object) {
	return request({
		url: '/Follow/' + data.DependentId + '/' + data.DependentName + '/' + data.DependentType,
		method: 'post',
		data,
	});
}
// 删除关注标签
export function DeleteFollowApi(data: object) {
	return request({
		url: '/Follow/Remove/' + data.DependentId + '/' + data.DependentName + '/' + data.DependentType,
		method: 'delete',
		data,
	});
}
// 是否关注过
export function FindFollowApi(data: object) {
	return request({
		url: '/Follow/Find?' + qs.stringify(data, { arrayFormat: 'repeat' }),
		method: 'post',
		data,
	});
}
// 我的关注列表
export function GetFollowListApi(data: object) {
	return request({
		url: '/Follow/Paging?' + qs.stringify(data, { arrayFormat: 'repeat' }),
		method: 'get',
		data,
	});
}
// 关注总人数
export function GetFollowCountApi(data: object) {
	return request({
		url: '/Follow/Count/' + data.DependentId + '/' + data.DependentName + '/' + data.DependentType,
		method: 'get',
		data,
	});
}
// 当前课程所在分类列表
export function GetCategoryListApi(data: object) {
	return request({
		url: '/Course/FindCourseCategoryByCourseId/' + data.CourseId,
		method: 'get',
		data,
	});
}

// 付费课程 - 创建预订单
export function CreateBookingOrderApi(data: object) {
	return request({
		url: '/PaidCourse/' + data.BatchId + '/Order?' + qs.stringify(data, { arrayFormat: 'repeat' }),
		method: 'get',
		data,
	});
}
// 付费课程 - 获取支付方式
export function GetPaymentTypeApi(data: object) {
	return request({
		url: '/PaidCourse/PaymentType/' + data.OrganizationCode + '/Support',
		method: 'get',
		data,
	});
}
// 付费课程 - 获取指定订单信息
export function GetBookingOrderApi(data: object) {
	return request({
		url: '/PaidCourse/OrderForm/' + data.OrderFormId,
		method: 'get',
		data,
	});
}
// 付费课程 - 提交订单
export function SubmitOrderApi(data: object) {
	return request({
		url: '/PaidCourse/' + data.OrderFormId + '/' + data.PaymentType + '/PaymentUrl',
		method: 'get',
		data,
	});
}
// 付费课程 - H5 - 提交订单
export function SubmitOrderH5Api(data: object) {
	return request({
		url: '/PaidCourse/' + data.OrderFormId + '/' + data.PaymentType + '/H5Url',
		method: 'get',
		data,
	});
}
// 付费课程 - 获取支付签名
export function SubmitOrderSignApi(data: object) {
	return request({
		url: '/PaidCourse/' + data.OrderFormId + '/' + data.PaymentType + '/PaymentSign',
		method: 'get',
		data,
	});
}
// 付费课程 - 获取wxcode配置
export function GetWxCodeApi(data: object) {
	return request({
		url: '/PaidCourse/WeixinJsSdkUiPackage?' + qs.stringify(data, { arrayFormat: 'repeat' }),
		method: 'get',
		data,
	});
}
// 付费课程 - 获取openid
export function PostCodeApi(data: object) {
	return request({
		url: '/PaidCourse/' + data.Id + '/' + data.PeymentType + '/AccessToken?Code=' + data.Code,
		method: 'post',
		data,
	});
}
// 付费课程 - 生成公众号支付链接
export function SubmitOrderPublicApi(data: object) {
	return request({
		url: '/PaidCourse/' + data.OrderFormId + '/' + data.PaymentType + '/PublicPay?OpenId=' + data.OpenId,
		method: 'get',
		data,
	});
}
// 付费课程 - 获取指定机构微信的APPID
export function GetAppIdApi(data: object) {
	return request({
		url: '/PaidCourse/' + data.OrganizationCode + '/Weixin/AppId',
		method: 'get',
		data,
	});
}
// 付费课程 - 我的订单
export function GetOrderListApi(data: object) {
	return request({
		url: '/PaidCourse/MyOrderFormPaging?' + qs.stringify(data, { arrayFormat: 'repeat' }),
		method: 'get',
		data,
	});
}
// 付费课程 - 申请退款
export function PostRefundApi(data: object) {
	return request({
		url: '/PaidCourse/' + data.OrderFormId + '/ApplyOfDrawback?' + qs.stringify(data, { arrayFormat: 'repeat' }),
		method: 'post',
		data,
	});
}
// 付费课程 - 检查学习授权
export function CheckLicenceApi(data: object) {
	return request({
		url: '/PaidCourse/' + data.BatchId + '/CheckLicence',
		method: 'get',
		data,
	});
}
// 付费课程 - 获取订单支付状态
export function GetOrderPaymentStatusApi(data: object) {
	return request({
		url: '/PaidCourse/' + data.OrderFormId + '/SyncStatus',
		method: 'put',
		data,
	});
}
// 付费课程 - 取消订单
export function CancelOrderApi(data: object) {
	return request({
		url: '/PaidCourse/CancelOrder?' + qs.stringify(data, { arrayFormat: 'repeat' }),
		method: 'get',
		data,
	});
}
// 付费课程 - 我的付费课程列表
export function GetMyPaidCoursesApi(data: object) {
	return request({
		url: '/PaidCourse/MyCourses?' + qs.stringify(data, { arrayFormat: 'repeat' }),
		method: 'get',
		data,
	});
}
// 获取moodle付费课程详情
export function GetMoodlePaidCourseDetailsApi(data: object) {
	return request({
		url: '/PaidCourse/' + data.CourseId + '/MoodleCourse/Details',
		method: 'get',
		data,
	});
}
// 获取Micro付费课程详情
export function GetMicroPaidCourseDetailsApi(data: object) {
	return request({
		url: '/PaidCourse/' + data.CourseId + '/MicroCourse/Details',
		method: 'get',
		data,
	});
}
// 获取moodle付费课程跳转链接
export function GetPaidCourseExternalUrlApi(data: object) {
	return request({
		url: '/PaidCourse/' + data.CourseId + '/ExternalUrl',
		method: 'get',
		data,
	});
}
// 修改发票信息
export function EditOrderInvoiceInfoApi(data: object) {
	return request({
		url: '/PaidCourse/' + data.OrderFormId + '/EditOrderInvoiceInfo',
		method: 'put',
		data,
	});
}
// 修改订单信息
export function EditOrderFormApi(data: object) {
	return request({
		url: '/PaidCourse/OrderForm',
		method: 'put',
		data,
	});
}

// 直播课详情页
export function GetLiveCourseDetailsApi(data: object) {
	return request({
		url: '/Course/' + data.CourseId + '/CourseLiveSection/Details',
		method: 'get',
		data,
	});
}

// 进入直播
export function ToLiveRoom(data: Object) {
	return request({
		url: '/Course/' + data.CourseLiveSectionId + '/EntentCourseLiveSection',
		method: 'get',
		data,
	});
}

// 获取排行榜数据
export function GetRankListMultiData(data: Object) {
	return request({
		url: '/TopHit/Multi?' + qs.stringify(data, { arrayFormat: 'repeat' }),
		method: 'get',
		data,
	});
}

// 获取某个专题下的课程(分页)
export function GetSpecialListById(data: object) {
	return request({
		url: '/Course/' + data.SpecialId + '/Paging?' + qs.stringify(data, { arrayFormat: 'repeat' }),
		method: 'get',
		data,
	});
}

// 获取ai课程摘要
export function GetCourseAiSummary(data: object) {
	return request({
		url: '/Course/AI/' + data.MicroCode,
		method: 'get',
		data,
	});
}

// 获取课程完成进度
export function GetCourseProgress(data: object) {
	return request({
		url: '/Completion/EndCourseMoudle/' + data.CourseId,
		method: 'get',
		data,
	});
}

// 创建推荐课程追踪
export function SetBrowerTrace(data: object) {
	return request({
		url: '/Browse/Trace',
		method: 'post',
		data,
	});
}

// 获取推荐课程
export function getRecommendCourse(data: object) {
	return request({
		url: '/Course/Recommendation?' + qs.stringify(data, { arrayFormat: 'repeat' }),
		method: 'get',
	});
}
