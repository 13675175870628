<template>
  <div
    class="mobile-text-link-list"
    :class="[{ 'flex-center-start': columnsItem.SameLine }]"
  >
    <!-- 隐藏标题 -->
    <div v-if="!props.columnsItem.HideHeader" class="title">
      {{ props.columnsItem.Title }}
    </div>
    <!-- 学校列表 -->
    <div class="text-link-list" :class="columnsItem.Css">
      <div
        v-for="(item, index) in JSON.parse(props.columnsItem.Data).Items"
        :key="index"
        class="text-link-list-item"
      >
        {{ item.Content }}
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
const props = defineProps({
  columnsItem: {
    type: Object,
    default: {},
  },
  ChannelId: {
    type: String,
    default: "",
  },
});
// console.log(props.columnsItem);
const data = reactive({});
</script>
<style scoped lang="scss">
.mobile-text-link-list {
  flex: 1;
  width: 100%;
  padding: 0.32rem;
  box-sizing: border-box;
  background-color: #ffffff;
  margin-top: 0.2rem;
  .title {
    min-width: 1rem;
    font-size: 0.32rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 0.32rem;
    margin-right: 0.24rem;
    margin-bottom: 0.24rem;
    vertical-align: middle;
  }
  .text-link-list {
    width: 100%;
    .text-link-list-item {
      width: 100%;
      min-width: 2rem;
      font-size: 0.3rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #9094a6;
      line-height: 0.32rem;
      padding: 0.2rem 0;
      box-sizing: border-box;
    }
  }
}
</style>