import VueCookies from 'vue-cookies';
const mutations = {
	// token
	saveToken(state: any, payload: any) {
		state.token = payload;
		// 设置cookies的日期为1个月
		VueCookies.config('15d');
		VueCookies.remove('token');
		VueCookies.set('token', payload);
	},
	// refreshToken
	saveRefreshToken(state: any, payload: any) {
		state.refreshToken = payload;
		// 设置cookies的日期为1个月
		VueCookies.config('15d');
		VueCookies.remove('refreshToken');
		VueCookies.set('refreshToken', payload);
	},
	// 用户信息
	saveUserInfo(state: any, payload: any) {
		state.userInfo = payload;
		VueCookies.remove('userInfo');
		VueCookies.set('userInfo', payload);
	},
	// 登录状态
	saveLoginStatus(state: any, payload: any) {
		state.loginStatus = payload;
		VueCookies.remove('loginStatus');
		VueCookies.set('loginStatus', payload);
	},
	// code
	saveCode(state: any, payload: any) {
		state.code = payload;
		VueCookies.remove('code');
		VueCookies.set('code', payload);
	},
	// appId
	saveAppId(state: any, payload: any) {
		state.appId = payload;
		VueCookies.remove('appId');
		VueCookies.set('appId', payload);
	},

	// baseData
	getBaseData(state: any, payload: any) {
		state.baseData = payload;
		state.baseDataStatus = true;
	},

	getPassportUserInfo(state: any, payload: any) {
		state.passportUserInfo = payload;
		state.passportStatus = true;
	},

	// logoClickNum
	setLogoClickNum(state: any, payload: any) {
		state.logoClickNum = payload;
	},

	getMyCourse(state: any, payload: any) {
		state.myCourse = payload;
		state.myCourseStatus = true;
	},
};
export { mutations };
