<template>
	<div class="hot-course-wrapper-item" :style="{ backgroundImage: 'url(' + props.Bg + ')' }">
		<div class="hot-title flex-center-between" @click="toMore">
			<div class="rank-title-a" :style="{ color: props.TitleColor }">{{ props.Name }} <span>TOP20</span></div>
			<img src="@/assets/images/rank/more-icon.png" alt="" class="more-icon" />
		</div>
		<el-carousel
			:interval="5000"
			:autoplay="false"
			height="490px"
			indicator-position="none"
			v-if="data.rankList.length"
			class="rank-carousel"
			trigger="click"
		>
			<el-carousel-item v-for="(item, index) in data.rankList" :key="index">
				<div
					class="flex-center-start hot-course-wrapper-line"
					v-for="(pageItem, pageIndex) in item"
					:key="pageIndex"
					@click="clickCourse(pageItem)"
				>
					<div class="course-cover">
						<img :src="pageItem.Cover" alt="" class="cover-icon" />
						<img src="@/assets/images/rank/rank-icon1.png" alt="" class="rank-icon" v-if="index === 0 && pageIndex === 0" />
						<img src="@/assets/images/rank/rank-icon2.png" alt="" class="rank-icon" v-if="index === 0 && pageIndex === 1" />
						<img src="@/assets/images/rank/rank-icon3.png" alt="" class="rank-icon" v-if="index === 0 && pageIndex === 2" />
						<img src="@/assets/images/rank/rank-icon4.png" alt="" class="rank-icon" v-if="index === 0 && pageIndex === 3" />
						<img src="@/assets/images/rank/rank-icon5.png" alt="" class="rank-icon" v-if="index === 0 && pageIndex === 4" />
					</div>
					<div class="flex-column-1 height_1 right-info">
						<div class="course-title twoDot">{{ pageItem.Name }}</div>
						<div class="source-name">{{ pageItem.SourceName }}</div>
					</div>
				</div>
			</el-carousel-item>
		</el-carousel>
	</div>
</template>
<script setup lang="ts">
import { toDetails } from '@/utils/event/courseList';
import { onMounted, reactive } from 'vue';
import { chunk } from 'lodash';
import { useRouter } from 'vue-router';
const router = useRouter();

const data: any = reactive({
	rankList: [],
	RankingListId: '',
});

const props = defineProps({
	list: {
		type: Array,
		default: [],
	},
	Name: {
		type: String,
		default: '',
	},
	Bg: {
		type: String,
		default: '',
	},
	TitleColor: {
		type: String,
		default: '',
	},
});

// 点击去详情页
const clickCourse = (item: any) => {
	toDetails(router, item, '');
};

onMounted(() => {
	getData();
});

const getData = () => {
	const rankList = props.list.map((v: any) => ({
		...v,
		Name: v.CourseName,
		Id: v.CourseId,
	}));
	data.rankList = chunk(rankList, 5);
};

const toMore = () => {
	const routeData = router.resolve({
		path: '/rankCourseList',
	});
	window.open(routeData.href, '_blank');
};
</script>
<style scoped lang="scss">
.hot-course-wrapper-item {
	background-position: top center;
	background-repeat: no-repeat;
	background-size: 100%;
	width: 380px;
	height: 566px;
	padding: 20px;
	box-sizing: border-box;
	.hot-title {
		margin-bottom: 24px;
		cursor: pointer;

		.rank-title-a {
			font-size: 22px;
			font-family: PangMenZhengDao-Regular, PangMenZhengDao;
			font-weight: 400;
			color: #144db5;
			line-height: 22px;
			letter-spacing: 2px;
		}

		.more-icon {
			width: 16px;
			height: 16px;
		}
	}

	.rank-carousel {
		.hot-course-wrapper-line {
			padding-bottom: 16px;
			height: 84px;
			cursor: pointer;

			.course-cover {
				width: 140px;
				height: 84px;
				position: relative;
				margin-right: 16px;
				.cover-icon {
					width: 100%;
					height: 100%;
					background-color: #eee;
				}

				.rank-icon {
					width: 24px;
					height: 24px;
					position: absolute;
					top: 0;
					left: 0;
				}
			}

			.right-info {
				padding: 2px 0 6px 0;
				box-sizing: border-box;
				justify-content: space-between;
				.source-name {
					color: #757575;
				}
			}
		}
	}
}
</style>
