<template>
	<el-drawer v-model="drawer" :direction="direction" :with-header="false" modal-class="my-chat-el-drawer__body" @close="hide">
		<template #default>
			<div class="chat-container">
				<div class="flex-center-start title-area">
					<div class="flex-1 flex-center-start">
						<img src="@/assets/images/chat/title-icon.png" alt="" class="title-icon" />
						<h4>智能小泽学习助手</h4>
					</div>
					<img src="@/assets/images/close-icon.png" alt="" class="close-icon" @click="hide" />
				</div>
				<div class="content-chat-box flex-1" ref="chatWindow">
					<div ref="chatWindowContent">
						<div class="date">{{ data.currentDate }}</div>
						<LeftItem :type="data.msgList[0].type" :content="data.msgList[0].content"></LeftItem>
						<div class="guide-area" v-if="data.guideShow">
							<div class="guide-title">猜你想问</div>
							<div class="guide-list">
								<template v-for="(item, index) in data.aslist" :key="index">
									<div class="item cursor-pointer" v-if="item.q" @click="ask(item.q)">{{ item.q }}</div>
								</template>
							</div>
						</div>

						<div class="list" id="list" ref="list">
							<ul>
								<template v-for="(item, index) in data.msgList" :key="index">
									<li v-if="index > 0">
										<RightItem :type="item.type" :content="item.content" v-if="item.me"></RightItem>
										<LeftItem :type="item.type" :content="item.content" v-else></LeftItem>
									</li>
								</template>
							</ul>
						</div>
					</div>
				</div>
				<div class="bottom-area">
					<div class="operation">
						<img src="@/assets/images/chat/face.png" alt="" class="operation-icon" />
						<img src="@/assets/images/chat/picture.png" alt="" class="operation-icon" />
						<img src="@/assets/images/chat/voice.png" alt="" class="operation-icon" />
					</div>
					<el-input v-model="data.input" placeholder="请输入您的问题" type="textarea" class="my-input" />
					<div class="send-btn" @click="send">发送</div>
					<a href=""></a>
				</div>
			</div>
		</template>
	</el-drawer>
</template>

<script lang="ts" setup>
import { trim, throttle, isEmpty } from 'lodash';
import { ref, onMounted, watch, nextTick } from 'vue';
import { reactive } from 'vue';
import moment from 'moment';
import elementResizeDetectorMaker from 'element-resize-detector';

const drawer = ref(false);
const props = defineProps({ isShow: Boolean });
const direction = ref('rtl');
const emit = defineEmits(['hide']);
const chatWindow = ref();
const chatWindowContent = ref();

watch(
	() => props.isShow,
	() => {
		drawer.value = props.isShow;
		if (props.isShow) {
			nextTick(watchSize);
		}
	},
);
const data = reactive<any>({
	aslist: [
		{
			q: '',
			a: [
				'嗨，大家好啊！我是终身教育平台的IP形象白泽，一个超级可爱的在线学习小助手！我可以帮助你制定个性化的学习计划、推荐适合你的学习内容、解答你的疑惑、跟踪你的学习进度，让你的学习更高效、更有趣！如果你有任何问题或需要帮助，就来找我吧！',
			],
		},
		{
			q: '我是一个财务，请推荐一门excel相关的课程供我学习。',
			a: [
				'如果您想学习Excel，那么《Excel在财务中的应用》课程是一个不错的选择。该课程涵盖了多个主题，从直线法下计提固定资产折旧到计算投资项目的净现值，内容丰富且实用。通过学习这门课程，您将掌握如何使用Excel来处理财务数据、编制利润表和资产负债表、进行盈利分析等重要技能。此外，课程还包括短期偿债能力比率分析、长期偿债能力分析、营运能力分析等内容，这些都是财务专业人士需要掌握的关键知识点。',
				'该课程的教学方法灵活多样，包括讲解、案例分析、实操演示等，能够帮助学员更好地理解和掌握所学知识。',
				'快来点击<a href="https://le.ouchn.cn/courseDetails/CAAA010000021272" target="_blank" style="color:#0947D5;">《Excel在财务中的应用》</a>进行学习吧！',
			],
		},
		{
			q: '我想学习编程，请问有合适的语言和课程供我学习么？',
			a: [
				'当然有！编程语言的选择应该根据你的兴趣、目标和经验水平来决定。Python是一种易于学习的高级编程语言，适合初学者入门。它具有简洁的语法和大量的库和框架，可以用于Web开发、数据科学、人工智能等领域。',
				'如果你对Python编程感兴趣，那么终身教育平台的《Python程序设计》课程是一个非常好的选择。该课程涵盖了Python基础语法、数据类型、函数、类和对象等方面的内容，并通过实战项目帮助学员将所学知识应用到实际工作中。',
				'课程设置非常丰富，包括Jupyter Notebook开发环境使用练习、Python版本信息获取、命令对扩展包进行管理等实用内容。通过学习这门课程，你将不仅掌握Python编程的基础知识和技能，还能了解Python在数据分析、互联网等领域的应用，为你未来的职业发展打下坚实的基础。',
				'快来点击<a href="https://le.ouchn.cn/courseDetails/CAAA010000018082" target="_blank" style="color:#0947D5;">《Python程序设计》</a>进行学习吧！',
			],
		},
		{
			q: '科技创新思维是什么？应该怎么养成？请推荐一门相关课程。',
			a: [
				'科技创新思维是指通过创造性的思考和行动，发现并解决现实问题的一种能力。它包括了对问题的敏感度、对未来的洞察力、对变化的适应性、对风险的承担能力和对创新的热情等方面。',
				'要养成科技创新思维，可以从多角度思考问题、学会提问、培养好奇心、接受失败、多与人交流、不断学习几方面入手。只要我们愿意付出努力，一定能够逐渐掌握这种思维方式。',
				'如果你想摆脱惯性思维，提高自己的创新能力，那么终身教育平台的《科技创新思维的养成》课程是一个不错的选择。该课程从科技创新的原因及任务入手，通过一系列实用的方法和案例，帮助你培养创新思维能力，掌握抽象思维和想象力等关键技能。无论你是从事科技创新、产品设计、还是企业管理等领域，这些技能都能为你的工作带来新的灵感和思路。 ',
				'快来点击<a href="https://le.ouchn.cn/courseDetails/CAAA010000056391" target="_blank" style="color:#0947D5;">《科技创新思维的养成》</a>进行学习吧！',
			],
		},
	],
	msgList: [],
	input: '',
	currentDate: '',
	guideShow: false,
});

onMounted(() => {
	drawer.value = props.isShow;
	handleMsg();
	const currentHour = moment().hour();
	const str = currentHour > 12 ? '下午' : '上午';
	data.currentDate = moment().format('M月D日') + '  ' + str + moment().format('h:mm');

	setTimeout(() => {
		data.guideShow = true;
	}, 2000);
});

const watchSize = () => {
	var erd = elementResizeDetectorMaker();
	const run = throttle((el: HTMLDivElement) => {
		const height = el.offsetHeight;
		chatWindow.value.scrollTop = height;
	}, 800);
	erd.listenTo(chatWindowContent.value, run);
};

const send = () => {
	if (trim(data.input)) {
		addmsg(data.input);
	}

	setTimeout(() => (data.input = ''));
};

const ask = (q: any) => {
	addmsg(q);
};

// 发送消息
const addmsg = (s: any) => {
	data.msgList.push({
		content: s,
		type: 1,
		me: true,
	});
	handleMsg(s);
};

// 根据消息处理回复
const handleMsg = (s: string = '') => {
	const result = data.aslist.find((v: any) => v.q === s);
	if (!isEmpty(result)) {
		data.msgList.push({ content: result.a, type: 1, me: false });
	}
};

const hide = () => {
	drawer.value = false;
	emit('hide');
};
</script>

<style scoped lang="scss">
.title-area {
	position: sticky;
	top: 0;
	width: 100%;
	height: 54px;
	background: #ffffff;
	border-radius: 13px;
	padding-left: 13px;
	padding-right: 13px;

	h4 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #000000;
		line-height: 17px;
	}

	.title-icon {
		width: 28px;
		height: 45px;
		margin-right: 7px;
	}

	.close-icon {
		width: 24px;
		height: 24px;
		padding-right: 20px;
	}
}
</style>

<style lang="scss">
.my-chat-el-drawer__body {
	.el-drawer__body {
		padding: 0 !important;
		background: #f4f6fa;
		box-shadow: -2px 2px 15px 0px #202841, inset 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
		.chat-container {
			height: 100%;
			display: flex;
			flex-direction: column;
			.content-chat-box {
				overflow-y: auto;
				flex-direction: column;
				padding-bottom: 262px;

				.date {
					font-size: 12px;
					font-family: AvenirNext-Regular, AvenirNext;
					font-weight: 400;
					color: #58617e;
					line-height: 22px;
					text-align: center;
					margin-top: 27px;
				}

				.guide-area {
					font-size: 16px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #000000;
					line-height: 22px;
					margin-left: 57px;
					margin-top: 30px;

					.guide-title {
						font-size: 16px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #000000;
						line-height: 22px;
					}

					.guide-list {
						.item {
							padding: 0 10px;
							background: #ffffff;
							border-radius: 15px;
							border: 1px solid #e9f0ff;
							font-size: 13px;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							color: #202841;
							line-height: 26px;
							width: fit-content;
							margin-top: 10px;
						}
					}
				}
			}
			.bottom-area {
				position: absolute;
				bottom: 0;
				width: 100%;
				height: 242px;

				.operation {
					height: 40px;
					display: flex;
					align-items: center;
					background: #fcfdff;
					border: 1px solid #d5dae0;

					.operation-icon {
						transform: scale(0.5);
					}
				}
				.my-input {
					width: 100%;
					.el-textarea__inner {
						width: 100%;
						height: 202px;
						position: relative;
						border: none;
						resize: none;
						box-shadow: none;
						border-radius: none;
					}
				}

				.send-btn {
					position: absolute;
					right: 18px;
					bottom: 20px;
					width: 75px;
					height: 30px;
					background: #316efa;
					border-radius: 18px;
					color: #ffffff;
					font-size: 14px;
					justify-content: center;
					display: flex;
					align-items: center;
					cursor: pointer;
				}
			}
		}
	}
}
</style>
