<template>
  <div class="media-video-container">
    <!-- 默认纯视频 -->
    <div
      v-if="columnsItem.Css.includes('default_video')"
      :class="columnsItem.Css"
      class="media-video default_video"
    >
      <!-- 视频播放器 -->
      <div class="video-player-container">
        <video
          controls
          poster="@/assets/images/event-video-cover.png"
          width="440"
          height="300"
        >
          <source :src="data.VideoUrl" type="video/mp4" />
          您的浏览器不支持 HTML5 video 标签。
        </video>
        <div class="video-player-title flex-center-center">
          {{ props.columnsItem.Title }}
        </div>
      </div>
    </div>
    <!-- 带背景的视频 -->
    <div v-else class="media-video-default-container">
      <!-- 背景图 -->
      <div class="event-video-bg">
        <img
          class="event-video-bg-img"
          src="@/assets/images/event-video-bg.png"
          alt=""
        />
      </div>

      <!-- 视频内容 -->
      <div class="media-video-default">
        <div class="media-video-default-content">
          <div class="flex-center-center">
            <!-- 视频左背景 -->
            <div class="video-player-bg-left"></div>
            <!-- 视频播放器 -->
            <div class="video-player-container">
              <video
                width="800"
                height="478"
                controls
                poster="@/assets/images/event-video-cover.png"
              >
                <source :src="data.VideoUrl" type="video/mp4" />
                您的浏览器不支持 HTML5 video 标签。
              </video>
            </div>
            <!-- 视频右背景 -->
            <div class="video-player-bg-right"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { reactive, onMounted } from "vue";
const props = defineProps({
  columnsItem: {
    type: Object,
    default: {},
  },
  ChannelId: {
    type: String,
    default: "",
  },
});
const data = reactive({
  VideoUrl: "",
});
onMounted(() => {
  console.log(props.columnsItem);
  data.VideoUrl = JSON.parse(props.columnsItem.Data).VideoUrl;
});

console.log(data.VideoUrl);
</script>
<style scoped lang="scss">
.media-video-container {
  width: 100%;
  // height: 560px;
  .default_video {
    .video-player-container {
      width: 100%;
      height: 100%;
      background: #ffffff;
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.18);
      border-radius: 2px 2px 8px 8px;
      video {
        // width: 100%;
        // height: 100%;
        object-fit: cover;
      }
      .video-player-title {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 22px;
        padding: 20px 0;
        box-sizing: border-box;
      }
    }
  }
  .media-video-default-container {
    width: 100%;
    position: relative;
    padding: 60px 0 0px 0;
    box-sizing: border-box;
    .event-video-bg {
      position: absolute;
      left: 0;
      top: 0;
      .event-video-bg-img {
        width: 526px;
        height: auto;
      }
    }
    .media-video-default {
      width: 80%;
      max-width: 840px;
      margin: 0 auto;
      position: relative;
      .media-video-default-content {
        width: 100%;
        height: 500px;
        position: relative;
        .video-player-bg-left {
          width: 236px;
          height: 350px;
          background: #1a5fea;
          position: absolute;
          bottom: 0;
          left: 0;
        }
        .video-player-container {
          z-index: 100;
          width: 800px;
          height: 480px;
          position: absolute;
          left: 20px;
          bottom: 20px;
          cursor: pointer;
          video {
            width: 100%;
            object-fit: cover;
          }
          .event-video-pause-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            img {
              width: 40px;
              height: auto;
            }
          }
        }
        .video-player-bg-right {
          width: 236px;
          height: 260px;
          background: #1a5fea;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
  }
}
</style>