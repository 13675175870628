import Oidc from 'oidc-client';
export const oidcSettings = {
	authority: import.meta.env.VITE_AUTHORITY,
	client_id: import.meta.env.VITE_CLIENT_ID, //项目的id需要后端配置
	client_secret: import.meta.env.VITE_CLIENT_SECRETS, //密钥
	redirect_uri: window.location.origin + '/signin-oidc', //返回地址
	// redirect_uri:import.meta.env.VITE_REDIRECT_URL,
	scope: import.meta.env.VITE_SCOPE,
	response_type: import.meta.env.VITE_RESPONSE_TYPE,
	post_logout_redirect_uri: import.meta.env.VITE_POST_LOGOUT_REDIRECT_URI,
	automaticSilentRenew: true, //通过当前留存的id_token去请求新的身份认证信息。
	includeIdTokenInSilentRenew: true,
	clockSkew: import.meta.env.VITE_CLOCKSKEW,
	silent_redirect_uri: import.meta.env.VITE_SILENT_REDIRECT_URI,
};
// console.log(oidcSettings)
// const OIDCConfig= {
//   authority: 'http://passport-le.ouc-online.com.cn/',
//   client_id: 'le',
//   client_secret:'E7QqwCRZbJrqt@tqdcuwZXz!@o',
//   redirect_uri: 'https://le.ouchn.cn/signin-oidc',
//   scope: 'openid leuser leportalapi profile PortalAPI offline_access IdentityServerApi',
//   response_type: 'code',
//   post_logout_redirect_uri: 'le.ouchn.cn/',
//   automaticSilentRenew: true,
//   includeIdTokenInSilentRenew: true,
//   clockSkew: 30 * 60 * 5,
//   silent_redirect_uri: 'https://le.ouchn.cn/redirect-silentrenew'
// }
// const OIDCConfig = import.meta.env.VITE_OIDCConfig
export const mgr = new Oidc.UserManager(oidcSettings);

// VITE_OIDCConfig = {authority: "http://passport-le.ouc-online.com.cn/",client_id: "le",redirect_uri: "http://localhost:4200/#/signin-oidc#",scope: "openid leuser leportalapi profile PortalAPI",response_type: "id_token token",post_logout_redirect_uri: "http://localhost:4200/",automaticSilentRenew: true,includeIdTokenInSilentRenew: true,clockSkew: 30 * 60 * 5,silent_redirect_uri: "http://localhost:4200/#/redirect-silentrenew#"}
