<template>
	<div class="advertise-modal" :class="{ animation: data.isShow }">
		<!-- 隐藏标题 -->
		<div :class="props.columnsItem.Css" class="picture-link-item">
			<div :class="data.detail.Css" @click="hide">
				<img :src="data.detail.ImageUrl" alt="" class="advertise-icon" />
				<img src="@/assets/images/common/close-icon.png" alt="" class="close-icon" />
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { reactive } from 'vue';
const emit = defineEmits(['hide']);
const props = defineProps({
	columnsItem: {
		type: Object,
		default: {},
	},
});
const data = reactive({
	detail: JSON.parse(props.columnsItem.Data),
	isShow: true,
});

const hide = () => {
	data.isShow = !data.isShow;
	emit('hide');
};
</script>
<style scoped lang="scss">
::-webkit-scrollbar {
	display: none;
}
@keyframes fadenum {
	0% {
		opacity: 1;
	}
	60% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.animation {
	animation: fadenum 10s;
}
.advertise-modal {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9999999;
	width: 100%;
	height: 100%;
	background: rgba($color: #fff, $alpha: 0.48);
	display: flex;
	justify-content: center;
	opacity: 1;
	overflow: hidden;

	.picture-link-item {
		margin-top: 10%;
		position: relative;

		.advertise-icon {
			width: 714px;
			height: auto;
		}

		.close-icon {
			width: 24px;
			height: 24px;
			position: absolute;
			top: 64px;
			right: 0;
		}
	}
}
</style>
