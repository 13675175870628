<template>
	<div class="mobile-home">
		<div class="gradient">
			<div class="top-box" ref="topWrapper">
				<SearchBar></SearchBar>
			</div>
		</div>
		<div class="main-wrapper" :style="{ 'margin-top': -(6.16 - navHeight / 50) + 'rem' }">
			<van-tabs
				shrink
				@click-tab="onClickTab"
				sticky
				:offset-top="navHeight / 50 + 'rem'"
				class="mobile-home-nav"
				background="transparent"
				line-width="0.32rem"
				line-height="0.06rem"
				title-inactive-color="#000"
			>
				<van-tab v-for="(item, index) in data.nav" :title="item.Name">
					<template v-if="item.IsExternal">
						<iframe
							id="iframe"
							:src="item.ExternalUrl"
							width="100%"
							height="100%"
							referrerPolicy="no-referrer"
							frameborder="0"
							@load="loaded"
						></iframe>
					</template>
					<template v-else>
						<!-- 内容 -->
						<div class="cache-body" v-if="data.CacheBody">
							<template v-for="(item, index) in data.CacheBody" :key="index">
								<template
									v-if="
										(item.ClientDisplayMethod2 && item.ClientDisplayMethod2.includes('H5')) ||
										(!item.ClientDisplayMethod2 && item.ClientDisplayMethod != 'PC')
									"
								>
									<!-- 行 -->
									<div v-if="!item.Hide" :class="item.Css" class="col_1">
										<!-- 列 -->
										<template v-for="(columnsItem, carouselIndex) in item.Columns" :key="carouselIndex">
											<!-- 隐藏列 -->
											<template v-if="!columnsItem.Hide">
												<BaseMobileSwiper
													v-if="columnsItem.ColumnType == 'Carousel'"
													:columnsItem="columnsItem"
													:ChannelId="data.ChannelId"
												/>

												<MobilePictureLinkList
													v-if="columnsItem.ColumnType == 'ImageLinkList'"
													:columnsItem="columnsItem"
												></MobilePictureLinkList>

												<MobileButtonList
													v-if="columnsItem.ColumnType == 'ButtonList'"
													:columnsItem="columnsItem"
													:is-horizontal-scroll="true"
												></MobileButtonList>

												<MobileSpecialCoursesList
													v-if="columnsItem.ColumnType == 'NewestSpecialCourseList'"
													:columnsItem="columnsItem"
													:isCard="true"
												></MobileSpecialCoursesList>

												<MobileSpecialBookList
													v-if="columnsItem.ColumnType == 'NewestSpecialBookList'"
													:columnsItem="columnsItem"
													:is-horizontal-scroll="true"
													:isCard="true"
												></MobileSpecialBookList>

												<MobileTextLinkList
													v-if="columnsItem.ColumnType == 'TextLinkList'"
													:columnsItem="columnsItem"
												></MobileTextLinkList>

												<MobileTextList
													v-if="columnsItem.ColumnType == 'TextList'"
													:columnsItem="columnsItem"
												></MobileTextList>

												<MobilePictureLink
													v-if="columnsItem.ColumnType == 'ImageLink'"
													:columnsItem="columnsItem"
												></MobilePictureLink>

												<MobileMediaObjectList
													v-if="columnsItem.ColumnType == 'MediaObject'"
													:columnsItem="columnsItem"
													:isCard="true"
												></MobileMediaObjectList>

												<MobileBlockNoticeList
													v-if="columnsItem.ColumnType == 'OrganizationNotice'"
													:columnsItem="columnsItem"
												></MobileBlockNoticeList>

												<MobileCourseRank
													:params="columnsItem.Data"
													v-if="columnsItem.ColumnType == 'RankingList'"
												></MobileCourseRank>
												<MobileBlockStructure
													:columnsItem="columnsItem"
													v-if="columnsItem.ColumnType == 'BlockStructure'"
												></MobileBlockStructure>
											</template>
										</template>
									</div>
								</template>
							</template>
						</div>
					</template>
				</van-tab>
			</van-tabs>
		</div>

		<BaseMobileFooter />
	</div>
</template>
<script setup lang="ts">
import { PostSpecialsApi } from '@/http/api/course';
import { GetViewApi } from '@/http/api/basedata';
import { reactive } from 'vue';
import { GetNavigationInfo } from '@/http/api/navigation';
import { onMounted } from 'vue';
import { ref } from 'vue';
import { sortList } from '@/utils/common';

const topWrapper = ref<HTMLDivElement>();
let navHeight = ref();
const data: any = reactive({
	CacheBody: [], //渲染页面
	courseSeriesList: [],
	ChannelId: 'home', //频道id
	Keyword: '', //关键字
	specialList: [], //专题数组
	nav: [],
});

onMounted(() => {
	getNav();
	navHeight.value = topWrapper.value?.offsetHeight;
});

// 获取页面
const getView = (item: any) => {
	const params = {
		DependentType: item.DependentType,
		DependentId: item.DependentId,
	};
	GetViewApi(params)
		.then((res: any) => {
			// 渲染首页页面
			data.CacheBody = JSON.parse(res.CacheBody).Rows;
			// console.log(data.CacheBody);
			// 专题id数组
			const specialIdList: Array<any> = [];

			data.CacheBody.forEach((item: any) => {
				if (
					((item.ClientDisplayMethod2 && item.ClientDisplayMethod2.length && item.ClientDisplayMethod2.includes('H5')) ||
						item.ClientDisplayMethod != 'PC') &&
					!item.Hide
				) {
					item['Columns'].forEach((childItem: any) => {
						if (childItem.ColumnType == 'NewestSpecialCourseList') {
							data.specialList = JSON.parse(childItem.Data)['Items']; //专题数组

							data.specialList.forEach((listItem: any) => {
								if (listItem.Title == '猜你喜欢') {
									listItem.SpecialId = 'HotSale';
								}
								let obj = {
									Key: listItem.SpecialId + '',
									Value: listItem.Count + '',
								};
								specialIdList.push(obj);
							});
						}
					});
					if (item.Columns.find((v: any) => v.ColumnType === 'RankingList')) {
						item.Columns[0].Data = item.Columns.map((v: any) => v.Data);
						item.Columns = [item.Columns[0]];
					}
				}
			});

			// 获取专题列表
			getSpecialsList(specialIdList);
		})
		.catch((err: any) => {
			console.log(err);
		});
};
const getNav = () => {
	GetNavigationInfo({ Client: 0 }).then((res) => {
		data.nav = res;
		sortList(data.nav, 'Orderby');
		if (!data.nav[0].IsExternal) {
			getView(data.nav[0].Dependent);
		}
	});
};

const onClickTab = ({ name }: any) => {
	if (!data.nav[name].IsExternal) {
		data.CacheBody = {};
		getView(data.nav[name].Dependent);
	}
};

// 获取专题课程列表
const getSpecialsList = (specialIdList: Array<string>) => {
	PostSpecialsApi(specialIdList)
		.then((res: any) => {
			const courseSeriesList = res;
			data.specialList.forEach((item) => {
				courseSeriesList.forEach((courseItem: any) => {
					if (item.SpecialId == courseItem.Id && item.SubTitle) {
						courseItem['SubTitle'] = item.SubTitle;
					}
				});
			});

			data.courseSeriesList = courseSeriesList;
		})
		.catch((err: any) => {
			console.log(err);
		});
};
</script>
<style lang="scss">
@import './index.scss';
</style>
