<template>
	<div :class="[{ 'flex-center-start': columnsItem.SameLine }, columnsItem.Css]" class="scroll-button-list-wrapper">
		<!-- 隐藏标题 -->
		<div v-if="!props.columnsItem.HideHeader" class="title">
			{{ props.columnsItem.Title }}
		</div>
		<div class="scroll-button-list-container" ref="scrollContainer" @scroll="scrollCon" @touchmove.stop>
			<!-- 按钮列表 -->
			<div class="button-list" :class="columnsItem.Css" ref="scrollList">
				<div
					v-for="(item, index) in JSON.parse(props.columnsItem.Data).Items"
					:key="index"
					@click="toChannel(item)"
					class="button-list-item flex-center-center"
				>
					<img class="button-logo" :src="item.Icon" :alt="item.Title" />
					<span class="button-title">{{ item.Title }}</span>
				</div>
			</div>
		</div>
		<div class="flex-center-center slide-container" v-if="JSON.parse(props.columnsItem.Data).Items.length">
			<div class="slide-bar" ref="scrollBox">
				<div class="slide-block" :style="{ marginLeft: data.scrollPercent, width: data.slideWidth + 'rem' }" ref="scrollBar" />
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { reactive, ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
const emits = defineEmits(['toChannel']);
const router = useRouter();
const props = defineProps({
	columnsItem: {
		type: Object,
		default: {},
	},
	list: {
		type: Array,
		default: [],
	},
});
const scrollContainer = ref<HTMLDivElement>();
const scrollList = ref<HTMLDivElement>();
const scrollBox = ref<HTMLDivElement>();
const scrollBar = ref<HTMLDivElement>();

// 容器宽度
const containerWidth = computed(() => scrollContainer.value.offsetWidth);
// 列表宽度
const listWidth = computed(() => scrollList.value.offsetWidth);

const data = reactive({
	scrollLeft: 0, // 当前滚动位置
	thumbWidth: 0, // 滚动条宽度
	scrollPercent: 0,
	slideWidth: 0,
	conScrollWidth: '', // 滚动内容的宽度
	conClientWidth: '', // box的宽度
	conScrollLeft: '', // 滚动了的距离
});
// 点击去频道页
const toChannel = (item: any) => {
	emits('toChannel', item);
};

onMounted(() => {
	data.slideWidth = (scrollContainer.value.offsetWidth / scrollList.value.offsetWidth) * 0.5;
});

// 滚动事件
//使用比例来实现同步滚动
const scrollCon = (e) => {
	//this.conScrollWidth 滚动区域的长度
	//this.conClientWidth div区域长度
	//this.conScrollLeft   滚动了的距离
	data.conScrollWidth = e.target.scrollWidth;
	data.conClientWidth = e.target.clientWidth;
	data.conScrollLeft = e.target.scrollLeft;
	// 计算滑动的比例
	const scale = (data.conScrollWidth - data.conClientWidth) / (scrollBox.value.clientWidth - scrollBar.value.clientWidth);
	// 滚动条滑动的距离
	data.scrollPercent = data.conScrollLeft / scale / 50 + 'rem';
};
</script>

<style lang="scss" scoped>
.scroll-button-list-wrapper {
	.title {
		font-size: 0.32rem;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #000000;
		line-height: 0.32rem;
		margin-bottom: 0.42rem;
	}
	.scroll-button-list-container {
		width: 100%;
		padding: 0.36rem 0.32rem 0 0.32rem;
		box-sizing: border-box;
		border-radius: 0.16rem;
		display: flex;
		overflow: auto;
		&::-webkit-scrollbar {
			display: none;
			height: 0;
		}

		.button-list {
			display: flex;
			.button-list-item {
				width: 0.8rem;
				flex-direction: column;
				margin-bottom: 0.32rem;
				padding-right: calc((100vw - 1.04rem - 0.8rem * 5) / 4);
				.button-logo {
					width: 0.8rem;
					height: auto;
					margin-bottom: 0.16rem;
				}
				.button-title {
					font-size: 0.28rem;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #333333;
					line-height: 0.28rem;
				}

				&:last-of-type {
					padding-right: 0;
				}
			}
		}
	}
}

.slide-container {
	display: flex;
	flex-direction: column;
	.slide-bar {
		overflow: hidden;
		width: 0.5rem;
		height: 0.08rem;
		background: #dddddd;
		border-radius: 0.04rem;
	}
	.slide-block {
		width: 0.25rem;
		height: 0.08rem;
		background: #aaaaaa;
		height: 100%;
		border-radius: 0.04rem;
		transition: all 0.3 linear;
	}
}
</style>
