import request from '../request';
const baseDownloadURL: any = import.meta.env.VITE_DOWNLOAD_BASE_URL;
// 获取所有频道数据
export function GetChannelApi(data: object = {}) {
	return request({
		url: '/BaseData/Channel/All',
		method: 'get',
		// baseURL:'http://49.4.122.4:2103/api',
		// baseURL:'/api',
		data,
	});
}
// 生成页面模板
export function GetViewApi(data: object) {
	return request({
		url: '/VisualPage/' + data.DependentType + '/' + data.DependentId,
		method: 'get',
		data,
	});
}
// 获取基础数据
export function GetBaseDataApi(data: object) {
	return request({
		url: '/BaseData',
		method: 'get',
		data,
	});
}

// 获取指定分类的子分类
export function GetChildCourseCategoryApi(data: object) {
	return request({
		url: '/BaseData/' + data.ParentId + '/CourseCategory',
		method: 'get',
		data,
	});
}

// 获取指定频道下的指定层级分类列表
export function GetChannelLevelCourseCategoryApi(data: object) {
	return request({
		url: '/BaseData/Channel/' + data.ChannelId + '/CourseCategory/' + data.Level,
		method: 'get',
		data,
	});
}
// 获取全部来源
export function GetAllSourceApi(data: object) {
	return request({
		url: '/BaseData/Source/All',
		method: 'get',
		data,
	});
}
// 获取某个来源
export function GetOneSourceApi(data: object) {
	return request({
		url: '/BaseData/Source/' + data.Id,
		method: 'get',
		data,
	});
}
// 获取安卓apk
export function GetAndroidAppApi(data: object) {
	return request({
		url: 'App/AndroidApp/Version/Newest',
		method: 'get',
		data,
		baseURL: baseDownloadURL,
	});
}
//文件上传
export function PostUploadApi(data: object) {
	return request({
		url: '/Upload/UpFile',
		method: 'post',
		data: data.formdata,
	});
}
// 获取总访问量
export function GetTotalPageViewApi(data: object) {
	return request({
		url: '/Browse/Pageview',
		method: 'get',
		data: '',
	});
}
// 提交总访问量
export function PostTotalPageViewApi(data: object) {
	return request({
		url: '/Browse/Pageview',
		method: 'post',
		data: '',
	});
}

// 根据Jti获取Token
export function getUserToken(data: object) {
	return request({
		url: `/WeChat/${data.Jti}/AccessToken`,
		method: 'get',
		data,
	});
}
