<template>
	<div class="mobile-header flex-center-between">
		<div class="header-search flex-center-between">
			<input v-model="data.Keyword" type="text" placeholder="搜索" @keyup.enter.native="searchEnter" />
			<img @click="goSearch()" class="search-icon" src="@/assets/images/search-gray-icon.png" alt="" />
		</div>
	</div>
</template>
<script setup lang="ts">
import { ElMessage } from 'element-plus';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const data = reactive({
	Keyword: '',
});

// 搜索
const searchEnter = (e: any) => {
	if (data.Keyword == '' || data.Keyword == undefined) {
		ElMessage({
			message: '请输入关键字搜索',
			type: 'warning',
		});
		return;
	}
	var keyCode = window.event ? e.keyCode : e.which;
	if (keyCode == 13) {
		router.push({
			path: '/screenCourseList',
			query: {
				Keyword: data.Keyword,
			},
		});
	}
};
// 搜索
const goSearch = () => {
	if (data.Keyword == '' || data.Keyword == undefined) {
		ElMessage({
			message: '请输入关键字搜索',
			type: 'warning',
		});
		return;
	}
	router.push({
		path: '/screenCourseList',
		query: {
			Keyword: data.Keyword,
		},
	});
};
</script>

<style scoped lang="scss">
.mobile-header {
	width: 100%;
	padding: 0.2rem 0.3rem;
	box-sizing: border-box;
	z-index: 10;
	.logo-icon {
		width: 0.68rem;
		height: auto;
		margin-right: 0.2rem;
	}
	.header-search {
		flex: 1;
		height: 0.74rem;
		background: #ffffff;
		border: 0.02rem solid #cccccc;
		padding: 0 0.4rem;
		box-sizing: border-box;
		border-radius: 0.14rem;
		input {
			width: 100%;
			height: 100%;
			border: none;
			outline: none;
		}
		.search-icon {
			width: 0.36rem;
			height: auto;
			margin-left: 0.2rem;
		}
	}
	a {
		.sort-icon {
			width: 0.48rem;
			height: auto;
			margin-left: 0.34rem;
		}
	}
}
</style>
