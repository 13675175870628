<template>
	<div class="base-footer-container">
		<div class="base-footer-wrapper">
			<div class="base-footer-wrapper-content">
				<div class="base-footer-wrapper-content-left">
					<div class="base-footer-title">{{ props.footerTitle }}</div>
					<div class="flex-start-start">
						<div class="info-left">
							<div class="tips">国家开放大学版权所有</div>
							<div class="tips">地址：北京市海淀区复兴路75号</div>
							<div class="tips">咨询热线：400-867-9660</div>
							<div class="tips">运营支持：国开在线教育科技有限公司</div>
						</div>
						<div>
							<div class="tips" v-if="!props.rightType">实时访问次数：{{ data.totalPageViewNumber }}</div>
							<div class="tips">资源仅供教学使用，如有侵权请联系我们</div>
							<div class="tips" v-if="!props.rightType">资源内容均由人民网进行审核</div>
						</div>
					</div>
				</div>
				<div class="base-footer-wrapper-content-right" v-if="!props.rightType">
					<div class="flex-start-start code-icon-list">
						<div class="icon-item">
							<el-image style="width: 80px; height: 80px" :src="getImageUrl('down.png')" />
							<div class="icon-text">APP下载</div>
						</div>
						<div class="icon-item">
							<el-image style="width: 80px; height: 80px" :src="getImageUrl('wechat2.jpg')" />
							<div class="icon-text">订阅号助手</div>
						</div>
					</div>
					<div class="flex-start-start code-icon-list">
						<div class="icon-item">
							<el-image style="width: 80px; height: 80px" :src="getImageUrl('wechat1.jpg')" />
							<div class="icon-text">平台公众号</div>
						</div>
						<div class="icon-item">
							<el-image style="width: 80px; height: 80px" :src="getImageUrl('video.png')" />
							<div class="icon-text">平台视频号</div>
						</div>
					</div>
				</div>

				<div
					class="base-footer-wrapper-content-right"
					v-if="props.rightType === 1"
					:class="{ rightTypeClass: props.rightType === 1 }"
				>
					<div class="flex-start-start code-icon-list">
						<div class="icon-item">
							<el-image style="width: 80px; height: 80px" :src="getImageUrl('blibli.png')" />
							<div class="icon-text">国开B站</div>
						</div>
						<div class="icon-item">
							<el-image style="width: 80px; height: 80px" :src="getImageUrl('class.jpg')" />
							<div class="icon-text">国开轻课堂</div>
						</div>
					</div>
				</div>
			</div>
			<div class="base-footer-wrapper-copyright">
				<div>京ICP备05064925号</div>

				<div class="filings">
					<div class="flex-center-start">
						<img src="@/assets/images/footer/copyright-icon.png" alt="" class="icon" />
						<div>京公网安备 11010802039510号</div>
					</div>
				</div>
				<div>推荐使用Chrome、Edge、360极速浏览器等</div>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { GetTotalPageViewApi, PostTotalPageViewApi } from '@/http/api/basedata';
import { reactive } from 'vue';
const data = reactive({
	totalPageViewNumber: 0,
});

const props = defineProps({
	footerTitle: {
		type: String,
		default: '终身教育平台',
	},
	rightType: {
		type: Number,
		default: '',
	},
});

// 获取图片路径
const getImageUrl = (name: string) => {
	return new URL(`../../assets/images/footer/${name}`, import.meta.url).href;
};

// ------------------- 接口开始  -----------------
// 获取访问量
const getTotalPageViewApi = () => {
	const params = {};
	GetTotalPageViewApi({})
		.then((res: any) => {
			data.totalPageViewNumber = res;
		})
		.catch((err: any) => {
			console.log(err);
		});
};
getTotalPageViewApi();
// 提交访问量
const postTotalPageViewApi = () => {
	const params = {};
	PostTotalPageViewApi({})
		.then((res: any) => {
			getTotalPageViewApi();
		})
		.catch((err: any) => {
			console.log(err);
		});
};
postTotalPageViewApi();
</script>
<style scoped lang="scss">
.base-footer-container {
	position: absolute;
	bottom: 0;
	width: 100%;
	background: #181131;
	height: 328px;

	.base-footer-wrapper {
		width: 90%;
		max-width: 1200px;
		margin: 0 auto;
		box-sizing: border-box;
		&-content {
			display: flex;
			justify-content: space-between;
			padding-bottom: 10px;

			&-left {
				.base-footer-title {
					font-family: PingFang SC, PingFang SC;
					font-weight: 600;
					font-size: 20px;
					color: #dddddd;
					margin-bottom: 20px;
					line-height: 40px;
					margin-top: 25px;
				}

				.info-left {
					margin-right: 50px;
				}

				.tips {
					margin-bottom: 10px;
					position: relative;
					padding-left: 15px;
					font-family: PingFang SC, PingFang SC;
					font-weight: 400;
					font-size: 13px;
					color: #aaaaaa;

					&::before {
						content: '';
						display: block;
						position: absolute;
						left: 0;
						top: 8px;
						width: 4px;
						height: 4px;
						background: #3c6bff;
						border-radius: 50%;
					}
				}
			}
			&-right {
				&.rightTypeClass {
					margin-top: 78px;
				}
				.code-icon-list {
					margin-top: 28px;
					.icon-item {
						min-width: 100px;
						display: flex;
						flex-direction: column;
						align-items: center;
						box-sizing: border-box;

						&:nth-child(odd) {
							margin-right: 30px;
						}

						.icon-text {
							font-family: PingFang SC, PingFang SC;
							font-weight: 400;
							font-size: 12px;
							color: #aaaaaa;
							line-height: 18px;
							text-align: center;
							margin-top: 4px;
						}
					}
				}
			}
		}

		&-copyright {
			display: flex;
			justify-content: space-between;
			font-family: PingFang SC, PingFang SC;
			font-weight: 400;
			font-size: 13px;
			color: #aaaaaa;
			line-height: 30px;
			margin-top: 12px;

			.filings {
				position: relative;
				padding-left: 10px;

				.icon {
					width: 20px;
					height: 20px;
					margin-right: 8px;
					vertical-align: bottom;
				}

				// &::before {
				// 	content: '';
				// 	display: block;
				// 	position: absolute;
				// 	left: 0;
				// 	top: 9px;
				// 	width: 1px;
				// 	height: 13px;
				// 	background: #aaaaaa;
				// 	border-radius: 50%;
				// }
			}
		}
	}
}
</style>
