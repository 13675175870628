<template>
	<div class="library-list-item" v-for="(item, index) in props.list" :key="index" @click="getBookUrl(item)">
		<div class="library-cover">
			<img class="library-cover-img" :src="item.Cover" alt="" />
		</div>
		<div class="library-list-item-describe">
			<h2 class="library-title twoDot">{{ item.Title }}</h2>
		</div>
	</div>
</template>
<script setup lang="ts">
const emits = defineEmits(['getBookUrl']);
const props = defineProps({
	list: Array,
	quoteType: Number,
});

const getBookUrl = (item: any) => {
	emits('getBookUrl', item);
};
</script>

<style scoped lang="scss">
.library-list-item {
	width: 31%;
	margin-bottom: 0.32rem;
	flex-basis: 31%;
	flex-shrink: 0;
	margin-right: 0.2rem;
	.library-cover {
		width: 100%;
		max-width: 2.28rem;
		height: 3rem;
		margin-bottom: 0.08rem;
		.library-cover-img {
			display: block;
			width: 100%;
			height: auto;
			border-radius: 0.18rem;
		}
	}
	.library-list-item-describe {
		h2.library-title {
			font-size: 0.28rem;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 400;
			color: #000000;
			line-height: 0.4rem;
			max-height: 0.8rem;
		}
	}
}
</style>
