<template>
	<!-- 提示框 -->
	<el-dialog
		draggable
		center
		:modal="false"
		v-model="data.customerVisible"
		modal-class="baizecustomer-modal"
		:before-close="cancel"
		:width="iframeWidth + 'px'"
		:height="iframeHeight + 'px'"
		:show-close="false"
	>
		<div
			class="background_white baize-wrapper"
			:style="{ height: iframeHeight + 'px', width: iframeWidth + 'px', 'border-radius': '14px' }"
		>
			<div class="close-icon" @click="cancel">
				<el-icon><CloseBold color="#000" /></el-icon>
			</div>

			<div class="iframe-container">
				<iframe
					ref="iframe"
					:key="data.key"
					width="100%"
					height="100%"
					:src="data.currentSrc"
					frameborder="0"
					referrerPolicy="no-referrer"
					scrolling="no"
					style="border-radius: 10px"
				></iframe>
			</div>
		</div>
	</el-dialog>
</template>
<script lang="ts" setup>
import { watch } from 'vue';
import { reactive, onMounted, ref } from 'vue';
import { CloseBold } from '@element-plus/icons-vue';

const iframe = ref();
const emits = defineEmits(['close']);
const iframeWidth = ref(400);
const iframeHeight = ref(500);
const loadingInstance = ref();

const props = defineProps({
	isShow: {
		type: Boolean,
		default: false,
	},
});

const data: any = reactive({
	customerVisible: false,
	currentSrc: `https://udesk.ouc-online.com.cn/im_client/?web_plugin_id=35122&group_id=14434&switchmanual=true`,
	key: '',
});

const cancel = () => {
	data.customerVisible = false;
	emits('close');
};

watch(
	() => props.isShow,
	() => {
		data.customerVisible = props.isShow;
	},
);

onMounted(() => {
	data.customerVisible = props.isShow;
});
</script>
<style lang="scss">
// 提示框
.baizecustomer-modal {
	position: fixed;
	top: 0;
	bottom: constant(safe-area-inset-bottom);
	bottom: env(safe-area-inset-bottom);
	left: 0;
	right: 0;
	z-index: 2002;
	.el-overlay-dialog {
		position: fixed;
		top: 0;
		bottom: constant(safe-area-inset-bottom);
		bottom: env(safe-area-inset-bottom);
		left: 0;
		right: 0;

		.el-dialog {
			border-radius: 12px;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			margin: 0 !important;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			.el-dialog__header {
				display: none !important;
			}

			.el-dialog__body {
				padding: 0 !important;
				box-sizing: border-box;

				.baize-wrapper {
					border-radius: 10px;
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 40px 20px 0 20px;
					.close-icon {
						position: absolute;
						top: 0;
						right: 0;
						cursor: pointer;
						padding: 20px 30px 0 10px;
						box-sizing: border-box;
					}

					.iframe-container {
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}
}
</style>
