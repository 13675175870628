<template>
	<div class="special-courses-list-container" :class="[{ 'flex-center-start': columnsItem.SameLine }, columnsItem.Css]">
		<!-- 隐藏标题 -->
		<div v-if="!props.columnsItem.HideHeader" class="title">
			{{ props.columnsItem.Title }}
		</div>
		<!-- 图书列表 -->
		<!-- 图书系列 -->
		<div class="special-courses-list">
			<div class="course-series-item" v-for="(item, index) in data.specialCoursesList" :key="index">
				<MobileSpecialTitle :title="item.Name" :subTitle="item.SubTitle" :SpecialId="item.Id" type="Book"></MobileSpecialTitle>
				<MobileBookList
					:list="item.Books"
					@login="data.notLoggedInVisible = true"
					:quoteType="1"
					:is-horizontal-scroll="props.isHorizontalScroll"
				></MobileBookList>
			</div>
		</div>
		<!-- 提示框  -  未登录 -->
		<div v-if="data.notLoggedInVisible">
			<NotLoggedIn @notLoggedInCancel="data.notLoggedInVisible = false"></NotLoggedIn>
		</div>
	</div>
</template>
<script setup lang="ts">
import { onMounted, reactive } from 'vue';
import { PostSpecialsApiByBook } from '@/http/api/book';
const props = defineProps({
	columnsItem: {
		type: Object,
		default: {},
	},
	ChannelId: {
		type: String,
		default: '',
	},
	isVerticalScroll: Boolean,
	isHorizontalScroll: Boolean,
});
// console.log(props.columnsItem);
const data = reactive({
	specialList: [], //专题数组
	specialIdList: [], //专题id数组
	specialCoursesList: [], //专题图书列表
	notLoggedInVisible: false,
});
// 专题列表
onMounted(() => {
	data.specialList = JSON.parse(props.columnsItem.Data)['Items']; //专题数组
	data.specialList.forEach((listItem: any) => {
		if (listItem.Title == '猜你喜欢') {
			listItem.SpecialId = 'HotSale';
		}
		let obj = {
			Key: listItem.SpecialId + '',
			Value: listItem.Count + '',
		};
		data.specialIdList.push(obj);
	});
	data.specialIdList;
	//   获取专题列表
	getSpecialsList();
});

// 获取专题图书列表
const getSpecialsList = () => {
	PostSpecialsApiByBook(data.specialIdList)
		.then((res: any) => {
			const specialCoursesList = res;
			// console.log(res);
			data.specialList.forEach((item) => {
				specialCoursesList.forEach((courseItem: any) => {
					if (item.SpecialId == courseItem.Id && item.SubTitle) {
						courseItem['SubTitle'] = item.SubTitle;
					}
				});
			});

			data.specialCoursesList = specialCoursesList;
		})
		.catch((err: any) => {
			console.log(err);
		});
};
</script>
<style scoped lang="scss">
.special-courses-list-container {
	margin-top: 0.3rem;
	padding: 0.32rem 0.32rem 0 0.32rem;
	box-sizing: border-box;
	background-color: #fff;
	border-radius: 0.16rem;
	.title {
		font-size: 0.32rem;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #000000;
		line-height: 0.32rem;
		margin-bottom: 0.42rem;
	}
	.special-courses-list {
		.course-series-item {
		}
	}
}
</style>
