<template>
	<div class="container">
		<img class="head" src="@/assets/images/chat/left-icon.png" />

		<div class="content">
			<div class="flex-center-start user-name">智能小泽学习助手</div>
			<div class="text" v-if="props.type === 1" ref="text_box"></div>
			<img class="img" :src="props.content" v-else-if="props.type === 2" />
		</div>
	</div>
</template>

<script setup lang="ts">
import TypeIt from 'typeit';
import { watch } from 'vue';
import { ref, onMounted } from 'vue';
const props = defineProps({
	content: { type: Array, default: [] },
	type: Number,
});
const text_box: any = ref(null);

watch(
	() => props.content,
	() => {
		new (TypeIt as any)(text_box.value, {
			strings: props.content,
			speed: 100, //打字的速度,每步之间以毫秒为单位测量。
			lifeLike: false, // 使打字速度不规则，就好像真人在做一样。
			breakLines: true,
		}).go();
	},
);

onMounted(() => {
	new (TypeIt as any)(text_box.value, {
		strings: props.content,
		speed: 10, //打字的速度,每步之间以毫秒为单位测量。
		lifeLike: false, // 使打字速度不规则，就好像真人在做一样。
		breakLines: true,
		cursor: false,
	}).go();
});
</script>

<style scoped lang="scss">
.container {
	display: flex;
	padding: 10px 15px;
	margin-right: 60px;

	.head {
		width: 40px;
		height: 40px;
		border-radius: 50%;
	}

	.content {
		margin-left: 10px;
		margin-top: 10px;
		.user-name {
			margin-bottom: 11px;
			font-size: 13px;
			font-family: ArialMT;
			color: #58617e;
			line-height: 22px;
		}
		.text {
			padding: 13px 15px;
			background: #ffffff;
			border-radius: 13px;
			border: 1px solid #e9f0ff;
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #202841;
			line-height: 25px;
		}

		.img {
			width: 100px;
			border-bottom-right-radius: 10px;
			border-top-right-radius: 10px;
			border-bottom-left-radius: 10px;
		}
	}
}
</style>
