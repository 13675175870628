<template>
	<div v-if="props.columnsItem.Title" class="block-list-title flex-center-between">
		<div class="flex-center-start">
			<h2 v-if="props.columnsItem.Title" class="main-title">
				{{ props.columnsItem.Title }}
			</h2>
			<h4 v-if="props.columnsItem.SubTitle" class="sub-title">{{ props.columnsItem.SubTitle }}</h4>
		</div>
		<div v-if="props.more" class="more flex-center-end" @click="toMore">
			<img src="@/assets/images/more-icon2.png" alt="" />
		</div>
	</div>
</template>

<script setup lang="ts">
const emits = defineEmits(['toMore']);
const props = defineProps({
	columnsItem: {
		type: Object,
		default: {},
	},
	more: {
		type: Boolean,
		default: false,
	},
});

const toMore = () => {
	emits('toMore');
};
</script>

<style lang="scss" scoped>
.block-list-title {
	margin-bottom: 0.42rem;
	.main-title {
		font-size: 0.32rem;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #000000;
		line-height: 0.32rem;
	}
	.sub-title {
		margin-left: 0.2rem;
		font-size: 0.24rem;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #000000;
		line-height: 0.24rem;
	}
	.more {
		font-size: 0.28rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;
		line-height: 0.2rem;
		cursor: pointer;
		img {
			width: 0.4rem;
			height: auto;
			margin-left: 0.1rem;
			cursor: pointer;
		}
	}
}
</style>
