import { GetBaseDataApi } from '@/http/api/basedata';
import { GetUserCourseApi } from '@/http/api/completion';
import { GetUserInfoApi } from '@/http/api/passport';
import { sortList } from '@/utils/common';
import { isEmpty } from 'lodash';

const actions = {
	saveToken({ commit }: any, data: any) {
		commit('saveToken', data);
	},
	saveRefreshToken({ commit }: any, data: any) {
		commit('saveRefreshToken', data);
	},
	saveUserInfo({ commit }: any, data: any) {
		commit('saveUserInfo', data);
	},
	saveLoginStatus({ commit }: any, data: any) {
		commit('saveLoginStatus', data);
	},
	saveCode({ commit }: any, data: any) {
		commit('saveCode', data);
	},
	saveAppId({ commit }: any, data: any) {
		commit('saveAppId', data);
	},
	getBaseData({ commit, state }: any, data: any) {
		if (!isEmpty(state.baseData)) {
			commit('getBaseData', state.baseData);
			return;
		}

		// 获取后台返回的基础数据
		let tempObj = {};
		GetBaseDataApi({})
			.then((res) => {
				tempObj = res;
			})
			.finally(() => {
				commit('getBaseData', tempObj);
			});
	},

	getPassportUserInfo({ commit, state }: any, data: any) {
		if (!isEmpty(state.passportUserInfo)) {
			commit('getPassportUserInfo', state.passportUserInfo);
			return;
		}

		// 获取后台返回的个人档案信息
		let tempObj = {};
		GetUserInfoApi({})
			.then((res) => {
				tempObj = res;
			})
			.finally(() => {
				commit('getPassportUserInfo', tempObj);
			});
	},

	// logoClickNum
	setLogoClickNum({ commit }: any, data: any) {
		commit('setLogoClickNum', data);
	},

	getMyCourse({ commit }: any, data: any) {
		let params: any = {
			PageNum: 1,
			PageSize: 100,
		};
		let tempObj = {};
		GetUserCourseApi(params)
			.then((res: any) => {
				tempObj = res.PageListInfos;
				sortList(tempObj, 'CourseId');
			})
			.finally(() => {
				commit('getMyCourse', tempObj);
			});
	},
};
export { actions };
