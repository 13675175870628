import request from '../request';
import qs from 'qs';

// 获取学生机构信息
export function getStudentOrz() {
	return request({
		url: '/Organization',
		method: 'get',
	});
}

//分页显示机构课程信息
export function getOrzCourseApi(data: { OrganizationId: 0 }) {
	return request({
		url: `/Organization/${data.OrganizationId}/CoursePaging?${qs.stringify(data, { arrayFormat: 'repeat' })}`,
		method: 'get',
	});
}

// 获取机构信息
export function getOrzById(data: any) {
	return request({
		url: `/Organization/${data.OrganizationId}`,
		method: 'get',
	});
}

// 查询公告分页
export function getOrzNoticeList(data: any) {
	return request({
		url: `/Organization/${data.TenantId}/Notice/Paging?${qs.stringify(data, { arrayFormat: 'repeat' })}`,
		method: 'get',
	});
}

// 查询指定公告
export function getOrzNoticeById(data: any) {
	return request({
		url: `/Organization/Notice/${data.Id}`,
		method: 'get',
	});
}
