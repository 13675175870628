import wx from 'weixin-js-sdk';

// andriod 详情
export const adr_showCourseDetail = (Id = '', CourseType = '') => {
	window.androidCallBackAction.showCourseDetail(Id, CourseType);
};

// andriod 生成学习成果
export const adr_createPortfolio = () => {
	window.androidCallBackAction.createPortfolio();
};

// ios 生成学习成果
export const ios_createPortfolio = () => {
	window.webkit.messageHandlers.createPortfolio.postMessage({});
};

// ios 详情
export const ios_showCourseDetail = (Id = '', CourseType = '', Type = '') => {
	window.webkit.messageHandlers.showCourseDetail.postMessage({ Id, CourseType, Type });
};

// andriod 登录
export const adr_appIsLogin = (isLogin = false) => {
	window.androidCallBackAction.appIsLogin(isLogin);
};

// 小程序 登录
export const miniProgram_login = () => {
	wx.miniProgram.postMessage({
		data: {
			pageType: '3',
		},
	});

	wx.miniProgram.navigateBack({ delta: 1 });
};

// 小程序 课程详情
export const miniProgram_courseDetail = (courseId: string, courseType: string, position: number) => {
	wx.miniProgram.postMessage({
		data: {
			courseId,
			courseType,
			pageType: '1',
			position,
		},
	});

	wx.miniProgram.navigateBack({ delta: 1 });
};
