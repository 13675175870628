<template>
	<div class="course">
		<!-- 标题 -->
		<div class="course-list-title flex-center-between cursor-pointer">
			<div class="flex-center-center flex-1 pr_2">
				<div class="flex-center-center flex-1">
					<div class="flex-center-center" @click="clickTitle">
						<h2 v-if="props.courseTitle" class="course-list-title-text cursor-pointer">
							{{ props.courseTitle }}
						</h2>
						<h4 v-if="props.courseSubTitle" class="course-list-sub-title cursor-pointer">
							{{ props.courseSubTitle }}
						</h4>
					</div>
					<div class="flex-1 oneDot">
						<TitleTextLinkList :list="props.textLinkList"></TitleTextLinkList>
					</div>
				</div>
			</div>
			<router-link
				v-if="props.more"
				:to="{
					path: '/specialCourseList',
					query: {
						SpecialId: props.SpecialId,
						SpecialTitle: props.courseTitle,
					},
				}"
				class="course-list-title-more flex-center-end"
				target="_blank"
			>
				更多
				<img src="@/assets/images/more-icon.png" alt="" />
			</router-link>
		</div>
		<!-- 列表 -->
		<div v-if="data.list && data.list.length * 1 > 0" class="course-lists flex-center-start">
			<div v-for="(item, index) in data.list" :key="index" class="course-lists-item" @click="clickCourse(item)">
				<div class="course-lists-item-cover">
					<img v-if="item.Cover" :src="item.Cover" :alt="item.Name" class="cover" />
					<img src="@/assets/images/baize/baize-tag.png" alt="" class="baize-tag" v-if="item.BaizeShow" />
				</div>

				<div class="course-lists-item-content">
					<h4 class="twoDot">{{ item.Name }}</h4>
					<p v-if="item.SeriesIntroduction" class="describe oneDot">
						{{ item.SeriesIntroduction }}
					</p>
					<div class="organ flex-center-between">
						<div class="organ-logo">{{ item.SourceName }}</div>
						<div style="display: none" class="organ-number flex-center-end">
							<!-- <img src="@/assets/images/user-icon.png" alt="" /> -->
							{{ item.ViewedCount }}人学过
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="no-data">暂无数据！</div>
	</div>
</template>

<script setup lang="ts">
import { reactive, watch, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { PostClickCourse } from '@/http/api/course';
import { toDetails } from '@/utils/event/courseList';
import { store } from '@/store';

const router = useRouter();
const data = reactive({ list: [] });
const props = defineProps({
	courseTitle: {
		type: String,
		default: '',
	},
	courseLists: {
		type: Array,
		default: [],
	},
	SpecialId: {
		type: String,
		default: '',
	},
	more: {
		type: Boolean,
		default: true,
	},
	courseSubTitle: {
		type: String,
		default: '',
	},
	ChannelId: {
		type: String,
		default: '',
	},
	textLinkList: {
		type: Array,
		value: [],
	},
});

// 判断是否显示白泽
const handleShowBaize = (v: any) => ({ ...v, BaizeShow: store.state.chatLimitCourseIdList.indexOf(v.Id) >= 0 });

watch(
	() => props.courseLists,
	() => {
		data.list = <any>props.courseLists.map((v) => handleShowBaize(v));
	},
);

onMounted(() => {
	data.list = <any>props.courseLists.map((v) => handleShowBaize(v));
});

// 点击更多左侧标题
const clickTitle = () => {
	if (!props.more) return;
	let routeData: any;
	routeData = router.resolve({
		path: '/specialCourseList',
		query: {
			SpecialId: props.SpecialId,
			SpecialTitle: props.courseTitle,
		},
	});
	window.open(routeData.href, '_blank');
};

// 点击去详情页
const clickCourse = (item: any) => {
	if (!props.SpecialId) {
		toDetails(router, item, props.ChannelId);
		return;
	}
	const params = {
		SpecialId: props.SpecialId,
		CourseId: item.Id,
	};
	PostClickCourse(params)
		.then(() => {
			toDetails(router, item, props.ChannelId);
		})
		.catch((err: any) => {
			console.log(err);
		});
};
</script>

<style lang="scss" scoped>
.course {
	width: 100%;
	box-sizing: border-box;
	.course-list-title {
		margin-bottom: 25px;
		.course-list-title-text {
			font-size: 20px;
			font-family: PingFangSC-Semibold, PingFang SC;
			font-weight: 600;
			color: #000000;
		}
		.course-list-sub-title {
			margin-left: 20px;
			font-size: 13px;
			font-family: PingFangSC-Semibold, PingFang SC;
			font-weight: 600;
			color: #000000;
		}
		.course-list-title-more {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #666666;
			cursor: pointer;
			img {
				width: 16px;
				height: auto;
				margin-left: 5px;
				cursor: pointer;
			}
		}
	}
	.course-lists {
		flex-wrap: wrap;
		.course-lists-item {
			width: 24%;
			max-width: 277px;
			text-decoration: none;
			background: #ffffff;
			box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.14);
			border-radius: 3px;
			margin-bottom: 25px;
			box-sizing: border-box;
			cursor: pointer;
			// margin-right:calc(6% / 3);
			.course-lists-item-cover {
				width: 100%;
				// height: auto;
				height: 165px;
				display: block;
				border-radius: 4px 4px 0 0;
				font-size: 12px;
				color: #000000;
				position: relative;

				.cover {
					width: 100%;
					// height: auto;
					height: 100%;
				}

				.baize-tag {
					width: 56px;
					height: 80px;
					position: absolute;
					bottom: 0;
					right: 0;
				}
			}
			.course-lists-item-content {
				padding: 20px 15px 0 15px;
				box-sizing: border-box;
				h4 {
					min-height: 47px;
					font-size: 16px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 600;
					color: #000000;
					line-height: 24px;
					// margin-bottom: 12px;
				}
				.describe {
					font-size: 13px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #999999;
					line-height: 13px;
					margin-bottom: 18px;
				}
				.organ {
					border-top: 1px solid #eeeeee;
					padding: 12px 0;
					box-sizing: border-box;
					margin-top: 25px;
					.organ-logo {
						font-size: 12px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #2655fb;
						line-height: 20px;
					}
					.organ-number {
						font-size: 12px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #757575;
						line-height: 13px;
						img {
							width: 16px;
							height: auto;
							margin-right: 8px;
						}
					}
				}
			}
		}
		.course-lists-item:not(:nth-of-type(4n)) {
			margin-right: calc(7% / 3);
		}
		.course-lists-item:nth-of-type(4n) {
			margin-right: 0;
		}
	}
	// .course-lists:after {
	//   content: "";
	//   width: 15em;
	// }
	.no-data {
		font-size: 14px;
		color: #000000;
	}
}
@media screen and (max-width: 1350px) {
	.course {
		.course-lists {
			.course-lists-item:not(:nth-of-type(4n)) {
				margin-right: calc(4% / 3);
			}
		}
	}
}
</style>
