<template>
	<div class="list-title flex-center-between" v-if="!props.columnsItem.HideHeader">
		<div class="flex-center-center flex-1 pr_2">
			<div class="flex-center-center flex-1">
				<div class="flex-center-center">
					<h2 v-if="props.columnsItem.Title" class="main-title cursor-pointer">
						{{ props.columnsItem.Title }}
					</h2>
					<h4 v-if="props.columnsItem.SubTitle" class="sub-title cursor-pointer">
						{{ props.columnsItem.SubTitle }}
					</h4>
				</div>
				<div class="flex-1 oneDot">
					<TitleTextLinkList :list="props.textLinkList"></TitleTextLinkList>
				</div>
			</div>
		</div>
		<div v-if="props.more" class="more flex-center-end" @click="toMore">
			更多
			<img src="@/assets/images/more-icon.png" alt="" />
		</div>
	</div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
const emits = defineEmits(['toMore']);
const router = useRouter();

const props = defineProps({
	columnsItem: {
		type: Object,
		default: {},
	},
	more: Boolean,
	textLinkList: {
		type: Array,
		default: [],
	},
});

const toMore = () => {
	emits('toMore');
};
</script>
<style lang="scss" scoped>
.list-title {
	margin-bottom: 25px;

	.main-title {
		font-size: 20px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #000000;
	}
	.sub-title {
		margin-left: 20px;
		font-size: 13px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #000000;
	}
	.more {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;
		cursor: pointer;
		img {
			width: 16px;
			height: auto;
			margin-left: 5px;
			cursor: pointer;
		}
	}
}
</style>
