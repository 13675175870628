import request from '../request';
import qs from 'qs';

// 获取图书馆的图书
export function bookListApi(data: object) {
	return request({
		url: '/Book/Paging?' + qs.stringify(data, { arrayFormat: 'repeat' }),
		method: 'get',
		data,
	});
}
// 跳转外部图书
export function getBookUrlApi(data: object) {
	return request({
		url: '/Book/' + data.bookId + '/ExternalUrl',
		method: 'get',
		data,
	});
}

// 跳转用户观看过的图书
export function getUserViewBook(data: object) {
	return request({
		url: '/Book/BookByUserId/Paging?' + qs.stringify(data, { arrayFormat: 'repeat' }),
		method: 'get',
		data,
	});
}

// 获取多个专题下的图书
export function PostSpecialsApiByBook(data: object) {
	return request({
		url: '/Book/BySpecials',
		method: 'post',
		data,
	});
}

// 获取某个专题下的图书
export function GetOneSpecialsApiByBook(data: object) {
	return request({
		url: '/Book/' + data.SpecialId + '/Books?Count=' + data.Count,
		method: 'get',
		data,
	});
}

// 分页获取专题下的图书
export function GetSpecialsBookList(data: object) {
	return request({
		url: '/Book/' + data.SpecialId + '/Paging?' + qs.stringify(data, { arrayFormat: 'repeat' }),
		method: 'post',
		data,
	});
}
