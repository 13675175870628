<template>
	<div class="nav-wrapper-btn" @mouseenter="navListShow = true" @mouseleave="navListShow = false">
		<div class="main-btn" :class="{ 'border-radius-5555': !navListShow, 'border-radius-5500': navListShow }">
			<span class="text">分类</span>
		</div>
		<CourseClassify :classify-show="navListShow"></CourseClassify>
	</div>
</template>
<script setup lang="ts">
import { ref } from 'vue';
const navListShow = ref(false);
</script>
<style lang="scss" scoped>
.nav-wrapper-btn {
	position: relative;
	cursor: pointer;
	.main-btn {
		width: 64px;
		height: 35px;
		border: 1px solid #dddddd;
		font-size: 13px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #13202b;
		display: flex;
		justify-content: center;
		align-items: center;

		&::after {
			content: '';
			position: absolute;
			width: 12px;
			height: 7px;
			background: url('@/assets/images/header/arrow-bottom-icon.png');
			background-repeat: no-repeat;
			background-size: cover;
			top: 14px;
			right: 10px;
			margin-left: 10px;
			transition: all 0.3s;
		}

		&:hover {
			&::after {
				background: url('@/assets/images/header/arrow-top-icon.png');
				background-repeat: no-repeat;
				background-size: cover;
				transition: all 0.3s;
			}
		}

		.text {
			padding-right: 8px;
		}
	}

	.border-radius-5555 {
		border-radius: 5px;
	}

	.border-radius-5500 {
		border-radius: 5px 5px 0 0;
	}
}
</style>
