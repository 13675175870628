<template>
	<div v-if="props.title" class="course-list-title flex-center-between">
		<div class="flex-center-start" @click="clickTitle">
			<h2 v-if="props.title" class="course-list-title-text">
				{{ props.title }}
			</h2>
			<h4 v-if="props.subTitle" class="course-list-sub-title">{{ props.subTitle }}</h4>
		</div>
		<router-link
			v-if="props.more"
			:to="{
				path: `/special${props.type}List`,
				query: {
					SpecialId: props.SpecialId,
					SpecialTitle: props.title,
				},
			}"
			class="course-list-title-more flex-center-end"
		>
			更多
			<img src="@/assets/images/more-icon.png" alt="" />
		</router-link>
	</div>
</template>
<script setup lang="ts">
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
const router = useRouter();
const props = defineProps({
	title: {
		type: String,
		default: '',
	},
	SpecialId: {
		type: String,
		default: '',
	},
	more: {
		type: Boolean,
		default: true,
	},
	subTitle: {
		type: String,
		default: '',
	},
	type: {
		type: String,
		default: 'Course',
	},
});

// 点击更多左侧标题
const clickTitle = () => {
	if (!props.more) return;
	let routeData: any;
	routeData = router.resolve({
		path: `/special${props.type}List`,
		query: {
			SpecialId: props.SpecialId,
			SpecialTitle: props.title,
		},
	});
	window.open(routeData.href, '_blank');
};
</script>

<style scoped lang="scss">
.course-list-title {
	margin-bottom: 0.42rem;
	.course-list-title-text {
		font-size: 0.32rem;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #000000;
		line-height: 0.32rem;
	}
	.course-list-sub-title {
		margin-left: 0.2rem;
		font-size: 0.24rem;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #000000;
		line-height: 0.24rem;
	}
	.course-list-title-more {
		font-size: 0.28rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;
		line-height: 0.2rem;
		cursor: pointer;
		img {
			width: 0.32rem;
			height: auto;
			margin-left: 0.1rem;
			cursor: pointer;
		}
	}
}
</style>
