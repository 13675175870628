<template>
	<div class="mobile-swiper" :class="[{ 'flex-center-start': columnsItem.SameLine }]">
		<!-- 标题 -->
		<div v-if="!props.columnsItem.HideHeader">
			{{ props.columnsItem.Title }}
		</div>
		<div class="swiper-box">
			<div class="swiper-wrapper">
				<!-- :autoplay="3000"  -->
				<van-swipe indicator-color="#fff" lazy-render class="my-swipe">
					<van-swipe-item v-for="(item, index) in data.list" :key="index">
						<div class="swiper-cntainer">
							<img :src="item.ImageUrl" alt="" @click="jumpRoute(item)" />
						</div>
					</van-swipe-item>
				</van-swipe>
			</div>
			<!-- <div class="dots-container">
				<div class="flex-center-center width_100">
					<div class="dots flex-center-center">
						<template v-for="(dotItem, dotIndex) in JSON.parse(props.columnsItem.Data).Items.length">
							<div class="dot" :class="{ active: currentIndex === dotIndex }"></div>
						</template>
					</div>
				</div>
			</div> -->
		</div>
	</div>
</template>
<script setup lang="ts">
// import { showToast } from 'vant';
import { ref, reactive } from 'vue';
import { useRouter } from 'vue-router';
const router = useRouter();
const props = defineProps({
	columnsItem: {
		type: Object,
		default: {},
	},
});

const data: any = reactive({
	list: [],
});

data.list = JSON.parse(props.columnsItem.Data).Items;
const currentIndex = ref(0);
// 跳转路径
const jumpRoute = (item: any) => {
	if (item.TargetType == 'Url') {
		const path = item.Target;
		window.location.href = path;
	} else if (item.TargetType == 'Local') {
		const path = item.Target;
		window.location.href = path;
		// router.push({
		//   path: item.Target,
		// });
	}
};

// const change = (index: any) => {
// 	showToast('当前 Swipe 索引：' + index);
// };
</script>
<style lang="scss">
.mobile-swiper {
	width: 100%;
	padding: 0.12rem 0 0 0 !important;
	box-sizing: border-box;
	border-radius: 0.16rem;
	.swiper-box {
		width: 100%;
		height: 3.02rem;
		position: relative;
		.swiper-wrapper {
			width: 100%;
			height: 3.02rem;
			.my-swipe {
				width: 100%;
				height: 3.02rem !important;
				min-height: 3.02rem !important;
				.swiper-cntainer {
					display: flex;
					justify-content: center;
					width: 100%;
					height: 100%;
					border-radius: 0.16rem;
					min-height: 3.02rem !important;
					img {
						width: 100%;
						height: 100%;
						border-radius: 0.16rem;
					}
				}
			}
		}
		.dots-container {
			width: 100%;
			display: flex;
			align-items: center;
			position: absolute;
			bottom: 0.18rem;

			.dots {
				padding: 0 0.24rem;
				height: 0.24rem;
				background: rgba($color: #000000, $alpha: 0.2);
				border-radius: 0.2rem;

				.dot {
					width: 0.12rem;
					height: 0.12rem;
					border-radius: 50%;
					background: #999999;
					margin-right: 0.1rem;
				}

				.active {
					background: #eeeeee;
				}
			}
		}
	}
}
</style>
