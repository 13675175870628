<template>
	<div class="login-popver" v-if="!data.is_login">
		<div class="title">新人注册可享以下权限</div>
		<div class="login-guide-content">
			<div class="login-popver-text">
				<img src="@/assets/images/appointment-success-checked.png" alt="" class="icon" />
				<div>收藏学习内容</div>
			</div>
			<div class="login-popver-text">
				<img src="@/assets/images/appointment-success-checked.png" alt="" class="icon" />
				<div>发表弹幕/评论</div>
			</div>
			<div class="login-popver-text">
				<img src="@/assets/images/appointment-success-checked.png" alt="" class="icon" />
				<div>享终身学习资源</div>
			</div>
			<div class="login-popver-text">
				<img src="@/assets/images/appointment-success-checked.png" alt="" class="icon" />
				<div>多端同步学习记录</div>
			</div>
		</div>
		<div class="flex-column-center">
			<div class="login-button flex-column-center" @click="login()">登录/注册</div>
		</div>
	</div>
	<div class="user-popover" v-else>
		<div class="top-info">
			<img v-if="userAvatarIcon" class="user-avatar" :src="userAvatarIcon" alt="" />
			<img v-else src="@/assets/images/head-pic-default.png" class="user-avatar" alt="" />
			<span class="user-name oneDot">{{ userNickname }}</span>
		</div>
		<div class="user-container">
			<div class="tab flex-center-between">
				<div class="flex-1 flex-center-center">我的课程</div>
				<router-link :to="{ path: '/mine/myCourse' }" target="_blank">
					<div class="more-card flex-center-center">
						更多 <img src="@/assets/images/banner/more-icon.png" alt="" class="more-icon" />
					</div>
				</router-link>
			</div>
			<div class="course-list">
				<div class="flex-center-center no-course-area" v-if="!data.CourseList.length">
					<img src="@/assets/images/banner/no-course.png" alt="" class="no-course-icon" />
				</div>
				<template v-for="(item, index) in data.CourseList" :key="index" v-else>
					<div class="course-item" @click.stop="toStudy(item)" v-if="index < 4">
						<h3 class="course-name twoDot">{{ item.CourseSummary.Name }}</h3>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { reactive, getCurrentInstance, onMounted, watch } from 'vue';
const { proxy } = getCurrentInstance() as any;
import { toStudyMyCourse } from '@/utils/event/mine';
import { useRouter } from 'vue-router';
import { mgr } from '@/assets/ts/oidcConfig';
import { useStore } from 'vuex';
import { computed } from 'vue';
const store = useStore();
const router = useRouter();
const user_info: any = computed(() => proxy.$cookies.get('userInfo') || {});
const userAvatarIcon = user_info.value.Avatar;
const userNickname = user_info.value.Nickname;

const data = reactive<any>({
	Keyword: '', //搜索关键字
	is_login: proxy.$cookies.get('loginStatus'), //登录状态  true登录  false未登录
	userInfo: proxy.$cookies.get('userInfo'), //用户信息
	CourseList: computed(() => store.state.myCourse),
});

watch(
	() => proxy.$cookies.get('loginStatus'),
	() => {
		data.is_login = proxy.$cookies.get('loginStatus');
	},
);

onMounted(() => {
	data.is_login = proxy.$cookies.get('loginStatus');

	if (!store.state.myCourseStatus && data.is_login) {
		store.dispatch('getMyCourse');
	}
});

// 登录
const login = () => {
	mgr.signinRedirect({ state: window.location.href });
};

// 去学习
const toStudy = (item: any) => {
	toStudyMyCourse(router, item);
};
</script>
<style scoped lang="scss">
.login-popver {
	color: #13202b;
	box-sizing: border-box;
	padding: 35px 10px 30px 10px;
	height: 325px;
	width: 186px;
	background: #ffffff;
	box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.16);
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.title {
		font-weight: 600;
		font-size: 15px;
		font-family: PingFangSC-Semibold, PingFang SC;
		color: #011945;
		line-height: 21px;
		text-align: center;
	}
	.login-guide-content {
		margin-left: 14px;
		margin-top: 20px;
		.login-popver-text {
			font-size: 13px;
			display: flex;
			align-items: center;
			font-weight: 400;
			margin-bottom: 24px;

			.icon {
				width: 12px;
				height: 12px;
				vertical-align: middle;
				margin-right: 10px;
			}
		}
	}
	.login-button {
		background: linear-gradient(180deg, #6fa4ff 0%, #3c6bff 100%);
		cursor: pointer;
		color: #fff;
		font-size: 13px;
		width: 162px;
		height: 30px;
		background: #588bff;
		border-radius: 15px;
	}
}

.user-popover {
	color: #1b3f6b;
	box-sizing: border-box;
	width: 186px;
	height: 325px;
	background: #ffffff;
	box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.16);
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	.top-info {
		background-image: url('@/assets/images/banner/user-info-bg.png');
		height: 60px;
		width: 186px;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		display: flex;
		padding: 12px 4px 18px 14px;
		box-sizing: border-box;
		align-items: center;
		border-radius: 6px 6px 0 0;
		img.user-avatar {
			width: 30px;
			height: 30px;
			border-radius: 50%;
			margin-right: 13px;
		}
		.user-name {
			font-size: 18px;
			font-family: PingFangSC-Semibold, PingFang SC;
			font-weight: 600;
			color: #ffffff;
		}
	}

	.user-container {
		flex: 1;
		width: 186px;
		background: #ffffff;
		box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.16);
		border-radius: 6px;
		margin-top: -6px;
		z-index: 1;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		.tab {
			height: 33px;
			font-size: 13px;
			font-family: PingFangSC-Semibold, PingFang SC;
			font-weight: 600;
			color: #000000;
			line-height: 18px;
		}

		.more-card {
			background-image: url('@/assets/images/banner/more-bg.png');
			height: 33px;
			width: 100px;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			padding-left: 10px;
			box-sizing: border-box;
			font-size: 13px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #999999;
			line-height: 18px;
			cursor: pointer;
			.more-icon {
				width: 11px;
				height: 11px;
				margin-left: 5px;
			}
		}

		.course-list {
			padding-left: 14px;
			padding-right: 14px;
			flex: 1;
			.course-item {
				height: 60px;
				display: flex;
				align-items: center;
				box-sizing: border-box;
				border-bottom: 1px dotted #ddd;
				&:last-of-type {
					border-bottom: none;
				}

				.course-name {
					font-size: 12px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #13202b;
					line-height: 16px;
				}
			}

			.no-course-area {
				padding-top: 15px;
				.no-course-icon {
					width: 85px;
					height: 114px;
				}
			}
		}
	}
}
</style>
